
import SummaryClient from './SummaryClient';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const BrandContacts = () => {
    let { id } = useParams()

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [Sales, setDataSales] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [IdDeleteusers, setIdDeleteusers] = useState(null);

    const items = [
        { label: <Link to={`/brands`}>{t('brand_name')}</Link> },
        { label: <Link to={`/brands/contacts/${id}`} className='p-2 border-round'>{t('brand_contacts')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    // Add new contacts
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Tooltip target=".disabled-button" autoHide={false} />

                <Link to={`/brands/contacts/${id}/add`}>
                    <Button label={t('add_brand_contacts')} severity="warning" className='disabled-button' icon="pi pi-plus" size='small' />
                </Link>

            </div>
        );
    };

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                {/*<Link to={`/brands/contacts/${id}/edit/${rowData.IDBrandcontacts}`}>*/}
                {/*</Link>*/}

                <Button onClick={() => confirmDeleteusers(rowData)} icon="pi  pi-trash" severity="danger" rounded outlined className="mr-2" tooltip={t('delete_brand_contacts')} tooltipOptions={{ position: t('tooltip') }} />

                <Link to={`/brands/contacts/${id}/edit/${rowData.IDBrandContact}`}>
                    <Button onClick={() => confirmDeleteusers(rowData)} icon="pi  pi-pencil" rounded outlined className="mr-2" tooltip={t('edit_brand_contacts')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
            </div>
        );
    };
    const confirmDeleteusers = async (users) => {
        setDeleteusersDialog(true);
        setIdDeleteusers(users?.IDBrandContact)
    };
    const deleteusers = async (rowData) => {
        setdeleteLoadind(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/contacts/delete/${IdDeleteusers}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {
            
            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

                fetchData()
                setTimeout(() => {
                    setDeleteusersDialog(false);
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-end">

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/brands/contacts`;
        let data = await axios.post(url, {
            IDBrand: id
        },
            {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.BrandContacts);
        setDataSales(data?.data?.Response?.Sales);
    }
    useEffect(() => {
        fetchData();
    }, [isLang])
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
            <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
        </div>
    );
    return (
        <div>

            {
                localStorage.getItem('Role') == 1 &&
                <>
                    <div className="mb-5">
                        <BreadCrumb model={items} home={home} />
                    </div>
                    <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
                </>
            }
            <SummaryClient data={Sales} />
            <div className="card">

                {
                    localStorage.getItem('Role') == 1 &&
                    <>
                        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                    </>
                }
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    paginator
                    rows={10}
                    header={header}>
                    <Column field="BrandContactName" header={t('BrandContactName')} style={{ textAlign: 'center' }}></Column>
                    <Column  field="BrandContactPhone" header={t('BrandContactPhone')} style={{ textAlign: 'center' }}></Column>
                    <Column  field="BrandContactTitle" header={t('title')} style={{ textAlign: 'center' }}></Column>
                    {
                        localStorage.getItem('Role') == 1 &&
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    }
                </DataTable>
            </div>
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                    </span>
                </div>
            </Dialog>
        </div>
    )
}

export default BrandContacts