import { Dialog } from 'primereact/dialog';

const PostionInfo = ({ t, isLang, setOpen, open, data }) => {
    const hideOpen = () => setOpen(false);
 
    return (
        <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'}
            visible={open}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header={isLang == "en" ? 'Positions Details' : 'تفاصيل المناصب'}
            onHide={hideOpen}>
            <div className='flex flex-column gap-3'>

                <div className='flex flex-row gap-3'>
                    <strong>{t('PositionTitle')} : </strong>
                    <span>{data?.PositionTitle}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ReferralNumber')} : </strong>
                    <span>{data?.PositionReferralNumber}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('PositionReferralInterval')} : </strong>
                    <span>{data?.PositionReferralInterval}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ClientLeftNumber')} : </strong>
                    <span>{data?.PositionLeftNumber}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ClientRightNumber')} : </strong>
                    <span>{data?.PositionRightNumber}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ClientTotalNumber')} : </strong>
                    <span>{data?.PositionAllNumber}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('NumberInterval')} : </strong>
                    <span>{data?.PositionNumberInterval}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ClientLeftPoints')} : </strong>
                    <span>{data?.PositionLeftPoints}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ClientRightPoints')} : </strong>
                    <span>{data?.PositionRightPoints}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('PointInterval')} : </strong>
                    <span>{data?.PositionPointInterval}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ClientTotalPoints')} : </strong>
                    <span>{data?.PositionAllPoints}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('visits')} : </strong>
                    <span>{data?.PositionVisits}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('VisitInterval')} : </strong>
                    <span>{data?.PositionVisitInterval}</span>
                </div>
                <hr />
                <div className='flex flex-row gap-3'>
                    <strong>{t('ChequeValue')} : </strong>
                    <span>{data?.PositionChequeValue}</span>
                </div>
                <hr /> 
                <div className='flex flex-row gap-3'>
                    <strong>{t('ChequeInterval')} : </strong>
                    <span>{data?.PositionChequeInterval}</span>
                </div>
            </div>
        </Dialog>
    )
}

export default PostionInfo