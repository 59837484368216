

import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateEvents = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let {
    fetchCity,
    fetchArea,
    city,
    area } = useContext(FetchApi)
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/events/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/events'}>{t('events_name')}</Link> },
    { label: <Link to={`/events/edit/${id}`} className='p-2 border-round'>{t('update_events')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/events/details`, { IDEvent: id },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response?.Event);
      setDocuments(data?.Response?.Event?.EventGallery);
      fetchCity(data?.Response?.Event?.IDCountry)
      fetchArea(data?.Response?.Event?.IDCity)
    }
  }

  useEffect(() => {
    fetchData()
  }, [isLang]);

  //!hide vido= 
  const [openEventVideos, setOpenEventVideos] = useState(false);
  const [EventVideos, setEventVideos] = useState([]);

  //!hide EventVideos modal 
  const hideEventVideosProjectsDialog = (e) => setOpenEventVideos(false)

  // Handle scope change
  const handleScopeChange = (index, value) => {
    const updatedEventVideos = [...formik.values.EventVideos];
    updatedEventVideos[index] = value;
    formik.setFieldValue('EventVideos', updatedEventVideos);
  };


  const handleAddScope = () => {
    const currentEventVideos = formik.values.EventVideos;

    // Ensure EventVideos is an array
    const updatedEventVideos = Array.isArray(currentEventVideos)
      ? [...currentEventVideos, '']
      : [''];

    formik.setFieldValue('EventVideos', updatedEventVideos);
    setEventVideos(updatedEventVideos);
  };

  const home = { icon: 'pi pi-home', url: '/' };
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

  const formik = useFormik({
    initialValues: {
      IDEvent: id,
      IDArea: Data?.IDArea || '',
      EventTitleEn: Data?.EventTitleEn || '',
      EventTitleAr: Data?.EventTitleAr || '',
      EventDescEn: Data?.EventDescEn || '',
      EventDescAr: Data?.EventDescAr || '',
      EventPrice: Data?.EventPrice || '',
      EventStartTime: Data?.EventStartTime || '',
      EventEndTime: Data?.EventEndTime || '',
      EventInstallmentEndDate: Data?.EventInstallmentEndDate || '',
      EventMaxNumber: Data?.EventMaxNumber || '',
      EventPoints: Data?.EventPoints || '',
      EventPolicyEn: Data?.EventPolicyEn || '',
      EventPolicyAr: Data?.EventPolicyAr || '',
      EventGallery: selectMultiImage,
      EventVideos: EventVideos,
    },
    onSubmit: async (values, { resetForm }) => {
      // Check if the date values are not empty strings or undefined before formatting
      // const formattedStartTime = values.EventStartTime ? format(new Date(values.EventStartTime.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm:ss") : '';
      // const formattedEndTime = values.EventEndTime ? format(new Date(values.EventEndTime.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm:ss") : '';
      // const formattedInstallmentEndDate = values.EventInstallmentEndDate ? format(new Date(values.EventInstallmentEndDate.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm") : '';

      const formattedStartTime = values.EventStartTime ? format(new Date(values.EventStartTime.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';
      const formattedEndTime = values.EventEndTime ? format(new Date(values.EventEndTime.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';
      const formattedInstallmentEndDate = values.EventInstallmentEndDate ? format(new Date(values.EventInstallmentEndDate.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';

      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, {
          IDEvent: id,
          IDArea: values.IDArea || '',
          EventTitleEn: values.EventTitleEn || '',
          EventTitleAr: values.EventTitleAr || '',
          EventDescEn: values.EventDescEn || '',
          EventDescAr: values.EventDescAr || '',
          EventPoints: values.EventPoints || '',
          EventPrice: values.EventPrice || '',
          EventPolicyEn: values.EventPolicyEn || '',
          EventPolicyAr: values.EventPolicyAr || '',
          EventStartTime: formattedStartTime,
          EventEndTime: formattedEndTime,
          EventInstallmentEndDate: formattedInstallmentEndDate,
          EventMaxNumber: values.EventMaxNumber || '',
          EventGallery: selectMultiImage,
          EventVideos: EventVideos,
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/events')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/events/gallery/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchData()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };


  useEffect(() => {
    formik.setValues({
      IDEvent: id,
      EventTitleEn: Data?.EventTitleEn || '',
      EventTitleAr: Data?.EventTitleAr || '',
      IDArea: Data?.IDArea || '',
      IDCity: Data?.IDCity || '',
      EventDescEn: Data?.EventDescEn || '',
      EventDescAr: Data?.EventDescAr || '',
      EventPrice: Data?.EventPrice || '',
      EventPolicyEn: Data?.EventPolicyEn || '',
      EventPolicyAr: Data?.EventPolicyAr || '',
      EventPoints: Data?.EventPoints || '',
      EventMaxNumber: Data?.EventMaxNumber || '',
      EventEndTime: Data?.EventEndTime ? new Date(Data.EventEndTime) : '',
      EventStartTime: Data?.EventStartTime ? new Date(Data.EventStartTime) : '',
      EventInstallmentEndDate: Data?.EventInstallmentEndDate ? new Date(Data.EventInstallmentEndDate) : '',

    });
  }, [Data, formik.setValues]);


  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="EventTitleEn" className="font-bold block mb-2">{t('title_en')}</label>
            <InputText
              name='EventTitleEn'
              id="EventTitleEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.EventTitleEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="EventTitleAr" className="font-bold block mb-2">{t('title_ar')}</label>
            <InputText
              value={formik.values.EventTitleAr}
              name='EventTitleAr'
              id="EventTitleAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="EventDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='EventDescEn'
              id="EventDescEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.EventDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="EventDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              value={formik.values.EventDescAr}
              name='EventDescAr'
              id="EventDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="EventPolicyEn" className="font-bold block mb-2">{t('EventPolicyEn')}</label>
            <InputTextarea
              name='EventPolicyEn'
              id="EventPolicyEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.EventPolicyEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="EventPolicyAr" className="font-bold block mb-2">{t('EventPolicyAr')}</label>
            <InputTextarea
              value={formik.values.EventPolicyAr}
              name='EventPolicyAr'
              id="EventPolicyAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2  ">
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="EventStartTime" className="font-bold block mb-2"> {t('start_time')}</label>
            <Calendar
              id="EventStartTime"
              name="EventStartTime"
              value={formik.values.EventStartTime}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Event start time' : 'وقت بداية الحدث'}

              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              iconPos="right"
              icon="pi pi-clock"
            />
          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="EventEndTime" className="font-bold block mb-2"> {t('end_time')}</label>
            <Calendar
              id="EventEndTime"
              name="EventEndTime"
              value={formik.values.EventEndTime}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Event end time' : 'وقت نهاية الحدث'}

              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              iconPos="right"
              icon="pi pi-clock"
              minDate={
                formik.values.EventStartTime
                  ? new Date(formik.values.EventStartTime.getTime())
                  : new Date()
              }
            />
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="InstallmentEndDate" className="font-bold block mb-2"> {t('InstallmentEndDate')}</label>
            <Calendar
              id="EventInstallmentEndDate"
              name="EventInstallmentEndDate"
              value={formik.values.EventInstallmentEndDate}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Installment end time' : 'وقت نهاية الحدث'}
              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              iconPos="right"
              icon="pi pi-clock"
              maxDate={
                formik.values.EventStartTime
                  ? new Date(formik.values.EventStartTime.getTime())
                  : new Date()
              }
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='EventPrice'
              id="EventPrice"
              value={formik.values.EventPrice}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventPoints" className="font-bold block mb-2">{t('ClientRewardPoints')}</label>
            <InputText
              name='EventPoints'
              id="EventPoints"
              value={formik.values.EventPoints}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventMaxNumber" className="font-bold block mb-2">{t('EventMaxNumber')}</label>
            <InputText
              name='EventMaxNumber'
              id="EventMaxNumber"
              value={formik.values.EventMaxNumber}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>


          <div className="lg:col-6 md:col-12 sm:col-12  mt-3">
            <label htmlFor="IDCity" className="font-bold block mb-2"> {t('tabel-header-cities-1')}</label>
            <Dropdown filter
              options={city?.map(item => ({
                name: item.CityName,
                value: item.IDCity,
              }))}
              id="IDCity"
              name="IDCity"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDCity} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDCity", e.value)
                fetchArea(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('city_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12  mt-3">
            <label htmlFor="IDArea" className="font-bold block mb-2"> {t('tabel-header-areas-1')}</label>
            <Dropdown filter
              options={area?.map(item => ({
                name: item.AreaName,
                value: item.IDArea,
              }))}
              id="IDArea"
              name="IDArea"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDArea} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDArea", e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('area_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2"> {t('gallery')}  </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('gallery')} type='button' size='small' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
              <Button label={isLang === "en" ? "Event Videos  " : 'فيديوهات الحدث'} type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenEventVideos(!openImanes)} />

            </div>
          </div>
        </div>


        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? " Event Gallery" : "معرض الأحداث"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('EventGallery', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  {
                    item?.EventGalleryType === "IMAGE" &&
                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                      <img
                        src={item.EventGalleryPath}
                        alt={`Image ${index}`}
                        style={{ objectFit: 'cover' }}
                        className="w-full h-19rem border-round-xl "
                      />
                      <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDEventGallery)} />
                    </div>
                  }

                  {
                    item?.EventGalleryType === "VIDEO" &&
                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                      <iframe width={400} height={250} className="w-full h-19rem border-round-xl " src={`https://${item.EventGalleryPath}`} title="Ghalia Benali/ YA MSAFER / غالية بنعلي/ يا مسافر وحدك" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDEventGallery)} />
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>


        </Dialog>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/events'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>


        {/*****Model Add Multi EventVideos */}
        <Dialog visible={openEventVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hideEventVideosProjectsDialog}>

          {/* EventVideos */}
          {EventVideos?.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="EventVideos" className="font-bold block mb-2">  {isLang === "en" ? "  Youtube Link" : 'رابط يوتيوب'}    </label>
                <InputText
                  name='EventVideos'
                  id="EventVideos"
                  type="text"
                  className="w-full"
                  defaultValue={scope.ar}
                  onChange={(event) => handleScopeChange(index, event.target.value)}
                />

              </div>
            </div>


          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hideEventVideosProjectsDialog} />
            </div>

          </div>

        </Dialog>

      </form>


    </div>
  )
}
export default UpdateEvents
