import Navber from 'Components/Navber/Navber';
import Error from 'Components/NotFound/Error';
import SidebarMenu from 'Components/Sidebar/SidebarMenu';
import SidebarSM from 'Components/Sidebar/SidebarSM';
import Sidebar from 'Components/Sidebar/Sildebar';
import Auth from 'Layout/Auth';
import BetterWay from 'Layout/BetterWay';
import AddCompanyLedger from 'Page/Admin/Account/Revenue/AddCompanyLedger';
import CompanyLedger from 'Page/Admin/Account/Revenue/CompanyLedger';
import UpdateCompanyLedger from 'Page/Admin/Account/Revenue/UpdateCompanyLedger';
import AddBonanza from 'Page/Admin/Bonanza/AddBonanza';
import AddBonanzaBrand from 'Page/Admin/Bonanza/AddBonanzaBrand';
import Bonanza from 'Page/Admin/Bonanza/Bonanza';
import BonanzaBrand from 'Page/Admin/Bonanza/BonanzaBrand';
import BonanzaClient from 'Page/Admin/Bonanza/BonanzaClient';
import UpdateBonanza from 'Page/Admin/Bonanza/UpdateBonanza';
import AddBrand from 'Page/Admin/Brands/Brand/AddBrand';
import Brand from 'Page/Admin/Brands/Brand/Brand';
import AddBrandContacts from 'Page/Admin/Brands/Brand/BrandContacts/AddBrandContacts';
import BrandContacts from 'Page/Admin/Brands/Brand/BrandContacts/BrandContacts';
import UpdateBrandContacts from 'Page/Admin/Brands/Brand/BrandContacts/UpdateBrandContacts';
import AddBrandContract from 'Page/Admin/Brands/Brand/BrandContract/AddBrandContract';
import BrandContract from 'Page/Admin/Brands/Brand/BrandContract/BrandContract';
import UpdateBrandContract from 'Page/Admin/Brands/Brand/BrandContract/UpdateBrandContract';
import BrandRating from 'Page/Admin/Brands/Brand/BrandRating/BrandRating';
import AddBrandGallery from 'Page/Admin/Brands/Brand/Gallery/AddBrandGallery';
import BrandGallery from 'Page/Admin/Brands/Brand/Gallery/BrandGallery';
import UpdateBrand from 'Page/Admin/Brands/Brand/UpdateBrand';
import BrandReviews from 'Page/Admin/Brands/BrandReviews/BrandReviews';
import AddProducts from 'Page/Admin/Brands/Products/AddProducts';
import Products from 'Page/Admin/Brands/Products/Products';
import UpdateProducts from 'Page/Admin/Brands/Products/UpdateProducts';
import ClientBonanza from 'Page/Admin/Client/ClientBonanza';
import ClientBrandProducts from 'Page/Admin/Client/ClientBrandProducts';
import ClientData from 'Page/Admin/Client/ClientData';
import ClientDetails from 'Page/Admin/Client/ClientDetails';
import ClientTools from 'Page/Admin/Client/ClientTools';
import ClientTransfer from 'Page/Admin/Client/ClientTransfer';
import ClientsEvents from 'Page/Admin/Client/ClientsEvents';
import ledgerClients from 'Page/Admin/Client/LedgerClients';
import NetworkRequest from 'Page/Admin/Client/NetworkRequest';
import RegisterClient from 'Page/Admin/Client/RegisterClient';
import AddEvents from 'Page/Admin/Events/AddEvents';
import EventDetails from 'Page/Admin/Events/EventDetails';
import Events from 'Page/Admin/Events/Events';
import UpdateEvents from 'Page/Admin/Events/UpdateEvents';
import AddAreas from 'Page/Admin/Locations/Areas/AddAreas';
import Areas from 'Page/Admin/Locations/Areas/Areas';
import UpdateAreas from 'Page/Admin/Locations/Areas/UpdateAreas';
import AddBranch from 'Page/Admin/Locations/Branch/AddBranch';
import Branch from 'Page/Admin/Locations/Branch/Branch';
import UpdateBranch from 'Page/Admin/Locations/Branch/UpdateBranch';
import AddCities from 'Page/Admin/Locations/Cities/AddCities';
import Cities from 'Page/Admin/Locations/Cities/Cities';
import UpdateCities from 'Page/Admin/Locations/Cities/UpdateCities';
import AddCountries from 'Page/Admin/Locations/Countries/AddCountries';
import Countries from 'Page/Admin/Locations/Countries/Countries';
import UpdateCountries from 'Page/Admin/Locations/Countries/UpdateCountries';
import AddPlanData from 'Page/Admin/Plan/PlanList/AddPlanData';
import PlanData from 'Page/Admin/Plan/PlanList/PlanData';
import UpdatePlanData from 'Page/Admin/Plan/PlanList/UpdatePlanData';
import AddPlanProducts from 'Page/Admin/Plan/PlanProducts/AddPlanProducts';
import PlanProducts from 'Page/Admin/Plan/PlanProducts/PlanProducts';
import UpdatePlanProducts from 'Page/Admin/Plan/PlanProducts/UpdatePlanProducts';
import AddPosition from 'Page/Admin/Position/AddPosition';
import AddPositionBrand from 'Page/Admin/Position/AddPositionBrand';
import Position from 'Page/Admin/Position/Position';
import PositionBrand from 'Page/Admin/Position/PositionBrand';
import UpdatePosition from 'Page/Admin/Position/UpdatePosition';
import Profile from 'Page/Admin/Profile/Profile';
import AddTools from 'Page/Admin/Tools/AddTools';
import Tools from 'Page/Admin/Tools/Tools';
import ToolsDetails from 'Page/Admin/Tools/ToolsDetails';
import UpdateTools from 'Page/Admin/Tools/UpdateTools';
import AddUsers from 'Page/Admin/Users/AddUsers';
import UpdateUsers from 'Page/Admin/Users/UpdateUsers';
import UserData from 'Page/Admin/Users/UserData';
import AddCategory from 'Page/Admin/setting/Category/AddCategory';
import Category from 'Page/Admin/setting/Category/Category';
import UpdateCategory from 'Page/Admin/setting/Category/UpdateCategory';
import AddNationalities from 'Page/Admin/setting/Nationalities/AddNationalities';
import Nationalities from 'Page/Admin/setting/Nationalities/Nationalities';
import UpdateNationalities from 'Page/Admin/setting/Nationalities/UpdateNationalities';
import AddSocialMedia from 'Page/Admin/setting/SocialMedia/AddSocialMedia';
import SocialMedia from 'Page/Admin/setting/SocialMedia/SocialMedia';
import UpdateSocialMedia from 'Page/Admin/setting/SocialMedia/UpdateSocialMedia';
import AddSubCategory from 'Page/Admin/setting/SubCategory/AddSubCategory';
import SubCategory from 'Page/Admin/setting/SubCategory/SubCategory';
import UpdateSubCategory from 'Page/Admin/setting/SubCategory/UpdateSubCategory';
import Dashboard from 'Page/Dashbord/Dashbord';
import Login from 'Page/auth/Login/Login';
import ProfileBrand from 'Page/client/ProfileBrand/ProfileBrand';
import AddCategoryAccount from 'Page/Admin/Account/Category/AddCategoryAccount';
import CategoryAccount from 'Page/Admin/Account/Category/CategoryAccount';
import UpdateCategoryAccount from 'Page/Admin/Account/Category/UpdateCategoryAccount';
import AddExpenses from 'Page/Admin/Account/Expenses/AddExpenses';
import Expenses from 'Page/Admin/Account/Expenses/Expenses';
import UpdateExpenses from 'Page/Admin/Account/Expenses/UpdateExpenses';
import AddSubCategoryAccount from 'Page/Admin/Account/SubCategory/AddSubCategoryAccount';
import SubCategoryAccount from 'Page/Admin/Account/SubCategory/SubCategoryAccount';
import UpdateSubCategoryAccount from 'Page/Admin/Account/SubCategory/UpdateSubCategoryAccount';
import BrandContactUs from 'Page/Admin/BrandContactUs/BrandContactUs';
import ClientRename from 'Page/Admin/Client/ClientRename';
import PositionClient from 'Page/Admin/Position/PositionClient';
import GeneralSettings from 'Page/Admin/setting/General/GeneralSettings';
import LogData from 'Page/Log/LogData';
import RoleDate from 'Page/Role/RoleDate';
import RoleSections from 'Page/Role/RoleSections';
import Ads from 'Page/Admin/Ads/Ads';
import AddAds from 'Page/Admin/Ads/AddAds';
import UpdateAds from 'Page/Admin/Ads/UpdateAds';
import UpgradeData from 'Page/Admin/Upgrade/UpgradeData';
import AddUpgradeData from 'Page/Admin/Upgrade/AddUpgradeData';
import UpdateUpgradeData from 'Page/Admin/Upgrade/UpdateUpgradeData';
import ChatDetails from 'Page/Chat/ChatDetails';
import Chat from 'Page/Chat/Chat';
import UpdateProfile from 'Page/Admin/Users/UpdateProfile';
import ClientsContract from 'Page/Admin/Client/ClientsContract';
import DeleteAccount from 'Page/auth/Login/DeleteAccount';
import { Accounts } from 'Page/reports/Accounts';
import BrandProductBranches from 'Page/Admin/Brands/Products/BrandProductBranches';
import ContactMessagess from 'Page/ContactMessagess/ContactMessagess';

const Component = {
    Auth,
    Accounts,
    BetterWay,
    Navber,
    UpdateProfile,
    SidebarMenu,
    Sidebar,
    SidebarSM,
    Error,
    ClientsContract,
    Dashboard,
    Auth,
    Login,
    DeleteAccount,
    // Users
    AddUsers,
    UpdateUsers,
    UserData,
    Profile,
    Areas,
    AddAreas,
    UpdateAreas,
    Countries,
    AddCountries,
    UpdateCountries,
    UpdateCities,
    Cities,
    AddCities,
    UpdateCities,
    Category, 
    AddCategory,
    UpdateCategory,
    SubCategory, 
    AddSubCategory,
    UpdateSubCategory,
    Brand,
    AddBrand,
    UpdateBrand,
    BrandContract,
    AddBrandContract,
    UpdateBrandContract,
    BrandGallery,
    AddBrandGallery, 
    Products,
    AddProducts,
    UpdateProducts,
    Branch,
    AddBranch,
    UpdateBranch,
    BrandContacts,
    AddBrandContacts, 
    UpdateBrandContacts,
    SocialMedia,
    AddSocialMedia,
    UpdateSocialMedia,
    ProfileBrand,
    ClientData,
    ClientBrandProducts,
    ClientsEvents,
    ClientTools,
    ClientTransfer,
    PlanData,
    AddPlanData,
    UpdatePlanData,
    ClientDetails,
    RegisterClient,
    PlanProducts,
    AddPlanProducts,
    UpdatePlanProducts,
    Events,
    AddEvents,
    UpdateEvents,
    EventDetails,
    Tools,
    AddTools,
    UpdateTools,
    ToolsDetails,
    ledgerClients,
    BrandRating,
    Position,
    AddPosition,
    UpdatePosition,
    Bonanza,
    BonanzaBrand,
    AddBonanza,
    UpdateBonanza,
    NetworkRequest,
    Nationalities,
    AddNationalities,
    UpdateNationalities,
    BrandReviews,
    AddBonanzaBrand,
    BonanzaClient,
    ClientBonanza,
    CompanyLedger,
    AddCompanyLedger,
    UpdateCompanyLedger,
    AddPositionBrand,
    PositionBrand,
    Expenses, 
    AddExpenses,
    UpdateExpenses,
    CategoryAccount,
    AddCategoryAccount,
    UpdateCategoryAccount,
    SubCategoryAccount,
    AddSubCategoryAccount,
    UpdateSubCategoryAccount,
    LogData,
    GeneralSettings,
    PositionClient,
    ClientRename,
    BrandContactUs,
    RoleDate, 
    RoleSections,
    Ads,
    AddAds,
    UpdateAds,
    UpgradeData,
    AddUpgradeData,
    UpdateUpgradeData,
    ChatDetails,
    BrandProductBranches,
    ContactMessagess,
    Chat 
}

export default Component


      

