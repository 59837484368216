
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const GeneralSettings = () => {
    let { isLang } = useContext(LocalizationContext);
    const toast = useRef(null);
    const dataRef = useRef(null);
    let { role } = useContext(FetchApi)

    const [visible, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [row, setRow] = useState(null);

    const setrewardpointsDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button label={isLang === "en" ? 'No' : 'لا'} outlined icon="pi pi-times" onClick={() => setModal(false)} size='small' />
            <Button label={isLang === "en" ? 'Yes' : 'نــعم'} loading={loading} raised icon="pi pi-check" size='small' onClick={() => setBalance()} />
        </div>
    );
    const hiderewardpointsDialog = (data) => {
        setRow(data?.IDGeneralSetting);
        setModal(!visible);
    };

    let { t } = useTranslation()

    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/generalsettings`;
        let data = await axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }
  

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Button onClick={() => hiderewardpointsDialog(rowData)} icon="pi pi-plus-circle" rounded outlined text raised className="mr-2" />
            </div>
        );
    };
    const setBalance = async (id) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/generalsettings/edit`, {
                IDGeneralSetting: row,
                GeneralSettingValue: dataRef?.current?.value
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            });

            const { data } = response;

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData();

                setTimeout(() => {
                    setModal(false);
                    setLoading(false);
                }, 1000);
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setLoading(false);
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchData();
        }, 1000); // 5000 milliseconds = 5 seconds
    
        return () => clearTimeout(timer); // Cleanup the timer
    }, [isLang]);
    
    return (
        <div className='mb-8 '>
            <div className="card">
                <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

                <DataTable className="custom-datatable"
                    scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    showGridlines
                    rowHover
                    globalFilter={globalFilter}
                    header={header}>
                    <Column  field="GeneralSettingName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="GeneralSettingValue" header={t('value')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="GeneralSettingDescription" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>

                    {
                        role?.EDIT_GENERAL_SETTINGS == 1 &&
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    }
                </DataTable>

            </div>

            <Dialog
                dir={isLang === "en" ? 'ltr' : 'rtl'}
                visible={visible}
                style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header={isLang === "en" ? 'Change Value' : 'تغيير القيمة'}
                modal
                footer={setrewardpointsDialogFooter}
                onHide={hiderewardpointsDialog}
            >
                <div className="flex justify-content-start gap-3 align-items-center">
                    <InputText className='w-full mt-3' ref={dataRef} />
                </div>
            </Dialog>
        </div>
    );
};

export default GeneralSettings