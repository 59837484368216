
import axios from 'axios';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const Chat = () => {
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()
    
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);



    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">  </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/clients/chat`;
        let data = await axios.post(url, { IDClient: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.ClientChat);
        setPagesNum(data?.data?.Response?.Pages)

    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])



    const items = [
        { label: <Link to={`/client`}>{t('client_name')}</Link> },
        { label: <Link to={`/client/chat/list/${id}`} className='p-2 border-round'>{t('FriendMessage')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.created_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const updated_at = (rowData) => {
        const formattedDate = format(new Date(rowData?.updated_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            {
                rowData.FriendPicture ?
                    <Image
                        preview
                        loading="lazy"
                        src={rowData.FriendPicture}
                        alt={'Image'}
                        width='90'
                        height='100%'
                        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
                    /> :
                    <img src={img.DefaultImage} alt="" width='90'
                        height='100%' className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }} />
            }
        </div>
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                <Link to={`/client/chat/client/${id}/details/${rowData?.IDClientChat}`}>
                    <Button icon="pi pi-pencil" rounded outlined raised text className="mr-2" tooltip={t('chats')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
            </div>
        );
    };
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="mb-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="card mt-5">
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter} 
                    size='normal'
                    rows={10}
                    header={header}>
                    <Column field="FriendPicture" header={t('Picture')} body={imageBodyTemplate}></Column>
                    <Column field="FriendName" header={t('FriendName')} style={{ textAlign: 'center' }}></Column>
                    <Column field="created_at" header={t('created_at')} body={date} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="updated_at" header={t('updated_at')} body={updated_at} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>

                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
        </div>
    )
}

export default Chat
