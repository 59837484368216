import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Upload, Modal, List, Avatar, message, Button, Progress } from 'antd';
import { UploadOutlined, DeleteOutlined, FileImageOutlined, FileOutlined } from '@ant-design/icons';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button as PrimeButton } from 'primereact/button';

const AddTools = () => {
  const { isLang } = useContext(LocalizationContext);
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_API_URL}/tools/add`;
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const [openImanes, setOpenImanes] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [openImanesFile, setOpenImanesFile] = useState(false);
  const [ToolVideos, setToolVideos] = useState([]);
  const [openToolVideos, setOpenToolVideos] = useState(false);

  const hideImagesProjectsDialog = (e) => setOpenImanes(false);
  const hideToolVideosProjectsDialog = (e) => setOpenToolVideos(false);

  const handleScopeChange = (index, value) => {
    const updatedToolVideos = [...formik.values.ToolVideos];
    updatedToolVideos[index] = value;
    formik.setFieldValue('ToolVideos', updatedToolVideos);
  };

  const handleAddScope = () => {
    const updatedToolVideos = [...formik.values.ToolVideos, ''];
    formik.setFieldValue('ToolVideos', updatedToolVideos);
    setToolVideos(updatedToolVideos);
  };

  const handleChange = info => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
  };

  const handleRemove = file => {
    setFileList(fileList.filter(f => f.uid !== file.uid));
  };

  const renderItem = file => {
    let icon;
    if (file.type.startsWith('image/')) {
      icon = <Avatar src={URL.createObjectURL(file.originFileObj)} />;
    } else if (file.type === 'application/pdf') {
      icon = <FileOutlined style={{ fontSize: '32px', color: '#f5222d' }} />;
    } else {
      icon = <FileImageOutlined style={{ fontSize: '32px', color: '#f5222d' }} />;
    }

    return (
      <List.Item
        key={file.uid}
        actions={[
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(file)}
          />
        ]}
      >
        <List.Item.Meta
          avatar={icon}
          title={file.name}
          description={file.type}
        />
      </List.Item>
    );
  };

  const formik = useFormik({
    initialValues: {
      ToolTitleEn: '',
      ToolTitleAr: '',
      ToolDescEn: '',
      ToolDescAr: '',
      ToolPoints: '',
      ToolPrice: '',
      ToolType: '',
      ToolGallery: selectMultiImage,
      ToolVideos: ToolVideos,
      ToolFiles: [], // We'll populate this manually in the onSubmit
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append('ToolTitleEn', values.ToolTitleEn);
        formData.append('ToolTitleAr', values.ToolTitleAr);
        formData.append('ToolDescEn', values.ToolDescEn);
        formData.append('ToolDescAr', values.ToolDescAr);
        formData.append('ToolPoints', values.ToolPoints);
        formData.append('ToolPrice', values.ToolPrice);
        formData.append('ToolType', values.ToolType);

        // Adding files to FormData
        fileList.forEach((file, index) => {
          formData.append(`ToolFiles[${index}]`, file.originFileObj);
        });

        // Adding images to FormData
        selectMultiImage.forEach((image, index) => {
          formData.append(`ToolGallery[${index}]`, image);
        });

        // Adding videos to FormData
        values.ToolVideos.forEach((video, index) => {
          formData.append(`ToolVideos[${index}]`, video);
        });

        let { data } = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted); // Update the progress state
          },
        });

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/tools`);
            resetForm();
            setUploadProgress(0); // Reset progress after upload
          }, 1000);
        } else {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        }
      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });


  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]]);
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={[
        { label: <Link to={`/tools`}>{t('tools_name')}   </Link> },
        { label: <Link to={`/tools/add`} className='p-2 border-round'>  {t('add_tools')}  </Link> }
      ]} home={{ icon: 'pi pi-home', url: '/' }} />

      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolTitleEn" className="font-bold block mb-2">{t('title_en')}</label>
            <InputText
              name='ToolTitleEn'
              id="ToolTitleEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="ToolTitleAr" className="font-bold block mb-2">{t('title_ar')}</label>
            <InputText
              name='ToolTitleAr'
              id="ToolTitleAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='ToolDescEn'
              id="ToolDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="ToolDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              name='ToolDescAr'
              id="ToolDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='ToolPrice'
              id="ToolPrice"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolPoints" className="font-bold block mb-2">{t('ClientRewardPoints')}</label>
            <InputText
              name='ToolPoints'
              id="ToolPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 mt-2 sm:col-12 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">{t('gallery')} </label>
            <div className="flex btn_gapAr gap-8">
              <PrimeButton label={t('image')} size='small' type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
              <PrimeButton label={isLang === "en" ? "Tool Videos  " : 'فيديوهات الأداة'} size='small' type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenToolVideos(!openImanes)} />
            </div>
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolType" className="font-bold block mb-2">Tool Type</label>

            <div className="flex  justify-content-  align-items-center gap-2">
              <InputText
                name='ToolType'
                id="ToolType"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <PrimeButton className="w-5 " label={isLang === "en" ? " Upload File" : 'رفع ملف'} size='small' type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanesFile(!openImanesFile)} />
            </div>
          </div>
        </div>

        {/* Progress Bar */}
        {uploadProgress > 0 && (
          <div className="mt-4">
            <Progress percent={uploadProgress} />
          </div>
        )}

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <PrimeButton raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/tools`}>
            <PrimeButton label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>
        <Dialog
          visible={openImanes}
          style={{ width: '50rem' }}
          modal
          onHide={hideImagesProjectsDialog}
          header={isLang === "en" ? " Tool Gallery" : "  معرض الأدوات"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
        >
          <div className="mt-3 mb-3 flex justify-content-center">
            <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
              <PrimeButton size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
              <PrimeButton size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
            </label>
          </div>

          <input
            type='file'
            id='fileInput'
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event) => {
              formik.setFieldValue('ToolGallery', [...selectMultiImage, event.target.files[0]]);
              handleMultiImageSelect(event);
            }}
          />

          <div className="grid  mt-2   ">
            {selectMultiImage.map((image, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col relative  ">
                <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(image)}
                    alt={'Image'}
                    width='300px'
                    height='100%'
                    className=' mx-auto overflow-hidden '
                    style={{ objectFit: 'cover' }}
                  />
                  <PrimeButton
                    size="small"
                    type="button"
                    severity='danger'
                    raised
                    className='btn__porfile bg_delete  absolute '
                    rounded
                    icon="pi pi-trash"
                    style={{ right: '15px' }}
                    onClick={() => handleDeleteImage(index)}
                  />
                </div>
              </div>
            ))}
          </div>

          <button type='button' style={{ display: 'none' }} onClick={() => document.getElementById('fileInput').click()}>
            Choose Images
          </button>
        </Dialog>

        <Dialog visible={openToolVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hideToolVideosProjectsDialog}
        >
          {ToolVideos.map((video, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col">
                <label htmlFor={`ToolVideos[${index}]`} className="font-bold block mb-2">
                  {isLang === "en" ? "Youtube Link" : 'رابط يوتيوب'}
                </label>
                <InputText
                  name={`ToolVideos[${index}]`}
                  id={`ToolVideos[${index}]`}
                  type="text"
                  className="w-full"
                  value={formik.values.ToolVideos[index]}
                  onChange={(event) => handleScopeChange(index, event.target.value)}
                />
              </div>
            </div>
          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <PrimeButton label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <PrimeButton label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hideToolVideosProjectsDialog} />
            </div>
          </div>
        </Dialog>

        <Modal
          title={isLang === "en" ? "Tool Files" : "الملفات التقنية "}
          visible={openImanesFile}
          onCancel={() => setOpenImanesFile(false)}
          footer={[
            <Button key="back" onClick={() => setOpenImanesFile(false)}>
              {t('cancel')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setOpenImanesFile(false);
                formik.setFieldValue('ToolFiles', fileList.map(file => file.originFileObj));
              }}
              disabled={fileList.length === 0}
              loading={loading}
            >
              {loading ? 'Uploading' : t('save')}
            </Button>,
          ]}
        >
          <Upload
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={() => false}
            accept=" image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt,    .pptx,   .rar,   .zip,   video/mp4,    video/mkv, video/avi,  video/mov,  video/wmv,   audio/mp3,   audio/wav,   audio/mpeg,  audio/aac,   audio/ogg,   audio/flac,   audio/m4a,   .gif"
          >
            <Button icon={<UploadOutlined />}>{t('Select Files')}</Button>
          </Upload>

          <List
            itemLayout="horizontal"
            dataSource={fileList}
            renderItem={renderItem}
            style={{ marginTop: 16 }}
          />
        </Modal>

      </form>
    </div>
  );
}

export default AddTools;
