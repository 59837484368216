import { useContext, useEffect } from 'react';

import LogoSvg from "../../constants/LogoSvg";
import { FetchApi } from 'context/FetchApi';

const useRoutes = () => {
    let { role, fetchRole } = useContext(FetchApi)

    useEffect(() => {
        fetchRole()
        localStorage.getItem('IDBrand')
    }, [])

    const routes = [
        {
            path: "",
            nameEn: "Dashboard",
            nameAr: "لوحة القيادة",
            icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
            Roles: [1, 2],
            permission: [1]

        },

        {
            path: "/users",
            nameEn: "Users",
            nameAr: "مستخدمين",
            icon: <i className='pi pi-users'></i>,
            Roles: [1],
            permission: [role?.VIEW_USERS]

        },
        {
            nameAr: "إعدادات",
            nameEn: "Configuration ",
            icon: <LogoSvg.Setting className="logoSvg" style={{ width: 17 }} />,
            Roles: [1],
            permission: [role?.VIEW_CATEGORIES, role?.VIEW_BANNERS, role?.VIEW_SOCIAL_MEDIA, role?.VIEW_NATIONALITIES, role?.VIEW_GENERAL_SETTINGS, role?.VIEW_BRAND_REQUESTS],
            subRoutes: [
                {
                    path: "/categories",
                    nameAr: "التصنيفات",
                    nameEn: "Categories",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_CATEGORIES],

                },
                {
                    path: "/subcategories",
                    nameAr: "التصنيفات الفرعية",
                    nameEn: "Sub Categories",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_CATEGORIES],

                },

                {
                    path: "/socialmedia",
                    nameAr: "مواقع التواصل الاجتماعي",
                    nameEn: "Social media",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_SOCIAL_MEDIA],

                },
                {
                    path: "/nationalities",
                    nameAr: "الجنسيات",
                    nameEn: "Nationalities",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_NATIONALITIES],

                },

                {
                    path: "/brand_request",
                    nameAr: "طلبات العلامة التجارية",
                    nameEn: "Brand requests",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_BRAND_REQUESTS],

                },
                {
                    path: "/ads",
                    nameAr: "لافتات",
                    nameEn: "Banners",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_BANNERS],
                },
                {
                    path: "/general_settings",
                    nameAr: "الاعدادات العامة",
                    nameEn: "General settings",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_GENERAL_SETTINGS],

                },
                {
                    path: "/contact-messagess",
                    nameAr: "رسائل الاتصال",
                    nameEn: "Contact Messagess",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    Roles: [1],
                },
            ],
        },
        {
            nameAr: "المواقع    ",
            nameEn: "Locations ",
            icon: <LogoSvg.Location className="logoSvg" style={{ width: 17 }} />,
            Roles: [1],
            permission: [role?.VIEW_COUNTRIES, role?.VIEW_CITIES, role?.VIEW_AREAS],

            subRoutes: [
                {
                    path: "/countries",
                    nameAr: "بلدان",
                    nameEn: "Countries",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_COUNTRIES],

                },
                {
                    path: "/cities",
                    nameAr: "المحافظات",
                    nameEn: "Governorates",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_CITIES],

                },
                {
                    path: "/areas",
                    nameAr: "المناطق",
                    nameEn: "Areas",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_AREAS],

                },
            ],
        },
        {
            nameAr: "العلامات التجارية",
            nameEn: "Brands ",
            icon: <LogoSvg.Brands className="logoSvg" style={{ width: 17 }} />,
            Roles: [1],
            permission: [role?.VIEW_BRANDS, role?.VIEW_BRANCHES, role?.VIEW_BRAND_REVIEWS, role?.VIEW_BRAND_PRODUCTS],
            subRoutes: [
                {
                    path: "/brands",
                    nameAr: "قائمة العلامات التجارية",
                    nameEn: "Brand list",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_BRANDS],

                },
                {
                    path: "/branch",
                    nameAr: "فــروع    ",
                    nameEn: "Branch",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_BRANCHES],

                },
                {
                    path: "/products",
                    nameAr: "عــروض",
                    nameEn: "Offers",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_BRAND_PRODUCTS],

                },
                {
                    path: "/reviews",
                    nameAr: "التعليقات",
                    nameEn: "Reviews",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_BRAND_REVIEWS],

                },

            ],
        },
        {
            nameEn: "Agency products",
            nameAr: "منتجات الوكالة",
            icon: <LogoSvg.Plans className="logoSvg" style={{ width: 17 }} />,
            permission: [role?.VIEW_PLANS, role?.VIEW_PLAN_PRODUCTS],
            subRoutes: [
                {
                    path: "/plans",
                    nameEn: "Plans",
                    nameAr: "الخطط",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_PLANS],

                },
                {
                    path: "/plan-products",
                    nameEn: "Products",
                    nameAr: "منتــجــات",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_PLAN_PRODUCTS],

                },
            ],
        },
        {
            path: "/client",
            nameEn: "Networkers",
            nameAr: "المسوقين الشبكيين",
            icon: <i className="pi pi-sitemap"></i>,
            permission: [role?.VIEW_CLIENTS],
        },
        {
            path: "/upgrades",
            nameEn: "Upgrades",
            nameAr: "ترقيات",
            icon: <i className="pi pi-sitemap"></i>,
            permission: [role?.VIEW_UPGRADES],
        },
        {
            path: `/brands/profile/${localStorage.getItem('IDBrand')}`,
            nameAr: "العلامات التجارية",
            nameEn: "Brand profile",
            icon: <LogoSvg.Brands className="logoSvg" style={{ width: 17 }} />,
            permission: [localStorage.getItem('Role') == 2 && 1]
        },
        {
            path: `/brands/contract/${localStorage.getItem('IDBrand')}`,
            nameEn: "Contracts",
            nameAr: "عقود",
            icon: <LogoSvg.Clients className="logoSvg" style={{ width: 17 }} />,
            permission: [localStorage.getItem('Role') == 2 && 1]
        },
        {
            path: `/brands/gallery/${localStorage.getItem('IDBrand')}`,
            nameEn: "Gallerys",
            nameAr: "معرض",
            icon: <i className="pi pi-images"></i>,
            permission: [localStorage.getItem('Role') == 2 && 1]
        },
        {
            path: `/brands/contacts/${localStorage.getItem('IDBrand')}`,
            nameEn: "Contacts",
            nameAr: "جهات الاتصال",
            icon: <i className="pi pi-phone"></i>,
            permission: [localStorage.getItem('Role') == 2 && 1]
        },
        {
            path: "/branch",
            nameAr: "فــروع    ",
            nameEn: "Branch",
            icon: <i className="pi pi-map"></i>,
            permission: [localStorage.getItem('Role') == 2 && 1]

        },
        {
            path: "/products",
            nameAr: "عــروض",
            nameEn: "Offers",
            icon: <i className="pi pi-gift"></i>,
            permission: [localStorage.getItem('Role') == 2 && 1]
        },
        {
            path: "/positions",
            nameEn: "Positions",
            nameAr: "المناصب",
            icon: <i className='pi pi-shield'></i>,
            permission: [role?.VIEW_POSITIONS],

        },
        {
            path: "/bonanza",
            nameEn: "Bonanza",
            nameAr: "بونانزا",
            icon: <i className='pi pi-gift'></i>,
            permission: [role?.VIEW_BONANZA],

        },
        {
            path: "/events",
            nameEn: "Events",
            nameAr: "الفعاليات",
            icon: <i className='pi pi-calendar-plus'></i>,
            permission: [role?.VIEW_EVENTS],
        },
        {
            path: "/tools",
            nameEn: "Tools",
            nameAr: "الأدوات",
            icon: <i className='pi pi-wrench'></i>,
            permission: [role?.VIEW_TOOLS],
        },
        {
            nameEn: "Accounts",
            nameAr: "الحسابات",
            icon: <i className='pi pi-money-bill'></i>,
            permission: [role?.VIEW_ACCOUNT_CATEGORY, role?.VIEW_ACCOUNT_EXPENSES, role?.VIEW_CATEGORIES],
            subRoutes: [
                {
                    path: "/category_account",
                    nameEn: "Categories ",
                    nameAr: "فئات",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_ACCOUNT_CATEGORY],

                },

                {
                    path: "/subcategory_account",
                    nameAr: "التصنيفات الفرعية",
                    nameEn: "Sub Categories",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_ACCOUNT_CATEGORY],

                },
                {
                    path: "/revenue",
                    nameEn: "Revenue",
                    nameAr: "إيرادات ",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_ACCOUNT_REVENUES],

                },
                {
                    path: "/expenses",
                    nameEn: "Expenses",
                    nameAr: "مصروفات",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_ACCOUNT_EXPENSES],

                },
            ],
        },
        {
            nameEn: "Reports",
            nameAr: "التقارير",
            icon: <i className='pi pi-file'></i>,
            // permission: [role?.VIEW_ACCOUNT_CATEGORY, role?.VIEW_ACCOUNT_EXPENSES, role?.VIEW_CATEGORIES],
            subRoutes: [
                {
                    path: "/better-way-depit",
                    nameEn: "Better Way Depit",
                    nameAr: "الوديعة ",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_ACCOUNT_REVENUES],

                },
                {
                    path: "/better-way-credit",
                    nameEn: "Better Way Credit",
                    nameAr: "رصيد",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                    permission: [role?.VIEW_ACCOUNT_EXPENSES],

                },
            ],
        },
        {
            path: "/roles",
            nameEn: "Roles",
            nameAr: "أدوار",
            icon: <i className='pi pi-wrench'></i>,
            permission: [localStorage.getItem('Role') == 1 && 1]
        },


    ];
    return {
        routes
    }
}


export default useRoutes;
