import AddGoogleMap from 'GoogleMap/AddGoogleMap';
import AddGallery from 'Page/Upload/AddGallery';
import AddSingleImage from 'Page/Upload/AddSingleImage';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const AddEvents = () => {
  let { fetchCity, fetchArea, city, area } = useContext(FetchApi)

  let { multiImage, setMultiImage, setSingleImage, singleImage, isLang, userLocationMap, setLoctionMap, setMarkers } = useContext(LocalizationContext);
  const extractCoordinates = (e) => {
    setLoctionMap(null)
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = e.match(regex);

    if (match) {
      setLoctionMap(null)
      const newMarker = { lat: Number(match[1]), lng: Number(match[2]) };
      setMarkers([newMarker]);
      setLoctionMap(newMarker)

    } else {
      console.error("Invalid Google Maps URL");
    }
  };
  const [openMap, setOpenMap] = useState(false);
  const hideMapDialog = (data) => {
    setOpenMap(!openMap)
  }
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/events/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const [openImanes, setOpenImanes] = useState(false);

  const items = [
    { label: <Link to={`/events`}>{t('events_name')}   </Link> },
    { label: <Link to={`/events/add`} className='p-2 border-round'>  {t('add_events')}  </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  //!hide vido= 
  const [openEventVideos, setOpenEventVideos] = useState(false);
  const [EventVideos, setEventVideos] = useState([]);

  //!hide EventVideos modal 
  const hideEventVideosProjectsDialog = (e) => setOpenEventVideos(false)

  // Handle scope change
  const handleScopeChange = (index, value) => {
    const updatedEventVideos = [...formik.values.EventVideos];
    updatedEventVideos[index] = value;
    formik.setFieldValue('EventVideos', updatedEventVideos);
  };

  // Handle adding a new scope
  const handleAddScope = () => {
    const updatedEventVideos = [...formik.values.EventVideos, ''];

    formik.setFieldValue('EventVideos', updatedEventVideos);
    setEventVideos(updatedEventVideos);
  };
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

  const formik = useFormik({
    initialValues: {
      EventTitleEn: '',
      EventTitleAr: '',
      EventDescEn: '',
      EventDescAr: '',
      EventPolicyEn: '',
      EventPolicyAr: '',
      EventMaxNumber: '',
      EventPrice: '',
      IDArea: '',
      EventStartTime: '',
      EventPoints: '',
      EventEndTime: '',
      EventInstallmentEndDate: '',
      EventGallery: multiImage,
      EventVideos: EventVideos,
      EventLogo: singleImage
    },
    onSubmit: async (values, { resetForm }) => {

      // Check if the date values are not empty strings or undefined before formatting
      // const formattedStartTime = values.EventStartTime ? format(new Date(values.EventStartTime.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm:ss") : '';
      // const formattedEndTime = values.EventEndTime ? format(new Date(values.EventEndTime.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm:ss") : '';
      // const formattedInstallmentEndDate = values.EventInstallmentEndDate ? format(new Date(values.EventInstallmentEndDate.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm") : '';

      const formattedStartTime = values.EventStartTime ? format(new Date(values.EventStartTime.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';
      const formattedEndTime = values.EventEndTime ? format(new Date(values.EventEndTime.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';
      const formattedInstallmentEndDate = values.EventInstallmentEndDate ? format(new Date(values.EventInstallmentEndDate.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';

      try {
        setLoading(true);
        let { data } = await axios.post(url, {
          EventTitleEn: values.EventTitleEn,
          EventTitleAr: values.EventTitleAr,
          EventDescEn: values.EventDescEn,
          EventDescAr: values.EventDescAr,
          EventPolicyEn: values.EventPolicyEn,
          EventPolicyAr: values.EventPolicyAr,
          EventMaxNumber: values.EventMaxNumber,
          EventPrice: values.EventPrice,
          EventPoints: values.EventPoints,
          IDArea: values.IDArea,
          EventGallery: multiImage,
          EventVideos: values.EventVideos,
          EventLogo: singleImage,
          EventStartTime: formattedStartTime,
          EventEndTime: formattedEndTime,
          EventInstallmentEndDate: formattedInstallmentEndDate,
          EventLatitude: userLocationMap?.lat,
          EventLongitude: userLocationMap?.lng,
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setMultiImage([])
          setSingleImage(null)
          setTimeout(() => {
            setLoading(false);
            navigate(`/events`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });
  useEffect(() => {
    formik.setFieldValue('EventGallery', multiImage);
  }, [multiImage]); // يتم استدعاء useEffect عندما تتغير multiImage


  useEffect(() => {
    formik.setFieldValue('EventLogo', singleImage);
  }, [singleImage]); // يتم استدعاء useEffect عندما تتغير multiImage

  useEffect(() => {
    fetchCity(1)
    return () => {
      fetchCity(1)
    }
  }, [isLang])
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="mb-4">
          <label htmlFor="BrandNameEn" className="font-bold block mb-2"> Event Logo </label>
          <AddSingleImage />
          <label htmlFor="BrandNameEn" className="font-bold block mb-2">{isLang === "en" ? " Event Gallery" : "معرض الأحداث"}</label>
          <AddGallery />
        </div>
        <div className="grid">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventTitleEn" className="font-bold block mb-2">{t('title_en')}</label>
            <InputText
              name='EventTitleEn'
              id="EventTitleEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="EventTitleAr" className="font-bold block mb-2">{t('title_ar')}</label>
            <InputText
              name='EventTitleAr'
              id="EventTitleAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='EventDescEn'
              id="EventDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="EventDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              name='EventDescAr'
              id="EventDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventPolicyEn" className="font-bold block mb-2">{t('EventPolicyEn')}</label>
            <InputTextarea
              name='EventPolicyEn'
              id="EventPolicyEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="EventPolicyAr" className="font-bold block mb-2">{t('EventPolicyAr')}</label>
            <InputTextarea
              name='EventPolicyAr'
              id="EventPolicyAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='EventPrice'
              id="EventPrice"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventPoints" className="font-bold block mb-2">{t('ClientRewardPoints')}</label>
            <InputText
              name='EventPoints'
              id="EventPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="EventMaxNumber" className="font-bold block mb-2">{t('EventMaxNumber')}</label>
            <InputText
              name='EventMaxNumber'
              id="EventMaxNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
            <label htmlFor="IDCity" className="font-bold block mb-2"> {t('tabel-header-cities-1')}</label>
            <Dropdown filter
              options={city?.map(item => ({
                name: item.CityName,
                value: item.IDCity,
              }))}
              id="IDCity"
              name="IDCity"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDCity} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDCity", e.value)
                fetchArea(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('city_placeholder')}
              className="w-full p-inputtext-sm"
            />

          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
            <label htmlFor="IDArea" className="font-bold block mb-2"> {t('tabel-header-areas-1')}</label>
            <Dropdown filter
              options={area?.map(item => ({
                name: item.AreaName,
                value: item.IDArea,
              }))}
              id="IDArea"
              name="IDArea"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDArea} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDArea", e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('area_placeholder')}
              className="w-full p-inputtext-sm"
            />

          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="EventStartTime" className="font-bold block mb-2"> {t('start_time')}</label>
            <Calendar
              id="EventStartTime"
              name="EventStartTime"
              value={formik.values.EventStartTime}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Event start time' : 'وقت بداية الحدث'}
              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              icon="pi pi-clock"
              stepMinute={5}
              readOnlyInput
              touchUI
            />

          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="EventEndTime" className="font-bold block mb-2"> {t('end_time')}</label>
            <Calendar
              id='EventEndTime'
              name="EventEndTime"
              value={formik.values.EventEndTime}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Event end time' : 'وقت نهاية الحدث'}
              showIcon
              showTime
              hourFormat="24"
              className='w-full'
              icon="pi pi-clock"
              stepMinute={5}
              touchUI
              minDate={
                formik.values.EventStartTime
                  ? new Date(formik.values.EventStartTime.getTime())
                  : new Date()
              }
            />
          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="EventInstallmentEndDate" className="font-bold block mb-2"> {t('InstallmentEndDate')}</label>
            <Calendar
              id='EventInstallmentEndDate'
              name="EventInstallmentEndDate"
              value={formik.values.EventInstallmentEndDate}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Installment end time' : 'وقت نهاية الحدث'}
              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              iconPos="right"
              icon="pi pi-clock"
              stepMinute={5}
              touchUI
              maxDate={
                formik.values.EventStartTime
                  ? new Date(formik.values.EventStartTime.getTime())
                  : new Date()
              }
            /**
             * 
            maxDate={
              formik.values.EventStartTime
              ? new Date(formik.values.EventStartTime.getTime() - 24 * 60 * 60 * 1000)
              : new Date()
            }
            */
            />
          </div>

          <div className="lg:col-6 md:col-12 mt-2 sm:col-12 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">{t('gallery')} </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={isLang === "en" ? "Event Videos  " : 'فيديوهات الحدث'} size='small' type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenEventVideos(!openImanes)} />
            </div>
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BranchPhone" className="font-bold block mb-2">{t('tooltip_location')}</label>
            <div className="flex flex-row gap-2" >

              <Link className='location_branch  ' onClick={() => hideMapDialog()}>
                <Button label={t('location')} size='small' icon='pi pi-map-marker' tooltip={t('tooltip_location')} tooltipOptions={{ position: t('tooltip') }} severity='success' outlined className="mr-2" />
              </Link>

              <InputText
                type='text'
                className="w-full p-inputtext-sm"
                onChange={(e) => extractCoordinates(e.target.value)}
                placeholder={isLang === "en" ? "Paste Google Maps URL here" : 'الصق عنوان URL الخاص بخرائط جوجل هنا'}
              />
            </div>
          </div>
        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/events`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>



        {/*****Model Add Multi EventVideos */}
        <Dialog visible={openEventVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hideEventVideosProjectsDialog}>

          {/* EventVideos */}
          {EventVideos.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="EventVideos" className="font-bold block mb-2">  {isLang === "en" ? "  Youtube Link" : 'رابط يوتيوب'}    </label>
                <InputText
                  name='EventVideos'
                  id="EventVideos"
                  type="text"
                  className="w-full"
                  defaultValue={scope}
                  onChange={(event) => handleScopeChange(index, event.target.value)}
                />

              </div>
            </div>


          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hideEventVideosProjectsDialog} />
            </div>

          </div>

        </Dialog>

        {/* Google map add location */}
        <AddGoogleMap
          openMap={openMap}
          hideMapDialog={hideMapDialog}
          isLang={isLang}
        />
      </form>
    </div>
  )
}



export default AddEvents