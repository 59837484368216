import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

const BrandProductBranches = () => {
    let { id } = useParams()
    let { role } = useContext(FetchApi)
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);

    // location path 
    const location = useLocation();


    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/brands/products/branches`;
        let data = await axios.post(url, { IDBrandProduct: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }

    useEffect(() => {
        fetchData();
    }, [isLang, page])

    const [editingRow, setEditingRow] = useState(null);

    const statusOption = [
        { label: t('active'), value: 1 },
        { label: t('inactive'), value: 0 },
    ];


    const onStatusChange = async (rowData, e) => {

        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/brands/products/branches/status`, {
            IDBranch: rowData?.IDBranch,
            IDBrandProduct: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {
            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.ProductBranchStatus === 1 ? t('active') : t('inactive');
        const placeholderText = rowData?.ProductBranchStatus !== 1 ? t('inactive') : t('active');

        return editingRow === rowData?.IDBranch ? (
            <Dropdown
                value={editingStatus}
                options={statusOption.filter((option) => option.value !== rowData?.ProductBranchStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.ProductBranchStatus === 1 ? t('active') : t('inactive')}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.ProductBranchStatus)}
                onClick={() => {
                    if (role?.EDIT_BRAND_PRODUCTS == 1) {
                        setEditingRow(rowData?.IDBranch)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>{`${rowData?.ProductBranchStatus === 1 ? t('active') : t('inactive')}`}</span>
                    {
                        role?.EDIT_BRAND_PRODUCTS == 1 &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };

    const statusBodyTemplate2 = (rowData) => {
        return editingRow === rowData?.IDBranch ? (
            <></>
        ) : (
            <Tag
                value={`${rowData?.ProductBranchStatus === 1 ? t('active') : t('inactive')}`}
                severity={getSeverity(rowData.ProductBranchStatus)}
                rounded
            />
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case 1:
                return 'success';

            case 0:
                return 'danger';
            case '':
                return 'info';
        }
    };

    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])

    const items = [
        { label: <Link to={'/products'}>{t('products')}</Link> },
        { label: <Link to={`/products/branches/${id}`} className='p-2 border-round'>{t('Branches')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    return (
        <div  className='mt-6'>
            <BreadCrumb model={items} home={home} />
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <DataTable
                    className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                >
                    <Column field="AreaName" header={t('areas_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BranchAddress" header={t('addres')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>

                    {
                        localStorage.getItem('Role') != 2 ?
                            <Column field="ProductBranchStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column> :
                            <Column field="ProductBranchStatus" header={t('status')} body={statusBodyTemplate2} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    }
                </DataTable>

            </div>
        </div>
    )
}

export default BrandProductBranches