import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
const BrandRating = ({ modalDone, setModalDone, dataSocialMedia }) => {
    const dt = useRef(null);
    const toast = useRef(null);

    let { role } = useContext(FetchApi)


    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [Data, setData] = useState(null);
    const [editingRow, setEditingRow] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const statusOptions = [
        { label: t('SHOW'), value: 'SHOW' },
        { label: t('HIDE'), value: 'HIDE' },
    ];
    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/brands/ratings`;
        let data = await axios.post(url, { IDPage: page + 1, IDBrand: dataSocialMedia?.IDBrand },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setPagesNum(data?.data?.Response?.Pages)

        setData(data?.data?.Response?.BrandRatings);
    }
    useEffect(() => {
        if (modalDone === true) {
            fetchData();
        }
    }, [isLang, dataSocialMedia?.IDBrand,page])



    // handel image in tabel
    const nameBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <span>{rowData?.BrandRating}</span>
        </div>
    };
    const onStatusChange = async (rowData, e) => {
 
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/brands/ratings/status`, {
            IDBrandRating: rowData?.IDBrandRating,
            BrandRatingStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success === true) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
        })
    };


    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.BrandRatingStatus === "SHOW" ? t('SHOW') : rowData?.BrandRatingStatus === "HIDE" ? t('HIDE') : t('pending');
        const placeholderText = rowData?.BrandRatingStatus !== 'SHOW' ? t('HIDE') : t('SHOW');

        return editingRow === rowData?.IDBrandRating ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.BrandRatingStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm  "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.BrandRatingStatus)}
                onClick={() => {
                    if (role?.EDIT_BRAND_REVIEWS == 1) {
                        setEditingRow(rowData?.IDBrandRating)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>{editingStatus}   </span>
                    {
                        role?.EDIT_BRAND_REVIEWS == 1 &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };
    const getSeverity = (status) => {
        switch (status) {
            case 'HIDE':
                return 'danger';
            case 'SHOW':
                return 'success';
            case 'PENDING':
                return 'warning';
        }
    };



    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalDone}
                style={{ width: '50rem' }}
                modal
                onHide={setModalDone}
                header={isLang === "en" ? "Brand rating" : "تقييم العلامة التجارية"}
                dir={isLang === "en" ? 'ltr' : 'rtl'}
                responsive
            >
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"

                    rows={10} >
                    <Column field="BrandRating" body={nameBodyTemplate} header={t('Rating')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandReview" header={t('Review')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientName" header={t('name')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientPhone" header={t('phone')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandRatingStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                </DataTable>

                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </Dialog>
        </div>
    )
}

export default BrandRating
