import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const PositionClient = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setloading] = useState(false);
    const [Data, setData] = useState(null);
    const toast = useRef(null);

    const items = [
        { label: <Link to={'/positions'}>{t('positions')}</Link> },
        { label: <Link to={`/positions/clients/${id}`} className='px-3 py-2 border-round'>{t('clients_positions')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">  </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/positions/clients`;
        let { data } = await axios.post(url, { IDPosition: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.Response);
    }

    useEffect(() => {
        fetchData();
    }, [isLang])

    const [open, setOpen] = useState(false);
    const [viewData, setViewData] = useState(null);
    const [openAccept, setAccept] = useState(false);
    const [viewAccept, setViewAccept] = useState(null);

    const hideOpen = () => setOpen(false);
    const hideAccept = () => setAccept(false);

    const hideViewDialog = (data) => {
        setOpen(true)
        setViewData(data)
    };
    const hideAcceptDialog = (data) => {
        setAccept(true)
        setViewAccept(data)
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                <Button onClick={() => hideViewDialog(rowData)} className="disabled-button" icon={<i className="pi pi-times-circle" style={{ color: 'red', fontSize: '14px' }}></i>} rounded outlined text raised severity='success' />
            </div>
        );
    };
    const acceptBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                <Button onClick={() => hideAcceptDialog(rowData)} shape='circle' className="disabled-button" icon={<i className="pi pi-check-circle" style={{ color: 'green', fontSize: '14px' }}></i>} rounded outlined text raised severity='success' />
            </div>
        );
    };

    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button  onClick={hideOpen} raised outlined   >{isLang == "en" ? 'No' : 'لا'} </Button>
            <Button  loading={loading} raised onClick={() => rejectPosition()}   >{isLang == "en" ? 'Yes' : 'نــعم'}</Button>
        </div>
    );

    const AcceptDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button  onClick={hideAccept} raised outlined   >{isLang == "en" ? 'No' : 'لا'} </Button>
            <Button  loading={loading} raised onClick={() => AcceptPosition()} type='primary'  >{isLang == "en" ? 'Yes' : 'نــعم'}</Button>
        </div>
    );
    const rejectPosition = async () => {
        console.log(viewData?.ID);
        setloading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/positions/clients/reject/${viewData?.ID}`, {

        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setloading(true)
                hideOpen()
                setloading(false)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setloading(false)

        })
    }
    const AcceptPosition = async () => { 
        setloading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/clients/position/update`, {
            IDClient: viewAccept?.IDClient,
            IDPosition: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setloading(true)
                hideAccept()
                setloading(false)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setloading(false)

        })
    }
    return (
        <div >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="mt-3 mb-5">
                <BreadCrumb model={items} home={home} />
            </div>

            <div className="card">
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    header={header}>
                    <Column field="ClientName" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} header={t('Reject')} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    <Column body={acceptBodyTemplate} header={t('Accept')} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    <Column field="ClientPosition" header={t('positions')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientPhone" header={t('phone')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PositionTitle" header={t('title')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="LeftPoints" header={t('ClientLeftPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="RightPoints" header={t('ClientRightPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="AllPoints" header={t('ClientTotalPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="ReferralNumber" header={t('ReferralNumber')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="LeftNumber" header={t('ClientLeftNumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="RightNumber" header={t('ClientRightNumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="AllNumber" header={t('ClientTotalNumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="Visits" header={t('visits')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="ChequeValue" header={t('ChequeValue')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                </DataTable>
            </div>
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'}
                visible={open}
                style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header={isLang == "en" ? 'Positions Reject' : 'تفاصيل المناصب'}
                footer={deleteusersDialogFooter}
                onHide={hideOpen}>
                <span>{isLang == "en" ? 'Do you want to reject this position?' : 'هل أنت تريد ان ترفض هذا المنصب'}</span>

            </Dialog>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'}
                visible={openAccept}
                style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header={isLang == "en" ? 'Positions Accept' : ' قبول المناصب'}
                footer={AcceptDialogFooter}
                onHide={hideAccept}>
                <span>{isLang == "en" ? 'Do you want to accept this position?' : 'هل أنت تريد قبول المناصب هذا المنصب'}</span>

            </Dialog>
        </div>
    )
}

export default PositionClient