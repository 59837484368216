
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const CompanyLedger = () => {
    let { isLang } = useContext(LocalizationContext);
    let { role } = useContext(FetchApi)

    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    let { subCategoriesAccountAjex, fetchSubCategoriesAccountAjex, categoriesAccountAjex, fetchCategoriesAccountAjex } = useContext(FetchApi)
    const [idCategory, setIDCategory] = useState(null);
    const [idSubCategory, setIDSubCategory] = useState(null);
    // Add new Tools
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/revenue/add">
                    <Button severity="warning" label={t('add_accounts')} icon="pi pi-plus" size='small' />
                </Link>
            </div>
        );
    };


    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                {
                    role?.EDIT_ACCOUNT_REVENUES == 1 &&
                    <>
                        {
                            rowData?.CompanyLedgerProcess === "AUTO" ?
                                <Button icon="pi pi-pencil" disabled={rowData?.CompanyLedgerProcess === "AUTO" ? true : false} rounded outlined raised text className="mr-2" tooltip={t('update_accounts')} tooltipOptions={{ position: t('tooltip') }} />
                                :
                                <Link to={`/revenue/edit/${rowData.IDCompanyLedger}`}>
                                    <Button icon="pi pi-pencil" rounded outlined raised text className="mr-2" tooltip={t('update_accounts')} tooltipOptions={{ position: t('tooltip') }} />
                                </Link>
                        }
                    </>
                }

                <Tooltip target=".disabled-button" autoHide={false} />
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDCompanyLedger}/COMPANYLEDGER`}>
                        <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }
            </div>
        );
    };
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className='flex gap-4'>
                <Dropdown filter  
                    options={categoriesAccountAjex?.map(item => ({
                        name: item.CategoryName,
                        value: item.IDCategory,
                    }))}
                    id="IDCategory"
                    name="IDCategory"
                    optionLabel="name"
                    optionValue="value"
                    value={idCategory} // Add this line 
                    onChange={(e) => {
                        setIDSubCategory(null);
                        setIDCategory(e.value);
                        fetchSubCategoriesAccountAjex(e.value)
                        fetchData()
                    }}
                    placeholder={t('Category_placeholder')}
                    className="w-14rem p-inputtext-sm"
                />
                <Dropdown filter  
                    options={subCategoriesAccountAjex?.map(item => ({
                        name: item.SubCategoryName,
                        value: item.IDSubCategory,
                    }))}
                    id="IDSubCategory"
                    name="IDSubCategory"
                    optionLabel="name"
                    optionValue="value"
                    value={idSubCategory} // Add this line 
                    onChange={(e) => {
                        setIDSubCategory(e.value);
                        fetchData()
                    }}
                    placeholder={t('SubCategory_placeholder')}
                    className="w-14rem p-inputtext-sm"
                />

            </div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                {/* <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} /> */}
            </span>

        </div>
    );
    useEffect(() => {
        fetchCategoriesAccountAjex("CREDIT")
    }, [isLang])
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);

    const fetchProfileData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/company/ledger`;
        let data = await axios.post(url, { IDPage: page + 1, CompanyLedgerType: "CREDIT" },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.CompanyLedger);
        setPagesNum(data?.data?.Response?.Pages)
    }
    useEffect(() => {
        fetchProfileData();
    }, [isLang, page])

    // Filter
    const fetchData = async (idCate, idSubCate) => {
        
        const url = `${process.env.REACT_APP_API_URL}/company/ledger`;
        let data = await axios.post(url, { IDPage: page + 1, IDCategory: idCate, IDSubCategory: idSubCate, CompanyLedgerType: "CREDIT" },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.CompanyLedger);
        setPagesNum(data?.data?.Response?.Pages)
    }

    useEffect(() => {
        if (idCategory || idSubCategory) {
            fetchData(idCategory, idSubCategory);
        }
    }, [isLang, page, idCategory, idSubCategory])
    


    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.created_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };

    const enddate = (rowData) => {
        const formattedDate = format(new Date(rowData?.updated_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_ACCOUNT_REVENUES == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    rows={10}
                    showGridlines
                    header={header}>
                    <Column  field="SubCategoryName" header={t('tabel-header-Product-2')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="CategoryName" header={t('Category')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="CompanyLedgerDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="CompanyLedgerAmount" header={t('amount')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {/* <Column  field="CompanyLedgerProcess" header={t('Process')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    {/* <Column  field="CompanyLedgerType" header={t('type')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column  field="created_at" header={t('created_at')} body={date} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

        </div>
    )
}

export default CompanyLedger