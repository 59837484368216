import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Modal, Button, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const ClientsContract = () => {
    let { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMap, setLoadingMap] = useState({}); // This will track loading states per document ID
    const [visible, setVisible] = useState(false);
    const [iframeSrc, setIframeSrc] = useState('');

    async function fetchData() {
        setLoading(true);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}/clients/contracts`, 
                { client_id: id },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
                    },
                }
            );
            setData(data?.Response);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const showModal = (url) => {
        setIframeSrc(url.endsWith('.pdf') ? url : url.endsWith('.doc') || url.endsWith('.docx') ? `https://docs.google.com/gview?url=${url}&embedded=true` : '');
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const deleteDocument = async (documentId) => {
        setLoadingMap(prev => ({ ...prev, [documentId]: true }));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/clients/contract/${documentId}`, {}, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            });
            message.success('Document deleted successfully');
            fetchData(); 
        } catch (error) {
            console.error('Failed to delete document:', error);
            message.error('Failed to delete document');
        }
        setLoadingMap(prev => ({ ...prev, [documentId]: false }));
    };

    const columns = [
        {
            title: 'Contract view',
            dataIndex: 'ClientDocumentPath',
            key: 'ClientDocumentPath',
            render: (text) => <Button onClick={() => showModal(text)}>View Document</Button>,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Delete',
            key: 'Delete',
            render: (_, record) => <Button danger onClick={() => deleteDocument(record.IDClientDocument)} loading={loadingMap[record.IDClientDocument]} icon={<DeleteOutlined />} /> ,
        },
    ];

    return (
        <>
            <Table dataSource={data} columns={columns} rowKey="IDClientDocument" loading={loading} />
            <Modal
                title="View Document"
                visible={visible}
                onCancel={handleCancel}
                footer={null} 
                style={{ top: 20 }}
                bodyStyle={{ height: '80vh' }}
                centered
            >
                {iframeSrc ? (
                    <iframe src={iframeSrc} style={{ width: '100%', height: '100%' }} frameBorder="0" />
                ) : (
                    <p>Unsupported file format</p>
                )}
            </Modal>
        </>
    );
};

export default ClientsContract;
