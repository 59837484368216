import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import img from 'constants/Img';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

const AddBrandContacts = () => {
    const { id } = useParams()

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/brands/contacts/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()


    const items = [
        { label: <Link to={`/brands`}>{t('brand_name')}   </Link> },
        { label: <Link to={`/brands/contacts/${id}`}>{t('brand_contacts')}   </Link> },
        { label: <Link to={`/brands/contacts/${id}/add`} className='p-2 border-round'>  {t('add_brand_contacts')}  </Link> }
    ];


    const home = { icon: 'pi pi-home', url: '/' };

    const formik = useFormik({
        initialValues: {
            IDBrand: id,
            BrandContactTitle: '',
            BrandContactName: '',
            BrandContactPhone: '',

        },
        onSubmit: async (values, { resetForm }) => {
            
            try {
                setLoading(true);
                let { data } = await axios.post(url, values,
                    {
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })

                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate(`/brands/contacts/${id}`)
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });

    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>


                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="BrandContactTitle" className="font-bold block mb-2">{isLang === "en" ? 'Brand Contact Title' : 'عنوان جهة اتصال العلامة التجارية'}</label>
                        <InputText
                            name='BrandContactTitle'
                            id="BrandContactTitle"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="BrandContactName" className="font-bold block mb-2">{t('name')}</label>

                        <InputText
                            name='BrandContactName'
                            id="BrandContactName"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            dir='ltr'
                        />
                    </div>
                </div>

                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="BrandContactPhone" className="font-bold block mb-2">{t('phone')}</label>
                 
                        <InputNumber
                            name="BrandContactPhone"
                            id="BrandContactPhone"
                            className="w-full"
                            inputClassName="p-inputtext-sm"
                            onValueChange={(e) => {
                                formik.setFieldValue("BrandContactPhone", '+20' + e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            mode="decimal"
                            useGrouping={false}
                            dir="ltr"
                            pattern="\d*"
                            maxLength={10}
                        />
                    </div>
                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={`/brands/contacts/${id}`}>
                        <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
                    </Link>
                </div>


            </form>
        </div>
    )
}

export default AddBrandContacts