import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Products = () => {
  let { role, brandsAjex, fetchBrandsAjex } = useContext(FetchApi)
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const inputRef = useRef(null);

  // Add new offer
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/products/add">
          <Button severity="warning" label={t('products_add')} icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />
      </div>
    );
  };
  const [statusOptions, setStatusOptions] = useState([]);

  const [byFilter, setByFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [idBrand, setIDBrand] = useState(null);

  const OptionsFilter = [
    { label: isLang === "en" ? 'Offer  Title' : 'العروض ', value: 1 },
    { label: isLang === "en" ? 'Brand  ' : 'البراند', value: 2 },
    { label: isLang === "en" ? ' Offer Status   ' : 'حاله العروض  ', value: 3 },
    { label: isLang === "en" ? 'Start & End Date  ' : 'تاريخ بدايه و نهايه', value: 4 },
  ];
  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center ">
        <Tooltip target=".disabled-button" autoHide={false} />
        {
          role?.EDIT_BRAND_PRODUCTS == 1 &&
          <Link to={`/products/edit/${rowData.IDBrandProduct}`}>
            <Button icon="pi pi-pencil" tooltip={t('Update')} tooltipOptions={{ position: t('tooltip') }} rounded outlined text raised className="mr-2" />
          </Link>
        }
        {
          role?.EDIT_BRAND_PRODUCTS == 1 &&
          <Link to={`/products/branches/${rowData.IDBrandProduct}`}>
            <Button icon="pi pi-map" severity="success" tooltip={t('Branches')} tooltipOptions={{ position:  t('tooltip')}} rounded outlined text raised className="mr-2" />
          </Link>
        }
        {
          localStorage.getItem('Role') == 1 &&
          <Link to={`/log/${rowData.IDBrandProduct}/BRAND_PRODUCT`}>
            <Button icon="pi pi-file" rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} text raised />
          </Link>
        }
      </div>
    );
  };
  // location path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between flex-row-reverse">
      <div className="lg:col-2 md:col-12 sm:col-12     ">
        <Dropdown filter
          value={byFilter}
          options={OptionsFilter?.map(item => ({
            name: item.label,
            value: item.value,
          }))}
          id="IDProduct"
          name="IDProduct"
          optionLabel="name"
          optionValue="value"
          onChange={(e) => {
            setByFilter(e.value)
          }}
          placeholder={isLang === "en" ? 'Filter by' : 'اختر المنتج'}
          className="p-inputtext-sm w-full"
        />
      </div>
      {
        byFilter === 1 &&
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchData(e.target.value);
              }
              if (e.target.value == '') {
                fetchData(e.target.value);
              }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
          />
        </span>
      }
      {
        byFilter === 3 &&
        <div className="lg:col-2 md:col-12 sm:col-12     ">
          <Dropdown
            filter
            options={statusOptions}
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.value);
              filterDataByStatus(e.value);
            }}
            placeholder={isLang === "en" ? 'Select a status' : 'حدد الحالة'}
            className="p-inputtext-sm w-full"
          />
        </div>
      }
      {
        byFilter === 4 &&
        <div className='flex gap-4 flex-row w-100 lg:col-9 md:col-12 sm:col-12 '>
          <div className={` filter_by_date ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <Calendar
              value={selectedStartDate ? new Date(selectedStartDate) : null}
              placeholder={isLang === "en" ? ' Start date' : '  تاريخ بدا   '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                setSelectedStartDate(selectedDate)
              }}
              showIcon
              className="p-inputtext-sm w-full h-100"
            />
          </div>
          <div className={`filter_by_date ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <Calendar
              value={selectedEndDate ? new Date(selectedEndDate) : null}
              placeholder={isLang === "en" ? ' End date' : '  تاريخ نهاية   '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                setSelectedEndDate(selectedDate)
              }}
              showIcon
              minDate={selectedStartDate ? new Date(selectedStartDate) : null}
              className="p-inputtext-sm w-full"

            />
          </div>
          <Button className='w-10rem' text raised onClick={() => filterDataByDate(selectedStartDate, selectedEndDate)} label={isLang === "en" ? 'Search by date' : 'البحث حسب التاريخ'} outlined severity="warning" icon="pi pi-calendar-plus" size='small' />

        </div>
      }
      {
        byFilter === 2 &&
        <div className="lg:col-2 md:col-12 sm:col-12     ">
          <Dropdown filter
            options={brandsAjex?.map(item => ({
              name: item.BrandName,
              value: item.IDBrand,
            }))}
            id="IDBrand"
            name="IDBrand"
            optionLabel="name"
            optionValue="value"
            value={idBrand} // Add this line 
            onChange={(e) => {
              filterDataByIDBrand(e.value);
              setIDBrand(e.value);
            }}
            placeholder={t('brand_placeholder')}
            className="w-full p-inputtext-sm"
          />
        </div>
      }
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/brands/products`;
    let data = await axios.post(url, { IDPage: page + 1, SearchKey },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setPagesNum(data?.data?.Response?.Pages)
    setData(data?.data?.Response?.BrandProducts);
    setPagesNum(data?.data?.Response?.Pages)

  }
  //ACTIVE  - BLOCKED  - INACTIVE
  const filterDataByStatus = async (status) => {

    const url = `${process.env.REACT_APP_API_URL}/brands/products`;
    let data = await axios.post(url, { BrandProductStatus: status },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setPagesNum(data?.data?.Response?.Pages)
    setData(data?.data?.Response?.BrandProducts);
    setPagesNum(data?.data?.Response?.Pages)
  }
  // filter by IDBrand
  const filterDataByIDBrand = async (IDBrand) => {

    const url = `${process.env.REACT_APP_API_URL}/brands/products`;
    let data = await axios.post(url, { IDBrand },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setPagesNum(data?.data?.Response?.Pages)
    setData(data?.data?.Response?.BrandProducts);
    setPagesNum(data?.data?.Response?.Pages)
  }

  // filter by start date , end date
  const filterDataByDate = async (start, end) => {

    const url = `${process.env.REACT_APP_API_URL}/brands/products`;
    let data = await axios.post(url, { BrandProductStartDate: start, BrandProductEndDate: end },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setPagesNum(data?.data?.Response?.Pages)
    setData(data?.data?.Response?.BrandProducts);
    setPagesNum(data?.data?.Response?.Pages)
  }

  useEffect(() => {
    fetchData();
    fetchBrandsAjex()
  }, [isLang, page])

  const [editingRow, setEditingRow] = useState(null);

  const statusOption = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('pending'), value: 'PENDING' },
    { label: t('deactivated'), value: 'DEACTIVATED' },
  ];


  const onStatusChange = async (rowData, e) => {

    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/brands/products/status`, {
      IDBrandProduct: rowData?.IDBrandProduct,
      BrandProductStatus: e?.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.BrandProductStatus === "ACTIVE" ? t('active') : rowData?.BrandProductStatus === "DEACTIVATED" ? t('deactivated') : t('pending');
    const placeholderText = rowData?.BrandProductStatus !== 1 ? t('deactivated') : t('active');

    return editingRow === rowData?.IDBrandProduct ? (
      <Dropdown
        value={editingStatus}
        options={statusOption.filter((option) => option.value !== rowData?.BrandProductStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.BrandProductStatus === "ACTIVE" ? t('active') : rowData?.BrandProductStatus === "DEACTIVATED" ? t('deactivated') : t('pending')}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.BrandProductStatus)}
        onClick={() => {
          if (role?.EDIT_BRAND_PRODUCTS == 1) {
            setEditingRow(rowData?.IDBrandProduct)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>{`${rowData?.BrandProductStatus === "ACTIVE" ? t('active') : rowData?.BrandProductStatus === "DEACTIVATED" ? t('deactivated') : t('pending')}`}</span>
          {
            role?.EDIT_BRAND_PRODUCTS == 1 &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };

  const statusBodyTemplate2 = (rowData) => {
    return editingRow === rowData?.IDBrandProduct ? (
      <></>
    ) : (
      <Tag
        value={`${rowData?.BrandProductStatus === "ACTIVE" ? t('active') : rowData?.BrandProductStatus === "DEACTIVATED" ? t('deactivated') : t('pending')}`}
        severity={getSeverity(rowData.BrandProductStatus)}
        rounded
      />
    );
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success';

      case 'DEACTIVATED':
        return 'danger';

      case 'PENDING':
        return 'warning';

      case '':
        return 'info';
    }
  };
  const date = (rowData) => {
    const formattedDate = format(new Date(rowData?.BrandProductStartDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };

  const enddate = (rowData) => {
    const formattedDate = format(new Date(rowData?.BrandProductEndDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };
  const BrandProductDiscount = (rowData) => {
    return <span>{rowData?.BrandProductDiscountType === "VALUE" ? `${rowData?.BrandProductDiscount} ${t('currency')}` : `${rowData?.BrandProductDiscount} %`}</span>;
  };
  useEffect(() => {
    const loadOptions = () => {
      setStatusOptions([
        { label: t('active'), value: 'ACTIVE' },
        { label: t('pending'), value: 'PENDING' },
        { label: t('deactivated'), value: 'DEACTIVATED' },
      ]);
    };

    loadOptions();
  }, []);

  const resetData = () => {
    fetchData();
    setSelectedStatus(null)
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setIDBrand(null)
    setByFilter(null)
    // inputRef.current.value = '';
  }
  return (
    <div  >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        {
          (localStorage.getItem('Role') != 2 && role?.ADD_BRAND_PRODUCTS == 1) &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }

        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="BrandProductTitle" header={t('tabel-header-Product-1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductDesc" header={t('tabel_brand_header_2')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="SubCategoryName" header={t('tabel-header-Product-2')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {
            localStorage.getItem('Role') != 2 &&
            <Column field="BrandName" header={t('tabel-header-Product-3')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          }
          <Column field="BrandProductPrice" header={t('price')} style={{ textAlign: 'center' }}></Column>
          <Column field="BrandProductDiscount" body={BrandProductDiscount} header={t('discount')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductPoints" header={t('ClientRewardPoints')} style={{ textAlign: 'center' }}></Column>
          {/* <Column field="BrandProductPoints" header={t('ClientRewardPoints')} style={{ textAlign: 'center' }}></Column> */}

          {/*{
            localStorage.getItem('Role') == 1 &&
            <Column  field="BrandProductReferralPoints" header={t('ReferralPoints')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          } */}
          <Column field="BrandProductStartDate" header={t('tabel_header_contract_1')} body={date} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductEndDate" header={t('tabel_header_contract_2')} body={enddate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {
            localStorage.getItem('Role') != 2 ?
              <Column field="BrandProductStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column> :
              <Column field="BrandProductStatus" header={t('status')} body={statusBodyTemplate2} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          }
          {
            (role?.EDIT_BRAND_PRODUCTS == 1) &&

            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          }
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>
    </div>
  )
}

export default Products