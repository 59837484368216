import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdatePosition = () => {

    const urlUpdateData = `${process.env.REACT_APP_API_URL}/positions/edit`;
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()
    const toast = useRef(null);
    let navigate = useNavigate()
    let { t } = useTranslation()
    const [Specialvisit, setSpecialVisit] = useState(false);


    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState(false);
    const [referralNum, setSelectReferral] = useState(false);
    const [visitNum, setSelectVisit] = useState(false);
    const [chequeValueNum, setSelectChequeValue] = useState(false);
    const [giftsPoint, setGiftsPoint] = useState(null);
    const [gifts, setGifts] = useState(null);

    const items = [
        { label: <Link to={'/positions'}>{t('positions')}</Link> },
        { label: <Link to={`/positions/edit/${id}`} className='p-2 border-round'>{t('update_positions')}</Link> }
    ];
    const fetchData = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/positions/edit/page/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        if (data?.Success === true) {
            setData(data?.Response);
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    const home = { icon: 'pi pi-home', url: '/' };
    const formik = useFormik({
        initialValues: {
            IDPosition: id,
            PositionTitleEn: Data?.PositionTitleEn || '',
            PositionTitleAr: Data?.PositionTitleAr || '',
            PositionReferralNumber: '',
            PositionReferralInterval: '',
            PositionVisits: Data?.PositionVisits || '',
            PositionVisitInterval: Data?.PositionVisitInterval || '',
            PositionLeftPoints: Data?.PositionLeftPoints || '',
            PositionRightPoints: Data?.PositionRightPoints || '',
            PositionBalancePoints: Data?.PositionBalancePoints || '',
            PositionAllPoints: Data?.PositionAllPoints || '',
            PositionBalancePerson: Data?.PositionBalancePerson || '',
            PositionPointInterval: Data?.PositionPointInterval || '',
            PositionPointInterval: Data?.PositionPointInterval || '',
            PositionPointInterval: Data?.PositionPointInterval || '',
            PositionUniqueVisitsInterval: Data?.PositionPointInterval || '',

        },
        onSubmit: async (values, { resetForm }) => {

            const minutesInADay = 24 * 60;


            try {
                setLoading(true);
                let { data } = await axios.post(urlUpdateData,

                    {
                        IDPosition: id,
                        PositionTitleEn: values?.PositionTitleEn,
                        PositionTitleAr: values?.PositionTitleAr,
                        PositionReferralNumber: values?.PositionReferralNumber,
                        PositionReferralInterval: values?.PositionReferralInterval ? values?.PositionReferralInterval * minutesInADay : '',
                        PositionLeftNumber: values?.PositionLeftNumber,
                        PositionRightNumber: values?.PositionRightNumber,
                        PositionAllNumber: values?.PositionAllNumber,
                        PositionNumberInterval: values?.PositionNumberInterval ? values?.PositionNumberInterval * minutesInADay : 0,
                        PositionLeftPoints: values?.PositionLeftPoints,
                        PositionRightPoints: values?.PositionRightPoints,
                        PositionAllPoints: values?.PositionAllPoints,
                        PositionPointInterval: values?.PositionPointInterval ? values?.PositionPointInterval * minutesInADay : 0,
                        PositionVisits: values?.PositionVisits,
                        PositionVisitInterval: values?.PositionVisitInterval ? values?.PositionVisitInterval * minutesInADay : 0,
                        PositionChequeValue: values?.PositionChequeValue,
                        PositionBalancePerson: values?.PositionBalancePerson,
                        PositionBalancePoints: values?.PositionBalancePoints,
                        PositionChequeInterval: values?.PositionChequeInterval ? values?.PositionChequeInterval * minutesInADay : 0,

                        PositionUniqueVisitsInterval: Specialvisit === true ? (values?.PositionUniqueVisitsInterval ? values?.PositionUniqueVisitsInterval * minutesInADay : '') : '',
                        IsPositionUniqueVisits: Specialvisit === true ? 1 : 0

                    }
                    ,
                    {
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })

                if (data?.Success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/positions')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.log(response);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
                setLoading(false);

            }
        }
    });
    const [giftsValue, setGiftsValue] = useState(false);
    const [giftsValue2, setGiftsValue2] = useState(false);

    useEffect(() => {
        const minutesInADay = 24 * 60;

        formik.setValues({
            IDPosition: id,
            PositionTitleEn: Data?.PositionTitleEn || '',
            PositionTitleAr: Data?.PositionTitleAr || '',
            PositionReferralNumber: Data?.PositionReferralNumber || '',
            PositionReferralInterval: Data?.PositionReferralInterval || '',
            PositionLeftNumber: Data?.PositionLeftNumber || '',
            PositionRightNumber: Data?.PositionRightNumber || '',
            PositionAllNumber: Data?.PositionAllNumber || '',
            PositionNumberInterval: Data?.PositionNumberInterval || '',
            PositionVisits: Data?.PositionVisits || '',
            PositionVisitInterval: Data?.PositionVisitInterval || '',
            PositionLeftPoints: Data?.PositionLeftPoints || '',
            PositionRightPoints: Data?.PositionRightPoints || '',
            PositionAllPoints: Data?.PositionAllPoints || '',
            PositionPointInterval: Data?.PositionPointInterval || '',
            PositionChequeValue: Data?.PositionChequeValue || '',
            PositionChequeInterval: Data?.PositionChequeInterval || '',
            PositionUniqueVisitsInterval: Data?.PositionUniqueVisitsInterval || '',
            PositionBalancePerson: (Data?.PositionLeftNumber || Data?.PositionRightNumber) ? 'Balance' : 'Total',
            PositionBalancePoints: (Data?.PositionLeftPoints || Data?.PositionRightPoints) ? 'Balance' : 'Total',
        });
        if (Data?.PositionUniqueVisitsInterval > 0) {
            setSpecialVisit(true)
            formik.setFieldValue('PositionUniqueVisitsInterval', Data?.PositionUniqueVisitsInterval / minutesInADay)
        }

        if (Data?.PositionReferralInterval > 0) {
            setSelectReferral(true)
            formik.setFieldValue('PositionReferralInterval', Data?.PositionReferralInterval / minutesInADay)
        }
        if (Data?.PositionLeftNumber > 0 && Data?.PositionRightNumber > 0) {
            setGifts(1)
            formik.setFieldValue('PositionNumberInterval', Data?.PositionNumberInterval / minutesInADay)
            setGiftsValue(true)
        } else if (Data?.PositionAllNumber > 0) {
            setGifts(0)
            formik.setFieldValue('PositionNumberInterval', Data?.PositionNumberInterval / minutesInADay)
            setGiftsValue2(true)
        } else {
            setGifts(null)
        }
        if (Data?.PositionVisitInterval > 0 || Data?.PositionVisits > 0) {
            setSelectVisit(true)
            formik.setFieldValue('PositionVisitInterval', Data?.PositionVisitInterval / minutesInADay)
        }

        if (Data?.PositionLeftPoints > 0 && Data?.PositionRightPoints > 0) {
            setGiftsPoint(1)
            formik.setFieldValue('PositionPointInterval', Data?.PositionPointInterval / minutesInADay)

        } else if (Data?.PositionAllPoints > 0) {
            setGiftsPoint(0)
            formik.setFieldValue('PositionPointInterval', Data?.PositionPointInterval / minutesInADay)

        } else {
            setGiftsPoint(null)
        }


        if (Data?.PositionChequeValue > 0) {
            setSelectChequeValue(true)
            formik.setFieldValue('PositionChequeInterval', Data?.PositionChequeInterval / minutesInADay)
        }
    }, [Data, formik.setValues]);




    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="PositionTitleEn" className="font-bold block mb-2">{isLang === "en" ? 'Title in English' : 'العنوان باللغة الإنجليزية '}</label>
                        <InputText
                            name='PositionTitleEn'
                            id="PositionTitleEn"
                            type="text"
                            className="w-full"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PositionTitleEn}
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="PositionTitleAr" className="font-bold block mb-2">{isLang === "en" ? ' Title in arabic' : 'العنوان باللغة العربية '}</label>
                        <InputText
                            value={formik.values.PositionTitleAr}
                            name='PositionTitleAr'
                            id="PositionTitleAr" type="text" className="w-full" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>
                {/* //TODO Referral Number   */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => { setSelectReferral(e.checked) }} checked={referralNum}></Checkbox>
                            <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('ReferralNumber')}</label>
                        </div>
                    </div>

                    {
                        referralNum &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionReferralNumber'
                                    id="PositionReferralNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.PositionReferralNumber}
                                    placeholder={t('ReferralNumber')}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionReferralInterval'
                                    id="PositionReferralInterval"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values.PositionReferralInterval}
                                />
                            </div>
                        </>
                    }

                </div>
                {/* //TODO Position Unique Visit     */}
                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => setSpecialVisit(e.checked)} checked={Specialvisit}></Checkbox>
                            <label htmlFor="Specialvisit" className="ml-2 font-bold ">{isLang === "en" ? 'Unique visits  ' : 'زيارات فريدة'}</label>
                        </div>
                    </div>
                    {
                        Specialvisit &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionUniqueVisitsInterval'
                                    id="PositionUniqueVisitsInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values.PositionUniqueVisitsInterval}
                                />
                            </div>
                        </>
                    }
                </div>
                {/* //TODO Position Visits     */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => {
                                setSelectVisit(e.checked)
                            }} checked={visitNum}></Checkbox>
                            <label htmlFor="PositionVisits" className="ml-2 font-bold ">{t('visits')}</label>
                        </div>
                    </div>

                    {
                        visitNum &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionVisits'
                                    id="PositionVisits"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('visits')}
                                    value={formik.values.PositionVisits}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionVisitInterval'
                                    id="PositionVisitInterval"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values.PositionVisitInterval}


                                />
                            </div>
                        </>

                    }

                </div>
                {/* //TODO cheque Value     */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => {
                                setSelectChequeValue(e.checked)
                            }} checked={chequeValueNum}></Checkbox>
                            <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('ChequeValue')}</label>
                        </div>
                    </div>

                    {
                        chequeValueNum &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionChequeValue'
                                    id="PositionChequeValue"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('ChequeValue')}
                                    value={formik?.values?.PositionChequeValue}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionChequeInterval'
                                    id="PositionChequeInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik?.values?.PositionChequeInterval}

                                />
                            </div>
                        </>
                    }

                </div>
                {/* //TODO Balance Number     */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex flex-row gap-5">
                            <h4>{isLang === "en" ? 'Balance number : ' : 'Balance number : '}</h4>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="gifts1" name="BonanzaLeftPoints" value={1}
                                    onChange={(e) => {
                                        setGifts(e.value)
                                        formik.setFieldValue('PositionBalancePerson', 'Balance')

                                    }} checked={gifts === 1} />
                                <label htmlFor="gifts1" className="ml-2">{t('balancePerson')}</label>
                            </div>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="gifts2" name="BonanzaRightPoints" value={0}
                                    onChange={(e) => {
                                        setGifts(e.value)
                                        formik.setFieldValue('PositionBalancePerson', 'Total')

                                    }} checked={gifts === 0} />
                                <label htmlFor="gifts2" className="ml-2">{t('TotalNumber')}</label>
                            </div>
                        </div>
                    </div>
                    {
                        gifts === 1 &&
                        <>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                {/* <label htmlFor="PositionLeftNumber" className="  block  ">{t('ClientLeftNumber')}</label> */}
                                <InputText
                                    name='PositionLeftNumber'
                                    id="PositionLeftNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    placeholder={t('ClientLeftNumber')}
                                    value={formik.values.PositionLeftNumber}

                                />
                            </div>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                {/* <label htmlFor="PositionRightNumber" className="  block  ">{t('ClientRightNumber')}</label> */}
                                <InputText
                                    placeholder={t('ClientRightNumber')}
                                    name='PositionRightNumber'
                                    id="PositionRightNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    value={formik.values.PositionRightNumber}

                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}
                            <div className="lg:col-4 md:col-12 sm:col-12  mt-2 input__Col ">
                                <InputText
                                    name='PositionNumberInterval'
                                    id="PositionNumberInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values.PositionNumberInterval}

                                />
                            </div>
                        </>
                    }
                    {gifts === 0 &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12  input__Col ">
                                {/* {<label htmlFor="BonanzaChequeValue" className="  block mb-2 ">{t('TotalNumber')}</label>} */}
                                <InputText
                                    name='PositionAllNumber'
                                    id="PositionAllNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 0 && true}
                                    placeholder={t('TotalNumber')}
                                    value={formik.values.PositionAllNumber}
                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionNumberInterval'
                                    id="PositionNumberInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values.PositionNumberInterval}
                                />
                            </div>
                        </>
                    }
                </div>
                {/* //TODO Balance Points   */}
                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex flex-row gap-5">
                            <h4>{isLang === "en" ? 'Balance points : ' : 'نقاط الرصيد:'}</h4>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="giftsPoint1" name="BonanzaLeftPoints" value={1} onChange={(e) => {
                                    setGiftsPoint(e.value)
                                    formik.setFieldValue('PositionBalancePoints', 'Balance')

                                }} checked={giftsPoint === 1} />
                                <label htmlFor="giftsPoint1" className="ml-2">{t('balancePoints')}</label>
                            </div>


                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="giftsPoint2" name="BonanzaRightPoints" value={0}
                                    onChange={(e) => {
                                        setGiftsPoint(e.value)
                                        formik.setFieldValue('PositionBalancePoints', 'Total')

                                    }} checked={giftsPoint === 0} />
                                <label htmlFor="giftsPoint2" className="ml-2">{t('ClientTotalPoints')}</label>
                            </div>
                        </div>
                    </div>
                    {
                        giftsPoint === 1 &&
                        <>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                <InputText
                                    name='PositionLeftPoints'
                                    id="PositionLeftPoints"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    placeholder={t('ClientLeftPoints')}
                                    value={formik.values?.PositionLeftPoints}
                                />
                            </div>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                <InputText
                                    name='PositionRightPoints'
                                    id="PositionRightPoints"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    placeholder={t('ClientRightPoints')}
                                    value={formik.values?.PositionRightPoints}


                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}

                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2  input__Col ">
                                <InputText
                                    name='PositionPointInterval'
                                    id="PositionPointInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values?.PositionPointInterval}


                                />
                            </div>
                        </>
                    }


                    {giftsPoint === 0 &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12  input__Col ">
                                {/* {<label htmlFor="PositionAllPoints" className="  block mb-2 ">{t('ClientTotalPoints')}</label>} */}
                                <InputText
                                    name='PositionAllPoints'
                                    id="PositionAllPoints"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 0 && true}
                                    placeholder={t('ClientTotalPoints')}
                                    value={formik.values?.PositionAllPoints}

                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                {/* {<label htmlFor="PositionPointInterval" className="  block mb-2 ">{t('days')}</label>} */}
                                <InputText
                                    name='PositionPointInterval'
                                    id="PositionPointInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                    value={formik.values?.PositionPointInterval}


                                />
                            </div>
                        </>

                    }




                </div>
                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={'/positions'}>
                        <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
        </div>
    )
}


export default UpdatePosition
