import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import ScrollToBottom from 'react-scroll-to-bottom';
import './Chat.scss'
import { useTranslation } from 'react-i18next';
import { BreadCrumb } from 'primereact/breadcrumb';
import { format } from 'date-fns';
const ChatDetails = () => {
    let { isLang } = useContext(LocalizationContext);
    const audioRef = useRef(null);
    let { t } = useTranslation()

    let { id, idChat } = useParams()
    const [data, setData] = useState([])
    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/clients/chat/details`;
        let data = await axios.post(url, { IDClient: id, IDClientChat: idChat },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.ClientChatDetails);
     }
    useEffect(() => {
        fetchData();
    }, [isLang])
    const items = [
        { label: <Link to={`/client`} className='opacity-70'>{t('client_name')}</Link> },
        { label: <Link to={`/client/chat/list/${id}`} className='text-bluegray-900 opacity-70'>{t('FriendMessage')}</Link> },
        { label: <Link to={`/client/chat/client/${id}/details/${idChat}`} className='text-bluegray-900'>{t('Chat')}</Link> }
    ];
    const updated_at = (rowData) => {
        const formattedDate = format(new Date(rowData), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const home = { icon: 'pi pi-home', url: '/' };
    return (
        <div className="grid    overflow-hidden ">
            <div className="app__chat__Consult  mt-3  ">
                <div className='app__chat '>
                    <div className="grid justify-content-center  " style={{ marginTop: '0' }}>
                        <div className='  lg:col-11 md:col-12 sm:col-12 xs:col-12'>
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                    <div className="grid app__chat__container " style={{ marginTop: '0' }}>
                        <div className='app__chat_messages lg:col-11 md:col-12 sm:col-12 xs:col-12'>
                            <div className='shadow-2 app__chat_list-card  '>
                                <div dir='ltr' className={`app__Live_chat chat-body   ${data?.length === 0 ? 'bg-dark' : ''} `} style={{ background: 'rgb(217 217 217 / 28%)' }}>
                                    <ScrollToBottom dir='ltr' className="message-container">
                                        {
                                            data?.length === 0 ?
                                                <div className="empty_chat   w-full h-full d-flex justify-content-center align-items-center flex-column">
                                                    <div className="expired-container">
                                                        <Player
                                                            className="expired-image w-5" 
                                                            src="https://assets7.lottiefiles.com/packages/lf20_qwl4gi2d.json"
                                                            autoplay
                                                            loop
                                                        />
                                                    </div>
                                                    <h2 className={` ${data?.length === 0 ? 'text-light text-center' : ''}`}>
                                                        <span className='frinds__color'>{t('client_name')}</span> , <span className='admin__color'>{t('FriendMessage')}</span>
                                                    </h2>
                                                    <h4 className={` ${data?.length === 0 ? 'text-light text-center' : ' text-center'}`}>This Chat Is Empty.</h4>
                                                </div>
                                                :
                                                <>
                                                    {data?.map((messageContent, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`message `}
                                                                id={messageContent.Sender === "CLIENT" ? "other" : "you"}
                                                            >
                                                                <div>
                                                                    <div className={`message-content ${messageContent.Sender === "CLIENT" ? "bg-orange-100" : "bg-cyan-100"}`}  >
                                                                        {
                                                                            messageContent.MessageType === "TEXT" &&
                                                                            <p  >{messageContent.Message}</p>
                                                                        }
                                                                        {
                                                                            messageContent.MessageType === "IMAGE" &&
                                                                            <img loading="lazy" src={messageContent.Message} width="100%" className='rounded-3 w-50' />
                                                                        }
                                                                        {
                                                                            messageContent.MessageType === "AUDIO" &&
                                                                            <audio ref={audioRef} controls>
                                                                                <source src={messageContent.Message} type="audio/ogg" />
                                                                                <source src={messageContent.Message} type="audio/mpeg" />
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                        }
                                                                    </div>
                                                                    <div className="message-meta">
                                                                        <p id="time">{updated_at(messageContent?.updated_at) } {' , '} {messageContent.Sender?.charAt(0).toUpperCase() + messageContent?.Sender?.slice(1).toLowerCase()}</p>
                                                                        {/* ConsultSender */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                        }

                                    </ScrollToBottom>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatDetails
