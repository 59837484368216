

import { DeleteOutlined, FileImageOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import { Avatar, List, Modal, Upload, Button, Progress } from 'antd';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button as PrimeButton } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ToolFilesDisplay from './ToolFilesDisplay';

const UpdateTools = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const [uploadProgress, setUploadProgress] = useState(0);

  const urlUpdateData = `${process.env.REACT_APP_API_URL}/tools/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [openImanesFile, setOpenImanesFile] = useState(false);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (Tool) => setSelectMultiImage([...selectMultiImage, Tool.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  //! hande files 


  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/tools'}>{t('tools_name')}</Link> },
    { label: <Link to={`/tools/edit/${id}`} className='p-2 border-round'>{t('update_tools')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/tools/details`, { IDTool: id },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response?.Tool);
      setDocuments(data?.Response?.Tool?.ToolGallery);
      setFiles(data?.Response?.Tool?.ToolFiles);
    }
  }

  useEffect(() => {
    fetchData()
  }, [isLang]);

  //!hide vido= 
  const [openToolVideos, setOpenToolVideos] = useState(false);
  const [ToolVideos, setToolVideos] = useState([]);

  //!hide ToolVideos modal 
  const hideToolVideosProjectsDialog = (e) => setOpenToolVideos(false)

  // Handle scope change
  const handleScopeChange = (index, value) => {
    const updatedToolVideos = [...formik.values.ToolVideos];
    updatedToolVideos[index] = value;
    formik.setFieldValue('ToolVideos', updatedToolVideos);
  };


  const handleAddScope = () => {
    const currentToolVideos = formik.values.ToolVideos;

    // Ensure ToolVideos is an array
    const updatedToolVideos = Array.isArray(currentToolVideos)
      ? [...currentToolVideos, '']
      : [''];

    formik.setFieldValue('ToolVideos', updatedToolVideos);
    setToolVideos(updatedToolVideos);
  };

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      IDTool: id,
      IDArea: Data?.IDArea || '',
      ToolTitleEn: Data?.ToolTitleEn || '',
      ToolTitleAr: Data?.ToolTitleAr || '',
      ToolDescEn: Data?.ToolDescEn || '',
      ToolDescAr: Data?.ToolDescAr || '',
      ToolPrice: Data?.ToolPrice || '',
      ToolPoints: Data?.ToolPoints || '',
      ToolType: Data?.ToolType || '',
      ToolGallery: selectMultiImage,
      ToolVideos: ToolVideos,
    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(percentCompleted); // Update the progress state
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/tools')
            resetForm();
            setUploadProgress(0); // Reset progress after upload

          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/tools/gallery/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchData()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };


  useEffect(() => {
    formik.setValues({
      IDTool: id,
      ToolTitleEn: Data?.ToolTitleEn || '',
      ToolTitleAr: Data?.ToolTitleAr || '',
      ToolDescEn: Data?.ToolDescEn || '',
      ToolDescAr: Data?.ToolDescAr || '',
      ToolPrice: Data?.ToolPrice || '',
      ToolPoints: Data?.ToolPoints || '',
      ToolType: Data?.ToolType || '',
    });
  }, [Data, formik.setValues]);
  const handleChange = info => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
  };

  const handleRemove = file => {
    setFileList(fileList.filter(f => f.uid !== file.uid));
  };

  const renderItem = file => {
    let icon;
    if (file.type.startsWith('image/')) {
      icon = <Avatar src={URL.createObjectURL(file.originFileObj)} />;
    } else if (file.type === 'application/pdf') {
      icon = <FileOutlined style={{ fontSize: '32px', color: '#f5222d' }} />;
    } else {
      icon = <FileImageOutlined style={{ fontSize: '32px', color: '#f5222d' }} />;
    }

    return (
      <List.Item
        key={file.uid}
        actions={[
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(file)}
          />
        ]}
      >
        <List.Item.Meta
          avatar={icon}
          title={file.name}
          description={file.type}
        />
      </List.Item>
    );
  };

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="ToolTitleEn" className="font-bold block mb-2">{t('title_en')}</label>
            <InputText
              name='ToolTitleEn'
              id="ToolTitleEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ToolTitleEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="ToolTitleAr" className="font-bold block mb-2">{t('title_ar')}</label>
            <InputText
              value={formik.values.ToolTitleAr}
              name='ToolTitleAr'
              id="ToolTitleAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="ToolDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputText
              name='ToolDescEn'
              id="ToolDescEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ToolDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="ToolDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputText
              value={formik.values.ToolDescAr}
              name='ToolDescAr'
              id="ToolDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2  ">


          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='ToolPrice'
              id="ToolPrice"
              value={formik.values.ToolPrice}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolPoints" className="font-bold block mb-2">{t('ClientRewardPoints')}</label>
            <InputText
              name='ToolPoints'
              id="ToolPoints"
              value={formik.values.ToolPoints}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolType" className="font-bold block mb-2">{t('type')}</label>
            <InputText
              name='ToolType'
              id="ToolType"
              value={formik.values.ToolType}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2"> {t('gallery')}  </label>
            <div className="flex btn_gapAr gap-8">
              <PrimeButton size="small" label={t('gallery')} type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
              <PrimeButton size="small" label={isLang === "en" ? "Tool Videos  " : 'فيديوهات الأداة'} type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenToolVideos(!openImanes)} />
              <PrimeButton label={isLang === "en" ? " Tool Files" : 'الملفات التقنية '} size='small' type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanesFile(!openImanesFile)} />
            </div>
          </div>
        </div>


        {/* Progress Bar */}
        {uploadProgress > 0 && (
          <div className="mt-4">
            <Progress percent={uploadProgress} />
          </div>
        )}

        {/* Tool Files */}
        <Modal
          title={isLang === "en" ? "Tool Files" : "الملفات التقنية "}
          visible={openImanesFile}
          onCancel={() => setOpenImanesFile(false)}
          footer={[
            <Button key="back" onClick={() => setOpenImanesFile(false)}>
              {t('cancel')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setOpenImanesFile(false);
                formik.setFieldValue('ToolFiles', fileList.map(file => file.originFileObj));
              }}
              disabled={fileList?.length === 0}
              loading={loading}
            >
              {loading ? 'Uploading' : t('save')}
            </Button>,
          ]}

          className='APP_TOOLS'
        >
          <Upload
            onChange={handleChange}
            type='drag'
            beforeUpload={() => false}
            accept=" image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt,    .pptx,   .rar,   .zip,   video/mp4,    video/mkv, video/avi,  video/mov,  video/wmv,   audio/mp3,   audio/wav,   audio/mpeg,  audio/aac,   audio/ogg,   audio/flac,   audio/m4a,   .gif"
          >
            <Button type='text' icon={<UploadOutlined />}>{t('Select Files')}</Button>
          </Upload>


          <List
            itemLayout="horizontal"
            dataSource={fileList}
            renderItem={renderItem}
            style={{ marginTop: 16 }}
          />
          <ToolFilesDisplay toast={toast} fetchData={fetchData} toolFiles={files} />
        </Modal>


        {/* !Tool Gallery */}
        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? " Tool Gallery" : "معرض الأدوات"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <PrimeButton size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <PrimeButton size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(Tool) => {
                    formik.setFieldValue('ToolGallery', [...selectMultiImage, Tool.target.files[0]]);
                    handleMultiImageSelect(Tool);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <PrimeButton
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  {
                    item?.ToolGalleryType === "IMAGE" &&
                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                      <img
                        src={item.ToolGalleryPath}
                        alt={`Image ${index}`}
                        style={{ objectFit: 'cover' }}
                        className="w-full h-19rem border-round-xl "
                      />
                      <PrimeButton icon="pi pi-times" severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDToolGallery)} />
                    </div>
                  }

                  {
                    item?.ToolGalleryType === "VIDEO" &&
                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                      <iframe
                        width="400"
                        height="250"
                        className="w-full h-19rem border-round-xl"
                        src={item.ToolGalleryPath?.replace('watch?v=', 'embed/')}
                        title="Ghalia Benali/ YA MSAFER"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                      </iframe>
                      <PrimeButton icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDToolGallery)} />
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>


        </Dialog>
        {/*****Model Add Multi ToolVideos */}
        <Dialog visible={openToolVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hideToolVideosProjectsDialog}>
          {/* ToolVideos */}
          {ToolVideos?.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="ToolVideos" className="font-bold block mb-2">  {isLang === "en" ? "  Youtube Link" : 'رابط يوتيوب'}    </label>
                <InputText
                  name='ToolVideos'
                  id="ToolVideos"
                  type="text"
                  className="w-full"
                  defaultValue={scope.ar}
                  onChange={(Tool) => handleScopeChange(index, Tool.target.value)}
                />

              </div>
            </div>


          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <PrimeButton label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <PrimeButton label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hideToolVideosProjectsDialog} />
            </div>

          </div>
        </Dialog>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <PrimeButton raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/tools'}>
            <PrimeButton label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>

      </form>


    </div>
  )
}

export default UpdateTools
