import { PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, message } from 'antd';
import img from 'constants/Img';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./login.scss";

const DeleteAccount = () => {
    const [checked, setChecked] = useState(false);
    const [loademail, setLoadEmail] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [showForm, setShowForm] = useState(true); // New state to control form visibility

    let navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            UserName: '',
            phone: '',
            email: ''
        },
        onSubmit: async (values) => {
            setLoadEmail(true);
            setTimeout(() => {
                setShowForm(false); // Hide form
                message.success('Your account will be deleted after 10 days');
                setLoadEmail(false);
            }, 1000); // Simulate a delay for the loading state
        }
    });

    return (
        <>
            <div className="app__login  mt-8">
                <div className="app__login-left">
                    <header className='flex mb-4  justify-content-center  h-5rem  align-items-center gap-2'>
                        <img src={img.logoSm} style={{ width: '45px' }} alt="logo" />
                        <span className="ant-pro-form-login-title ">Better way</span>
                    </header>

                    {showForm ? (
                        <Form
                            name="normal_login"
                            className="login-form mt-2"
                            initialValues={{ remember: true }}
                            onFinish={formik.handleSubmit}
                        >
                            <Form.Item
                                name="UserName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name!'
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    type={'text'}
                                    prefix={activeTab === '1' ? <UserOutlined className="site-form-item-icon" /> : <PhoneOutlined />}
                                    placeholder={'Username'}
                                    id="UserName"
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (activeTab === '1') {
                                            formik.setFieldValue("UserName", inputValue);
                                        } else {
                                            formik.setFieldValue("UserName", '+2' + inputValue);
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your phone number!'
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    type={'number'}
                                    prefix={<PhoneOutlined />}
                                    placeholder={'Phone number'}
                                    id="phone"
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (activeTab === '1') {
                                            formik.setFieldValue("phone", inputValue);
                                        } else {
                                            formik.setFieldValue("phone", '+2' + inputValue);
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: activeTab === '1' ? 'Please enter your email!' : 'Please enter your phone number!'
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    type={activeTab === '1' ? "email" : 'number'}
                                    prefix={activeTab === '1' ? <UserOutlined className="site-form-item-icon" /> : <PhoneOutlined />}
                                    placeholder={activeTab === "1" ? 'Email' : 'Phone number'}
                                    id="email"
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (activeTab === '1') {
                                            formik.setFieldValue("email", inputValue);
                                        } else {
                                            formik.setFieldValue("email", '+2' + inputValue);
                                        }
                                    }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <div className="w-100">
                                    <Button type="primary" loading={loademail} htmlType="submit" className="login-form-button w-full">
                                        Delete Account
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    ) : (
                        <Alert
                            description=" Your account will be deleted after 10 days."
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default DeleteAccount;
