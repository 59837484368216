import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
const UpdateCategoryAccount = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const urlUpdateData = `${process.env.REACT_APP_API_URL}/categories/edit`;
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const items = [
        { label: <Link to={'/category_account'}>{t('categories_title')}</Link> },
        { label: <Link to={`/category_account/edit/${id}`} className='p-2 border-round'>{t('categories_edit')}</Link> }
    ];
    const fetchData = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/categories/edit/page/${id}`)
        if (data?.Success === true) {
            setData(data?.Response);
            
        }
    }
    useEffect(() => {
        fetchData()
    }, []);
    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
    const home = { icon: 'pi pi-home', url: '/' };
    const CategoryGroup = [
        { name: isLang === "en" ? 'Credit' : '', value: 'CREDIT' },
        { name: isLang === "en" ? 'Debit' : '', value: 'DEBIT' },
    ];

    const formik = useFormik({
        initialValues: {
            CategoryNameEn: Data?.CategoryNameEn || '',
            CategoryNameAr: Data?.CategoryNameAr || '',
            IDCategory: id || '',
            CategoryGroup: Data?.CategoryGroup || '', 
            CategoryType: "COMPANY"
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                let { data } = await axios.post(urlUpdateData, values,
                    {
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })

                if (data?.Success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/category_account')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    setLoading(false);

                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
                setLoading(false);

            }
        }
    });
 

    useEffect(() => {
        formik.setValues({
            CategoryNameEn: Data?.CategoryNameEn || '',
            CategoryNameAr: Data?.CategoryNameAr || '',
            IDCategory: Data?.IDCategory || '',
            CategoryGroup: Data?.CategoryGroup || '',
        });
        formik.setFieldValue('CategoryGroup', Data?.CategoryGroup );
    }, [Data, formik.setValues]);



    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
     
                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="CategoryNameEn" className="font-bold block mb-2"> {t('label_categories_nameEn')}    </label>
                        <InputText
                            name='CategoryNameEn'
                            id="CategoryNameEn"
                            type="text"
                            className="w-full"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CategoryNameEn}
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="CategoryNameAr" className="font-bold block mb-2">  {t('label_categories_nameAr')}</label>
                        <InputText
                            value={formik.values.CategoryNameAr}
                            name='CategoryNameAr'
                            id="CategoryNameAr" type="text" className="w-full" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="CategoryGroup" className="font-bold block mb-2">
                            {t('type')}
                        </label>
                        <Dropdown filter  
                            id="CategoryGroup"
                            name="CategoryGroup"
                            value={formik.values.CategoryGroup}
                            options={CategoryGroup}
                            optionLabel="name"
                            required
                            onChange={(e) => formik.setFieldValue("CategoryGroup", e.value)}
                            onBlur={formik.handleBlur}
                            placeholder={isLang === "en" ? 'Select a ledger type' : 'اختر النوع'}
                            className="w-full p-inputtext-sm"
                            disabled
                        />
                    </div>
                </div>



                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={'/category_account'}>
                        <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default UpdateCategoryAccount