import { Switch } from 'antd';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from "primereact/checkbox";
import { InputText } from 'primereact/inputtext';
import { RadioButton } from "primereact/radiobutton";
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
const AddBonanza = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/bonanza/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()

    const [poaition, setPosition] = useState(false);
    const [Persons, setPersons] = useState(false);
    const [selectTotalPoints, setSelectTotalPoints] = useState(false);
    const [selectTotalPersons, setSelectTotalPersons] = useState(false);
    const [selectVisitNum, setSelectVisit] = useState(false);
    const [referralNum, setSelectReferral] = useState(false);
    const [Specialvisit, setSpecialVisit] = useState(false);
    const [gifts, setGifts] = useState(1);

    const [checked, setChecked] = useState(true);

    const handleSwitchChange = (checked) => {
        setChecked(checked);
        if (checked === true) {
            setPosition(true)
        } else {
            setSelectTotalPoints(true)

        }
    };
    const [checkedPersons, setCheckedPersons] = useState(true);

    const handleSwitchChangePersons = (checked) => {
        setCheckedPersons(checked);
        if (checked === true) {
            setPersons(true)
        } else {
            setSelectTotalPersons(true)
        }
    };



    const items = [
        { label: <Link to={`/bonanza`}>{t('bonanza_name')}   </Link> },
        { label: <Link to={`/bonanza/add`} className='p-2 border-round'>  {t('add_bonanza')}  </Link> }
    ];

    const home = { icon: 'pi pi-home', url: '/' };
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

    const formik = useFormik({
        initialValues: {
            BonanzaTitleEn: '',
            BonanzaTitleAr: '',
            BonanzaLeftPoints: '',
            BonanzaRightPoints: '',
            BonanzaTotalPoints: '',

            BonanzaLeftPersons: '',
            BonanzaRightPersons: '',
            BonanzaTotalPersons: '',

            BonanzaVisitNumber: '',
            BonanzaReferralNumber: '',
            BonanzaRewardPoints: '',
            BonanzaChequeValue: '',
            BonanzaStartTime: '',
            BonanzaEndTime: '',
        },
        onSubmit: async (values, { resetForm }) => {
            // const formattedStartTime = values.BonanzaStartTime ? format(new Date(values.BonanzaStartTime.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm:ss") : '';
            // const formattedEndTime = values.BonanzaEndTime ? format(new Date(values.BonanzaEndTime.toLocaleString('en-US', options).replace(/,/, '')), "yyyy-MM-dd hh:mm:ss") : '';

            const formattedStartTime = values.BonanzaStartTime ? format(new Date(values.BonanzaStartTime.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';
            const formattedEndTime = values.BonanzaEndTime ? format(new Date(values.BonanzaEndTime.toLocaleString('en-US', options)), "yyyy-MM-dd HH:mm:ss") : '';
            
            try {
                setLoading(true);
                let { data } = await axios.post(url,
                    {
                        BonanzaTitleEn: values?.BonanzaTitleEn,
                        BonanzaTitleAr: values?.BonanzaTitleAr,
                        BonanzaLeftPoints: values?.BonanzaLeftPoints,
                        BonanzaRightPoints: values?.BonanzaRightPoints,
                        BonanzaTotalPoints: values?.BonanzaTotalPoints,

                        BonanzaLeftPersons: values?.BonanzaLeftPersons,
                        BonanzaRightPersons: values?.BonanzaRightPersons,
                        BonanzaTotalPersons: values?.BonanzaTotalPersons,

                        BonanzaPoints: checked === true ? 'Balance' : 'Total',
                        BonanzaPersons: checkedPersons === true ? 'Balance' : 'Total',
                        BonanzaVisitNumber: values?.BonanzaVisitNumber,
                        BonanzaReferralNumber: values?.BonanzaReferralNumber,
                        BonanzaRewardPoints: values?.BonanzaRewardPoints,
                        BonanzaChequeValue: values?.BonanzaChequeValue,
                        BonanzaStartTime: formattedStartTime,
                        BonanzaEndTime: formattedEndTime,
                        IsBonanzaUniqueVisits: Specialvisit === true ? 1 : 0,
                        BonanzaPrize: gifts == 1 ? 'Points' : 'Cheque'
                    },
                    {
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })
                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        if (Specialvisit === true) {
                            navigate(`/bonanza/add/brands/${data?.Response}`)
                        } else {
                            navigate(`/bonanza`)
                        }
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });
    useEffect(() => {
        if (checked === true) {
            setPersons(true)
        } else {
            setSelectTotalPersons(true)
        }

        if (checked === true) {
            setPosition(true)
        } else {
            setSelectTotalPoints(true)

        }
    }, [])




    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                <div className="grid ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                        <label htmlFor="BonanzaTitleEn" className="  block  ">{t('title_en')}</label>
                        <InputText
                            name='BonanzaTitleEn'
                            id="BonanzaTitleEn"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
                        <label htmlFor="BonanzaTitleAr" className="  block  ">{t('title_ar')}</label>
                        <InputText
                            name='BonanzaTitleAr'
                            id="BonanzaTitleAr"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                    </div>
                </div>
                <Switch
                    checkedChildren="Balance Point"
                    unCheckedChildren="Total Point"
                    checked={checked}
                    onChange={handleSwitchChange}
                />

                {
                    checked === true ?
                        <>
                            <div className="grid flex  mt-2 align-items-center">
                                <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                                    <div className="flex align-items-center gap-2">
                                        <Checkbox onChange={e => setPosition(e.checked)} checked={poaition}></Checkbox>
                                        <label htmlFor="selectTotalPoints1" className="ml-2 font-bold ">{isLang === "en" ? 'Balance points ' : 'اختر الموقع الذي يظهر فيه بونانزا'}</label>
                                    </div>
                                </div>
                                {
                                    poaition &&
                                    <>
                                        <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                            <label htmlFor="BonanzaLeftPoints" className="  block  ">{t('ClientLeftPoints')}</label>
                                            <InputText
                                                name='BonanzaLeftPoints'
                                                id="BonanzaLeftPoints"
                                                type='number'
                                                className="w-full  p-inputtext-sm"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className="lg:col-6 md:col-12 sm:col-12   input__Col" >
                                            <label htmlFor="BonanzaRightPoints" className="  block  ">{t('ClientRightPoints')}</label>
                                            <InputText
                                                name='BonanzaRightPoints'
                                                id="BonanzaRightPoints"
                                                type='number'
                                                className="w-full  p-inputtext-sm"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                        </div>
                                    </>
                                }

                            </div>

                        </> :
                        <div className="grid flex   align-items-center">
                            <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                                <div className="flex align-items-center gap-2">
                                    <Checkbox onChange={e => setSelectTotalPoints(e.checked)} checked={selectTotalPoints}></Checkbox>
                                    <label htmlFor="selectTotalPoints1" className="ml-2 font-bold ">{t('ClientTotalPoints')}</label>
                                </div>
                            </div>
                            {
                                selectTotalPoints &&
                                <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                    <label htmlFor="BonanzaTotalPoints" className="  block  "></label>
                                    <InputText
                                        name='BonanzaTotalPoints'
                                        id="BonanzaTotalPoints"
                                        type='number'
                                        className="w-full  p-inputtext-sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            }
                        </div>
                }
                <Switch
                    checkedChildren="Balance Persons"
                    unCheckedChildren="Total Persons"
                    checked={checkedPersons}
                    onChange={handleSwitchChangePersons}
                />
                {
                    checkedPersons === true ?
                        <>
                            <div className="grid flex  mt-2 align-items-center">
                                <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                                    <div className="flex align-items-center gap-2">
                                        <Checkbox onChange={e => setPersons(e.checked)} checked={Persons}></Checkbox>
                                        <label htmlFor="selectTotalPoints1" className="ml-2 font-bold ">{isLang === "en" ? 'Balance Persons ' : 'اختر الموقع الذي يظهر فيه بونانزا'}</label>
                                    </div>
                                </div>
                                {
                                    Persons &&
                                    <>
                                        <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                            <label htmlFor="BonanzaLeftPersons" className="  block  ">{t('ClientLeftPersons')}</label>
                                            <InputText
                                                name='BonanzaLeftPersons'
                                                id="BonanzaLeftPersons"
                                                type='number'
                                                className="w-full  p-inputtext-sm"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className="lg:col-6 md:col-12 sm:col-12   input__Col" >
                                            <label htmlFor="BonanzaRightPersons" className="  block  ">{t('ClientRightPersons')}</label>
                                            <InputText
                                                name='BonanzaRightPersons'
                                                id="BonanzaRightPersons"
                                                type='number'
                                                className="w-full  p-inputtext-sm"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                        </div>
                                    </>
                                }

                            </div>

                        </> :
                        <div className="grid flex   align-items-center">
                            <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                                <div className="flex align-items-center gap-2">
                                    <Checkbox onChange={e => setSelectTotalPersons(e.checked)} checked={selectTotalPersons}></Checkbox>
                                    <label htmlFor="selectTotalPersons1" className="ml-2 font-bold ">{t('ClientTotalPersons')}</label>
                                </div>
                            </div>
                            {
                                selectTotalPersons &&
                                <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                    <InputText
                                        name='BonanzaTotalPersons'
                                        id="BonanzaTotalPersons"
                                        type='number'
                                        className="w-full  p-inputtext-sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            }
                        </div>
                }
                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => setSelectVisit(e.checked)} checked={selectVisitNum}></Checkbox>
                            <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('totalvisits')}</label>
                        </div>
                    </div>
                    {
                        selectVisitNum &&
                        <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                            <label htmlFor="BonanzaVisitNumber" className="  block  "> </label>
                            <InputText
                                name='BonanzaVisitNumber'
                                id="BonanzaVisitNumber"
                                type='number'
                                className="w-full  p-inputtext-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    }
                </div>


                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => setSpecialVisit(e.checked)} checked={Specialvisit}></Checkbox>
                            <label htmlFor="Specialvisit" className="ml-2 font-bold ">{isLang === "en" ? 'Unique visits  ' : 'زيارات فريدة'}</label>
                        </div>
                    </div>
                </div>
                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => setSelectReferral(e.checked)} checked={referralNum}></Checkbox>
                            <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('ReferralNumber')}</label>
                        </div>
                    </div>
                    {
                        referralNum &&
                        <div className="lg:col-6 md:col-12 sm:col-12 m  input__Col ">
                            <InputText
                                name='BonanzaReferralNumber'
                                id="BonanzaReferralNumber"
                                type='number'
                                className="w-full  p-inputtext-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    }
                </div>

                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex flex-row gap-5">
                            <h4>{isLang === "en" ? 'Choose how to receive gifts?' : 'اختيار طريقة استلام الهدايا'}</h4>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="gifts1" name="BonanzaLeftPoints" value={1} onChange={(e) => setGifts(e.value)} checked={gifts === 1} />
                                <label htmlFor="gifts1" className="ml-2">{t('ClientRewardPoints')}</label>
                            </div>

                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="gifts2" name="BonanzaRightPoints" value={0} onChange={(e) => setGifts(e.value)} checked={gifts === 0} />
                                <label htmlFor="gifts2" className="ml-2">{t('ChequeValue')}</label>
                            </div>
                        </div>
                    </div>
                    {
                        gifts === 1 &&
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                            {/* <label htmlFor="BonanzaRewardPoints" className="  block  ">{t('ClientRewardPoints')}</label> */}
                            <InputText
                                name='BonanzaRewardPoints'
                                id="BonanzaRewardPoints"
                                type='number'
                                className="w-full  p-inputtext-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required={gifts === 1 && true}
                            />
                        </div>
                    }

                    {
                        gifts === 0 &&
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                            {/* <label htmlFor="BonanzaChequeValue" className="  block  ">{t('ChequeValue')}</label> */}
                            <InputText
                                name='BonanzaChequeValue'
                                id="BonanzaChequeValue"
                                type='number'
                                className="w-full  p-inputtext-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required={gifts === 0 && true}
                            />
                        </div>
                    }


                </div>
                <div className="grid flex mt-3  align-items-center">
                    <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
                        <label htmlFor="BonanzaStartTime" className="  block  "> {t('start_date')}</label>
                        <Calendar
                            id='BonanzaStartTime'
                            name="BonanzaStartTime"
                            value={formik.values.BonanzaStartTime}
                            onChange={formik.handleChange}
                            placeholder={isLang === "en" ? 'Bonanza start date' : 'تاريخ بداية بونانزا'}
                            showIcon
                            showTime
                            hourFormat="24"
                            className='w-full'
                            icon="pi pi-calendar-plus"
                            required
                        />
                    </div>
                    <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
                        <label htmlFor="BonanzaEndTime" className="  block  "> {t('end_date')}</label>
                        <Calendar
                            id='BonanzaEndTime'
                            name="BonanzaEndTime"
                            value={formik.values.BonanzaEndTime}
                            onChange={formik.handleChange}
                            placeholder={isLang === "en" ? 'Bonanza end date' : 'تاريخ نهاية بونانزا'}
                            showIcon
                            showTime
                            hourFormat="24"
                            className='w-full'
                            icon="pi pi-calendar-plus"
                            minDate={
                                formik.values.BonanzaStartTime
                                    ? new Date(formik.values.BonanzaStartTime.getTime())
                                    : new Date()
                            }
                            required
                        />


                    </div>
                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={`/bonanza`}>
                        <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
                    </Link>
                </div>




            </form >
        </div >
    )
}



export default AddBonanza