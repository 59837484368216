import {
    GoogleMap,
    Marker,
    useLoadScript
} from '@react-google-maps/api';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';

const libraries = ['places'];

const ShowGoogleMap = ({
    hideImagesProjectsDialog,
    openMap,
    isLang
}) => {
    const mapContainerStyle = {
        width: '100%',
        height: '500px',
        borderRadius: '8px'
    };
    let { userLocationMap, setLoctionMap } = useContext(LocalizationContext);


    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);
 
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAtUOb461InzoQoGEVKKVqqLf2NbwSjqdk',
        libraries,
    }); 

    const handleMarkerClick = marker => {
        setSelectedMarker(marker);
    };  
    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';

    return (
        <Dialog
            visible={openMap}
            style={{ width: '32rem' }}
            breakpoints={{ '1000px': '1000px', '1000px': '1000px' }}
            modal
            onHide={hideImagesProjectsDialog}
            header={isLang === "en" ? "Show Location" : "موقع"}
            dir={isLang === "en" ? 'ltr' : 'rtl'}
        >

            <>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={18}
                    center={ userLocationMap}  
                >


                    {userLocationMap && ( // Add marker for current location
                        <Marker position={userLocationMap} />
                    )}

                    {markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            onClick={() => handleMarkerClick(marker)}
                        />
                    ))}

                </GoogleMap> 
            </>
        </Dialog>

    );
};


export default ShowGoogleMap