import { useTranslation } from "react-i18next"

const SummaryClient = ({ data }) => {
    const { t } = useTranslation()
    return (
        <>

            <div className="grid  p-2 ">

                {
                    data?.SalesName &&
                    <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
                        <div className="client_summary Sales_summary1   border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                            <div className="icons">
                                <i className='pi pi-users'></i>
                            </div>
                            <div className="content flex flex-column">
                                <span className='number'>{t('tabel_brand_header_4')}</span>
                                <span className='header'>{data?.SalesName}</span>
                            </div>
                        </div>
                    </div>
                }
                {
                    data?.SalesPhone &&
                    <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
                        <div className=" client_summary Sales_summary2   border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                            <div className="icons">
                                <i className='pi pi-phone'></i>
                            </div>
                            <div className="content flex flex-column">
                                <span className='number'>{t('tabel_brand_header_5')}</span>
                                <span className='header'>{data?.SalesPhone}</span>
                            </div>
                        </div>
                    </div>
                }


            </div>
        </>
    )
}

export default SummaryClient
