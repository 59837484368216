
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


export const Accounts = () => {
    let { role } = useContext(FetchApi)
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation() 
    const dt = useRef(null); 
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null); 

    // logcation path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async (SearchKey) => {
        let url = ''
        if (location.pathname === "/better-way-depit") {
            url = `${process.env.REACT_APP_API_URL}/betterway/debit`;
        } else {
            url = `${process.env.REACT_APP_API_URL}/betterway/credit`;
        }
        let data = await axios.post(url, {   },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);

    }
    useEffect(() => {
        fetchData();
    }, [isLang, location.pathname])

    const formatDate = (value) => {
        try {
            return format(new Date(value), 'MM/dd/yyyy'); // Adjust date format as needed
        } catch (error) {
            console.error('Date formatting error:', error);
            return 'Invalid Date';
        }
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.Date);
    };

    return (
        <div className='mb-8 '>
            <div className="card">
                <DataTable className="custom-datatable"
                    scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"

                    globalFilter={globalFilter}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    header={header}>

                    <Column sortable field="ClientLedgerBatchNumber" header={t('BatchNumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="Date" header="Date" sortable filterField="Date" dataType="Date" style={{ minWidth: '12rem' }} body={dateBodyTemplate} />
                    <Column sortable field="ClientLedgerAmount" header={t('amount')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerPoints" header={t('points')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerSource" header={t('Source')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerDestination" header={t('ledger_Destination')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerInitialeBalance" header={t('ledger_Initiale_Balance')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerFinalBalance" header={t('ledger_Final_Balance')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerInitialePoints" header={t('ledger_Initiale_Points')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerFinalPoints" header={t('ledger_final_Points')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column sortable field="ClientLedgerType" header={t('ledger_Ledger_Type')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                </DataTable>

            </div>

        </div>
    );
}
