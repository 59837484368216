import axios from 'axios';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FetchApi } from 'context/FetchApi';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from 'context/LangChange';


const AddCountries = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/location/countries/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/countries'}>  {t('countries_title')} </Link> },
    { label: <Link to={'/countries/add'} className='p-2 border-round'> {t('add_countries')} </Link> }
  ];
  const statusList = [
    { name: t('active'), value: '1' },
    { name: t('inactive'), value: '0' },
  ];
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      CountryNameEn: '',
      CountryNameAr: '',
      CountryActive: '',
      CountryTimeZone: '+02',
      CountryCode: 'Eg',
    },
    onSubmit: async (values, { resetForm }) => {
      
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })
        
        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/countries')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });
  return (

    <div className='mb-8'>     
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="CountryNameEn" className="font-bold block mb-2"> {t('label_CountryNameEn')}   </label>
            <InputText name='CountryNameEn' id="CountryNameEn" type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="CountryNameAr" className="font-bold block mb-2">   {t('label_CountryNameAr')}  </label>
            <InputText name='CountryNameAr' id="CountryNameAr" type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-3  ">
          <div className="lg:col-6 md:col-12 sm:col-12      ">
            <label htmlFor="CountryActive" className="font-bold block mb-2">    {t('label_CountryActive')}   </label>
            <Dropdown filter  
              options={statusList}
              id="CountryActive"
              name="CountryActive"
              optionLabel="name"
              optionValue="value"
              value={formik.values.CountryActive} // Add this line 
              onChange={(e) => formik.setFieldValue("CountryActive", e.value)}
              onBlur={formik.handleBlur}
              placeholder={t('status_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} size='small' className='mt-3' />
          </div>
          <Link to={'/countries'}>
            <Button severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form >
    </div >
  )
}

export default AddCountries