
import ShowGoogleMap from 'GoogleMap/ShowGoogleMap';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const BrandContactUs = () => {
    let { isLang, setLoctionMap } = useContext(LocalizationContext);
    
    let { t } = useTranslation()
    const dt = useRef(null);
    let { role } = useContext(FetchApi)

    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/brands/contactus`;

        let data = await axios.post(url, { IDPage: page + 1 },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.BrandContactUs);
        setPagesNum(data?.data?.Response?.Pages)

    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])

    const [openMap, setOpenMap] = useState(false);
    const hideImagesProjectsDialog = (data) => {
        setOpenMap(!openMap)
        const newMarker = { lat: data?.Latitude, lng: data?.Longitude };
        setLoctionMap(newMarker);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
              
                <Link className='location_branch' onClick={() => hideImagesProjectsDialog(rowData)}>
                    <Button icon='pi pi-map-marker' text raised tooltip={t('tooltip_location')} tooltipOptions={{ position: t('tooltip') }} severity='success' rounded outlined className="mr-2" />
                </Link>
               

            </div>
        );
    };
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('READ'), value: 'READ' },
    ];
    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/contactus/status/${rowData?.IDBrandContactUs}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.Status === 'READ' ? t('READ') : t('pending');
        const placeholderText = rowData?.Status === 'READ' ? t('READ') : t('pending');

        return editingRow === rowData?.IDBrandContactUs ? (

            <Dropdown filter
                value={editingStatus}
                options={statusOptions}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm "
            />


        ) : (
            <>
                <Tag
                    severity={getSeverity(rowData.Status)}
                    onClick={() => {
                        if (rowData?.Status !== 'READ') {
                            if (role?.EDIT_BRAND_REQUESTS == 1) {
                                setEditingRow(rowData?.IDBrandContactUs)
                            }
                        }
                    }}
                    rounded
                >
                    <div className='flex justify-content-center align-items-center gap-2'>
                        <span>{editingStatus}   </span>
                        {
                            (role?.EDIT_BRAND_REQUESTS == 1 && rowData?.Status !== 'READ') &&
                            <i className='pi pi-angle-down'></i>
                        }
                    </div>
                </Tag>
            </>

        );
    };



    const getSeverity = (status) => {
        switch (status) {
            case 'READ':
                return 'success';
            case 'PENDING':
                return 'warning';
            case '':
                return 'info';
        }
    };
    const mainAddress = (item) => { 
        return <span>{item?.City + ' , ' + item?.Area + ' , ' + item?.Address}</span>
    }
    return (
        <div  >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="BrandName" header={t('brand_name')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column field="Category" header={t('Category')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column field="ClientName" header={isLang === "en" ? 'Client name' : 'اسم العميل'} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="Phone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="Email" header={t('email')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="Address" header={t('addres')} body={mainAddress} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="Status" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="UserName" header={t('User')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} header={t('location')}  exportable={false} style={{ minWidth: '12rem' }}></Column>
              
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

            <ShowGoogleMap
                openMap={openMap}
                hideImagesProjectsDialog={hideImagesProjectsDialog}
                isLang={isLang}
            />
        </div>
    )
}


export default BrandContactUs