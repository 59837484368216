import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

const ClientPosition = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);

    let location = useLocation()

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            {
                location.pathname === `/client/details/${id}` &&
                <h4 className="m-0">{t('positions')}</h4>
            }
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );
    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/clients/position/log`;
        let data = await axios.post(url, { IDClient: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }

    useEffect(() => {
        fetchData();
    }, [isLang])

    return (
        <div >

            <div className="card">
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="Position" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="Date" header={t('Date')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                </DataTable>


            </div>

        </div>
    )
}

export default ClientPosition