import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { FetchApi } from 'context/FetchApi';

const BrandSocialMedia = ({ modalDone, setModalDone, dataSocialMedia }) => {
    const dt = useRef(null);
    const toast = useRef(null);
    const inputRef = useRef(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [IdDeleteusers, setIdDeleteusers] = useState(null);
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);

    let { role } = useContext(FetchApi)

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [Data, setData] = useState(null);
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 1 },
        { label: t('inactive'), value: 0 },
    ];
    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/brands/socialmedia`;
        let data = await axios.post(url, { IDBrand: dataSocialMedia?.IDBrand },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })

        setData(data?.data?.Response);
    }
    useEffect(() => {
        if (modalDone === true) {
            fetchData();
        }
    }, [isLang, dataSocialMedia?.IDBrand])

    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <Image
                preview
                src={rowData.SocialMediaIcon}
                alt={'logo'}
                width='100px'
                height='100px'
                className=' mx-auto overflow-hidden shadow-3  border-round'
                style={{ objectFit: 'cover' }}

            />
            {/* <img src={rowData?.SocialMediaIcon} alt={'SocialMediaIcon'} className="shadow-2 " style={{ width: '100px', height: '100px', borderRadius: '10px', objectFit: 'contain' }} /> */}
        </div>
    };


    // handel image in tabel
    const nameBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <span>{rowData?.SocialMediaName}</span>
        </div>
    };

    const onStatusChange = async (rowData, e) => {
        
        setEditingRow(null);
        if (e.value === 0) {
            await axios.post(`${process.env.REACT_APP_API_URL}/brands/socialmedia/status`, {
                IDBrand: dataSocialMedia?.IDBrand,
                IDSocialMedia: rowData?.IDSocialMedia
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            }).then(({ data }) => {

                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                    fetchData()
                }
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

            })
        } else {
            confirmDeleteusers(rowData)
        }
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.BrandSocialMediaLinked === 1 ? t('active') : t('inactive');
        const placeholderText = rowData?.BrandSocialMediaLinked !== 1 ? t('inactive') : t('active');

        return editingRow === rowData?.IDSocialMedia ? (
            <Dropdown filter
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.BrandSocialMediaLinked)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm  "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.BrandSocialMediaLinked)}
                onClick={() => {
                    if (role?.EDIT_BRANDS == 1) {
                        setEditingRow(rowData?.IDSocialMedia)
                    }
                }}

                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>{editingStatus}</span>
                    {
                        role?.EDIT_BRANDS == 1 &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };
    const getSeverity = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case '':
                return 'info';
        }
    };

    const confirmDeleteusers = async (users) => {
        setDeleteusersDialog(true);
        setIdDeleteusers(users?.IDSocialMedia)
    };
    const deleteusers = async () => {
        setdeleteLoadind(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/brands/socialmedia/status`, {
            IDBrand: dataSocialMedia?.IDBrand,
            IDSocialMedia: IdDeleteusers,
            BrandSocialMediaLink: inputRef.current.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setTimeout(() => {
                    setDeleteusersDialog(false);
                    setdeleteLoadind(false)
                }, 1000);
            } else {
                setdeleteLoadind(false)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const deleteusersDialogFooter = (rowData) => {
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
            <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" onClick={() => deleteusers(rowData)} />
        </div>
    }
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalDone}
                style={{ width: '50rem' }}
                modal
                onHide={setModalDone}
                header={isLang === "en" ? "Social media" : "مواقع التواصل الاجتماعي"}
                dir={isLang === "en" ? 'ltr' : 'rtl'}
            >
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    paginator
                    rows={10} >
                    <Column field="SocialMediaIcon" header={t('logo')} body={imageBodyTemplate}></Column>
                    <Column field="SocialMediaName " body={nameBodyTemplate} header={t('SocialMedia_contacts')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                    <Column field="BrandSocialMediaLinked" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>

                </DataTable>
            </Dialog>


            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Add Social Media' : '    إضافة موقع تواصل'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>

                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="BrandNameAr" className="font-bold block mb-2">{isLang === "en" ? 'Link Social Media' : 'ربط وسائل التواصل الاجتماعي'}</label>
                        <InputText
                            name='BrandNameAr'
                            id="BrandNameAr"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            ref={inputRef}
                        />
                        <div className="flex justify-content-center align-items-center mt-4 gap-3">
                            <Button loading={deleteLoadind} label={t('submit')} size='small' raised icon="pi pi-check" severity="warning" onClick={() => deleteusers()} />
                            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' onClick={() => setDeleteusersDialog(false)} />
                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    )
}

export default BrandSocialMedia