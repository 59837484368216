import UpdateGoogleMap from 'GoogleMap/UpdateGoogleMap';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdatePlanProducts = () => {
  let { id } = useParams()
  let { plansProductsAjex, fetchplansProductsAjex } = useContext(FetchApi)
  let { isLang, userLocationMap, setLoctionMap, setMarkers } = useContext(LocalizationContext);
  const extractCoordinates = (e) => {
    setLoctionMap(null)
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = e.match(regex);

    if (match) {
      setLoctionMap(null)
      const newMarker = { lat: Number(match[1]), lng: Number(match[2]) }; 
      setMarkers([newMarker]);
      setLoctionMap(newMarker)

    } else {
      console.error("Invalid Google Maps URL");
    }
  };
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/plans/products/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/plan-products'}>{t('plan_product')}</Link> },
    { label: <Link to={`/plan-products/edit/${id}`} className='p-2 border-round'>{t('update_plan_product')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/plans/products/edit/page/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response);
      setDocuments(data?.Response?.PlanProductGallery);

    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  //!hide vido= 
  const [openPlanProductVideos, setOpenPlanProductVideos] = useState(false);
  const [PlanProductVideos, setPlanProductVideos] = useState([]);

  //!hide PlanProductVideos modal 
  const hidePlanProductVideosProjectsDialog = (e) => setOpenPlanProductVideos(false)

  // Handle scope change
  const handleScopeChange = (index, value) => {
    const updatedPlanProductVideos = [...formik.values.PlanProductVideos];
    updatedPlanProductVideos[index] = value;
    formik.setFieldValue('PlanProductVideos', updatedPlanProductVideos);
  };


  const handleAddScope = () => {
    const currentPlanProductVideos = formik.values.PlanProductVideos;

    // Ensure PlanProductVideos is an array
    const updatedPlanProductVideos = Array.isArray(currentPlanProductVideos)
      ? [...currentPlanProductVideos, '']
      : [''];

    formik.setFieldValue('PlanProductVideos', updatedPlanProductVideos);
    setPlanProductVideos(updatedPlanProductVideos);
  };

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      IDPlanProduct: id,
      IDPlan: Data?.IDPlan || '',
      PlanProductNameEn: Data?.PlanProductNameEn || '',
      PlanProductNameAr: Data?.PlanProductNameAr || '',
      PlanProductDescEn: Data?.PlanProductDescEn || '',
      PlanProductDescAr: Data?.PlanProductDescAr || '',
      PlanProductRewardPoints: Data?.PlanProductRewardPoints || '',
      PlanProductPrice: Data?.PlanProductPrice || '',
      PlanProductPoints: Data?.PlanProductPoints || '',
      // PlanProductRewardPoints: Data?.PlanProductRewardPoints || '',
      // PlanProductUplinePoints: Data?.PlanProductUplinePoints || '',
      CardNumber: Data?.CardNumber || '',
      PlanProductPhone: Data?.PlanProductPhone || '',
      PlanProductAddressEn: Data?.PlanProductAddressEn || '',
      PlanProductAddressAr: Data?.PlanProductAddressAr || '',
    },
    onSubmit: async (values, { resetForm }) => {
      
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, {
          IDPlanProduct: id,
          IDPlan: values?.IDPlan || '',
          PlanProductNameEn: values?.PlanProductNameEn || '',
          PlanProductNameAr: values?.PlanProductNameAr || '',
          PlanProductDescEn: values?.PlanProductDescEn || '',
          PlanProductDescAr: values?.PlanProductDescAr || '',
          PlanProductRewardPoints: values?.PlanProductRewardPoints || '',
          PlanProductPrice: values?.PlanProductPrice || '',
          PlanProductPoints: values?.PlanProductPoints || '',
          // PlanProductRewardPoints: values?.PlanProductRewardPoints || '',
          // PlanProductUplinePoints: values?.PlanProductUplinePoints || '',
          CardNumber: values?.CardNumber || '',
          PlanProductGallery: selectMultiImage,
          PlanProductVideos: PlanProductVideos,
          PlanProductLatitude: userLocationMap?.lat,
          PlanProductLongitude: userLocationMap?.lng,
          PlanProductPhone: values?.PlanProductPhone || '',
          PlanProductAddressEn: values?.PlanProductAddressEn || '',
          PlanProductAddressAr: values?.PlanProductAddressAr || '',
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/plan-products')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/plans/products/gallery/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchData()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };
  const [openMap, setOpenMap] = useState(false);
  const hideMapDialog = (data) => {
    setOpenMap(!openMap)
  }


  useEffect(() => {
    setLoctionMap(null)
    formik.setValues({
      IDPlanProduct: id,
      PlanProductNameEn: Data?.PlanProductNameEn || '',
      PlanProductNameAr: Data?.PlanProductNameAr || '',
      IDPlan: Data?.IDPlan || '',
      PlanProductDescEn: Data?.PlanProductDescEn || '',
      PlanProductDescAr: Data?.PlanProductDescAr || '',
      PlanProductRewardPoints: Data?.PlanProductRewardPoints || 0,
      PlanProductPrice: Data?.PlanProductPrice || '',
      PlanProductPoints: Data?.PlanProductPoints || '',
      // PlanProductRewardPoints: Data?.PlanProductRewardPoints || '',
      // PlanProductUplinePoints: Data?.PlanProductUplinePoints || '',
      CardNumber: Data?.CardNumber || '',
      PlanProductPhone: Data?.PlanProductPhone || '',
      PlanProductAddressEn: Data?.PlanProductAddressEn || '',
      PlanProductAddressAr: Data?.PlanProductAddressAr || '',
    });
    const newMarker = { lat: Data?.PlanProductLatitude, lng: Data?.PlanProductLongitude };
    setLoctionMap(newMarker)
  }, [Data, formik.setValues]);



  useEffect(() => {

    fetchplansProductsAjex()
    return () => {
      fetchplansProductsAjex()
    }
  }, [isLang])


  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PlanProductNameEn" className="font-bold block mb-2">{t('label_Plan_name_en')}</label>
            <InputText
              name='PlanProductNameEn'
              id="PlanProductNameEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.PlanProductNameEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PlanProductNameAr" className="font-bold block mb-2">{t('label_Plan_name_ar')}</label>
            <InputText
              value={formik.values.PlanProductNameAr}
              name='PlanProductNameAr'
              id="PlanProductNameAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PlanProductDescEn" className="font-bold block mb-2">{t('label_Plan_desc_en')}</label>
            <InputText
              name='PlanProductDescEn'
              id="PlanProductDescEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.PlanProductDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PlanProductDescAr" className="font-bold block mb-2">{t('label_Plan_desc_ar')}</label>
            <InputText
              value={formik.values.PlanProductDescAr}
              name='PlanProductDescAr'
              id="PlanProductDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='PlanProductPrice'
              id="PlanProductPrice"
              value={formik.values.PlanProductPrice}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductPoints" className="font-bold block mb-2">{t('points')}</label>
            <InputText
              name='PlanProductPoints'
              id="PlanProductPoints"
              value={formik.values.PlanProductPoints}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="CardNumber" className="font-bold block mb-2">{t('card')}</label>
            <InputText
              name='CardNumber'
              id="CardNumber"
              value={formik.values.CardNumber}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3">
            <label htmlFor="IDPlan" className="font-bold block mb-2">{isLang === "en" ? " Plan " : 'خطة'}</label>
            <Dropdown filter
              options={plansProductsAjex?.map(item => ({
                name: item.PlanName,
                value: item.IDPlan,
              }))}
              id="IDPlan"
              name="IDPlan"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDPlan} // Add this line 
              onChange={(e) => formik.setFieldValue("IDPlan", e.value)}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a Plan' : 'حدد خطة'}
              className="w-full p-inputtext-sm"
            />
          </div>
          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductRewardPoints" className="font-bold block mb-2">{t('ReferralPoints')}</label>
            <InputText
              name='PlanProductRewardPoints'
              id="PlanProductRewardPoints"
              value={formik.values.PlanProductRewardPoints}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductUplinePoints" className="font-bold block mb-2">{t('UplinePoints')}</label>
            <InputText
              name='PlanProductUplinePoints'
              id="PlanProductUplinePoints"
              value={formik.values.PlanProductUplinePoints}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div> */}
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductRewardPoints" className="font-bold block mb-2">{isLang === "en" ? 'Reward points' : 'نقاط مكافأة'}</label>
            <InputText
              name='PlanProductRewardPoints'
              id="PlanProductRewardPoints"
              value={formik.values.PlanProductRewardPoints}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductPhone" className="font-bold block mb-2">{t('phone')}</label>
     
            <InputNumber
              value={formik.values.PlanProductPhone}
              name="PlanProductPhone"
              id="PlanProductPhone"
              className="w-full"
              inputClassName="p-inputtext-sm"
              onBlur={formik.handleBlur}
              onValueChange={formik.handleChange}
              mode="decimal"
              useGrouping={false}
              dir="ltr"
              pattern="\d*"
              maxLength={12}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductAddressEn" className="font-bold block mb-2">{isLang === "en" ? ' Address in english' : 'العنوان بالانجليزية'}</label>
            <InputTextarea
              name='PlanProductAddressEn'
              id="PlanProductAddressEn"
              type='text'
              value={formik.values.PlanProductAddressEn}
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductAddressAr" className="font-bold block mb-2">{isLang === "en" ? ' Address in arabic' : 'العنوان بالعربية'}</label>
            <InputTextarea
              name='PlanProductAddressAr'
              id="PlanProductAddressAr"
              type='text'
              value={formik.values.PlanProductAddressAr}
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>




          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2"> {t('gallery')}  </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} type='button' size='small' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
              <Button label={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية'} size='small' type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenPlanProductVideos(!openImanes)} />

            </div>
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BranchPhone" className="font-bold block mb-2">{t('tooltip_location')}</label>
            <div className="flex flex-row gap-2">
              <Link className='location_branch  ' onClick={() => hideMapDialog()}>
                <Button label={t('location')} size='small' icon='pi pi-map-marker' tooltip={t('tooltip_location')} tooltipOptions={{ position: t('tooltip') }} severity='success' outlined className="mr-2" />
              </Link>

              <InputText
                type='text'
                className="w-full p-inputtext-sm"
                onChange={(e) => extractCoordinates(e.target.value)}
                placeholder={isLang === "en" ? "Paste Google Maps URL here" : 'الصق عنوان URL الخاص بخرائط جوجل هنا'}
              />
            </div>
          </div>

        </div>


        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Multi Images" : "صور متعددة"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('PlanProductGallery', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  {
                    item?.PlanProductGalleryType === "IMAGE" &&
                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">

                      <Image
                        preview
                        loading="lazy"
                        src={item.PlanProductGalleryPath}
                        alt={'Image'}
                        width='300px'
                        height='100%'
                        className=' mx-auto overflow-hidden '
                        style={{ objectFit: 'cover' }}
                      />
                      <Button icon="pi pi-times" severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDPlanProductGallery)} />

                    </div>
                  }

                  {
                    item?.PlanProductGalleryType === "VIDEO" &&
                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                      <iframe width={400} height={250} className="border-round  " src={`https://${item.PlanProductGalleryPath}`} title="Ghalia Benali/ YA MSAFER / غالية بنعلي/ يا مسافر وحدك" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDPlanProductGallery)} />
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>


        </Dialog>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/plan-products'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>


        {/*****Model Add Multi PlanProductVideos */}
        <Dialog visible={openPlanProductVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hidePlanProductVideosProjectsDialog}>

          {/* PlanProductVideos */}
          {PlanProductVideos?.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="PlanProductVideos" className="font-bold block mb-2">  {isLang === "en" ? "  Youtube Link" : 'رابط يوتيوب'}    </label>
                <InputText
                  name='PlanProductVideos'
                  id="PlanProductVideos"
                  type="text"
                  className="w-full"
                  defaultValue={scope.ar}
                  onChange={(event) => handleScopeChange(index, event.target.value)}
                />

              </div>
            </div>


          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hidePlanProductVideosProjectsDialog} />
            </div>

          </div>

        </Dialog>
        <UpdateGoogleMap
          openMap={openMap}
          hideMapDialog={hideMapDialog}
          isLang={isLang}
        />
      </form>


    </div>
  )
}


export default UpdatePlanProducts
