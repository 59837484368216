import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ProfileBrand = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/brands'}>{t('brand_name')}</Link> },
    { label: <Link to={`/brands/edit/${id}`} className='p-2 border-round'>{t('brand_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/edit/page/${id}`)
    if (data?.Success === true) {
      setData(data?.Response);
      fetchDocuments()
    }
  }

  const fetchDocuments = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/documents/${id}`)
    if (data?.Success === true) {
      setDocuments(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      BrandNameEn: Data?.BrandNameEn || '',
      BrandNameAr: Data?.BrandNameAr || '',
      BrandNumber: Data?.BrandNumber || '',
      BrandEmail: Data?.BrandEmail || '',
      SalesName: Data?.SalesName || '',
      SalesPhone: Data?.SalesPhone || '',
      BrandPolicyAr: Data?.BrandPolicyAr || '',
      BrandPolicyEn: Data?.BrandPolicyEn || '',
      BrandDescEn: Data?.BrandDescEn || '',
      BrandDescAr: Data?.BrandDescAr || '',
      IDBrand: id,
      BrandLogo: null,
      BrandDocuments: selectMultiImage,


    },
    onSubmit: async (values, { resetForm }) => {
      
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/brands')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/document/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {
        
        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchDocuments()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };
  useEffect(() => {
    formik.setFieldValue('BrandLogo', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setValues({
      BrandNameEn: Data?.BrandNameEn || '',
      BrandNameAr: Data?.BrandNameAr || '',
      BrandNumber: Data?.BrandNumber || '',
      BrandEmail: Data?.BrandEmail || '',
      IDBrand: id,
      SalesName: Data?.SalesName || '',
      SalesPhone: Data?.SalesPhone || '',
      BrandPolicyAr: Data?.BrandPolicyAr || '',
      BrandPolicyEn: Data?.BrandPolicyEn || '',
      BrandDescEn: Data?.BrandDescEn || '',
      BrandDescAr: Data?.BrandDescAr || '',

    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      {/*      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
  
        <BreadCrumb model={items} home={home} /> */}
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="mt-3  flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
          {formik.values.BrandLogo ? (
            <img
              loading="lazy"
              src={URL.createObjectURL(formik.values.BrandLogo)}
              alt={'  notfound'}              className='  mx-auto  '
              style={{ border: '6px solid #F1592A', objectFit: 'cover', width: '200px', height: '200px', borderRadius: '50%' }}

            />
          ) :
            <>
              <img
                loading="lazy"
                src={Data.BrandLogo}
                alt={'default-image'}
                className='   mx-auto  '
                style={{ border: '6px solid #F1592A', objectFit: 'cover', width: '200px', height: '200px', borderRadius: '50%' }}

              />
            </>
          }

        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNameEn" className="font-bold block mb-2"> {t('label_brand_name_en')}    </label>
            <InputText
              name='BrandNameEn'
              id="BrandNameEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandNameEn}
              disabled
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandNameAr" className="font-bold block mb-2">  {t('label_brand_name_ar')}</label>
            <InputText
              value={formik.values.BrandNameAr}
              name='BrandNameAr'
              disabled
              id="BrandNameAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNumber" className="font-bold block mb-2"> {t('label_brand_num')}    </label>
            <InputText
              name='BrandNumber'
              id="BrandNumber"
              type="text"
              disabled
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandNumber}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandEmail" className="font-bold block mb-2">  {t('label_brand_email')}</label>
            <InputText
              value={formik.values.BrandEmail}
              name='BrandEmail'
              disabled
              id="BrandEmail" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="SalesName" className="font-bold block mb-2"> {t('Sales Name')}    </label>
            <InputText
              name='SalesName'
              id="SalesName"
              type="text"
              disabled
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.SalesName}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="SalesPhone" className="font-bold block mb-2">  {t('Sales Phone')}</label>
            <InputText
              value={formik.values.SalesPhone}
              name='SalesPhone'
              disabled
              id="SalesPhone" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='BrandDescEn'
              id="BrandDescEn"
              disabled
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              value={formik.values.BrandDescAr}
              name='BrandDescAr'
              disabled
              id="BrandDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandPolicyEn" className="font-bold block mb-2">{t('Policy_en')}</label>
            <InputText
              name='BrandPolicyEn'
              id="BrandPolicyEn"
              disabled
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandPolicyEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandPolicyAr" className="font-bold block mb-2">{t('Policy_ar')}</label>
            <InputText
              value={formik.values.BrandPolicyAr}
              name='BrandPolicyAr'
              disabled
              id="BrandPolicyAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
          <label htmlFor="link" className="font-bold block mb-2"> {t('label_brand_brand_documents')}  </label>
          <div className="flex btn_gapAr gap-8">
            <Button label={t('image')} type='button' severity="" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
          </div>
        </div>

        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={t('image')}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}

        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={t('cancel')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('BrandDocuments', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
     
                    <Image
                      preview
                      loading="lazy"
                      src={item.BrandDocumentPath}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>


        </Dialog>




      </form>


    </div>
  )
}

export default ProfileBrand