import axios from 'axios'
import img from 'constants/Img'
import LogoSvg from 'constants/LogoSvg'
import { LocalizationContext } from 'context/LangChange'
import { format } from 'date-fns'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Tag } from 'primereact/tag'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import ClientBrandProducts from './ClientBrandProducts'
import ClientTools from './ClientTools'
import ClientsEvents from './ClientsEvents'
import './client.scss'
import ClientBonanza from './ClientBonanza'
import ClientPosition from './ClientPosition'
const ClientDetails = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    let { id } = useParams()
    const [Data, setData] = useState(null);

    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/clients/details/${id}`;
        let data = await axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }
    useEffect(() => {
        fetchData();
        window.scroll(0, 0)
    }, [isLang, id])
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const getSeverity = (status) => {
        switch (status) {
            case 'INACTIVE':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';


            case 'NOT_VERIFIED':
                return 'danger';

            case 'BLOCKED':
                return 'info';

        }
    };
    const items = [
        { label: <Link to={`/client`} className='text-bluegray-400'> {t('client_name')}</Link> },
        { label: <Link to={`/client/transfer/${id}`} className=' text-bluegray-400'>{t('client_transfer')}</Link> },
        { label: <Link to={`/client/ledger/${id}`} className=' text-bluegray-400'>{t('ledgerClients')}</Link> },
        { label: <Link to={`/client/details/${id}`} className='  text-bluegray-800 p-2 border-round'>{t('client_details')}</Link> },
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    return (
        <>
            <div className="mb-3 px-5 mt-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="app__PersonalData">
                <div className="header">
                    <div className="img relative">

                        <div className={`personal   `}>
                            {
                                Data?.ClientPicture ?
                                    <img src={`${Data.ClientPicture}`} className='w-100' alt="images" loading='lazy' /> :
                                    <img src={img.DefaultImage} className='w-100' alt="images" loading='lazy' />
                            }
                        </div>

                    </div>
                </div>
                <div className="info flex flex-row gap-8 mt-35">
                    <h3 className='text-center w-100 capitalize'>   {Data?.ClientName} {Data?.ClientNameArabic && <>/ {Data?.ClientNameArabic}</>} </h3>
                    <Tag
                        value={`${Data?.ClientStatus === 'INACTIVE' ? t('inactive') : Data?.ClientStatus === 'ACTIVE' ? t('active') : Data?.ClientStatus === 'NOT_VERIFIED' ? t('NOT_VERIFIED') : Data?.ClientStatus === 'PENDING' ? t('pending') : t('BLOCKED')}`}
                        severity={getSeverity(Data?.ClientStatus)}
                        rounded
                    />
                </div>
                <div className="info_contactus mt-35">
                    <div className="grid  mt-5  ">
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <LogoSvg.Contact />
                                </div>
                                <div>
                                    <div className="header"> {isLang === "en" ? 'Mobile number' : '   رقم الجوال '}   </div>
                                    <div className="body" dir='ltr'> {Data?.ClientPhone}  </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-mobile'></i>
                                </div>
                                <div>
                                    <div className="header">   {isLang === "en" ? ' App ID  ' : 'رقم تعريف التطبيق'}</div>
                                    <div className="body" dir='ltr'> {Data?.ClientAppID}   </div>
                                </div>
                            </div>
                        </div>

                        {
                            Data?.ClientNationalID ?
                                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                    <div className="conatent ">
                                        <div className="icons">
                                            <i className='pi pi-id-card'></i>
                                        </div>
                                        <div className=' flex flex-column gap-0 '>
                                            <div className="header  ">   {isLang === "en" ? ' National id  ' : ' رقم الهوية  '}</div>
                                            <div className="body  " dir='ltr'> {Data?.ClientNationalID}   </div>
                                        </div>
                                    </div>
                                </div> : ''
                        }

                        {
                            Data?.ClientPassport ?
                                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                    <div className="conatent ">
                                        <div className="icons">
                                            <i className='pi pi-id-card'></i>
                                        </div>
                                        <div className=' flex flex-column gap-0 '>
                                            <div className="header  "> {t('Passport')}</div>
                                            <div className="body  " dir='ltr'> {Data?.ClientPassport}   </div>
                                        </div>
                                    </div>
                                </div> : ''
                        }
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-envelope'></i>
                                </div>
                                <div>
                                    <div className="header">{isLang === "en" ? 'Email' : '   البريد الالكترونى   '}  </div>
                                    <div className="body"> {Data?.ClientEmail} </div>
                                </div>
                            </div>
                        </div>
                        {
                            Data?.PositionTitle ?
                                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                    <div className="conatent ">
                                        <div className="icons">
                                            <i className='pi pi-user'></i>
                                        </div>
                                        <div>
                                            <div className="header"> {t('PositionTitle')} </div>
                                            <div className="body">{Data?.PositionTitle}       </div>
                                        </div>
                                    </div>
                                </div> : ''
                        }
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-dollar'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('E_Cash')} </div>
                                    <div className="body">{Data?.ClientBalance}       </div>
                                </div>
                            </div>
                        </div>

                        {Data?.ClientBirthDate ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi  pi-calendar'></i>
                                    </div>
                                    <div>
                                        <div className="header">   {isLang === "en" ? 'Birthdate  ' : 'تاريخ الميلاد'} </div>
                                        <div className="body">{Data?.ClientBirthDate}       </div>
                                    </div>
                                </div>
                            </div> : ''
                        }


                        {Data?.ClientNationality &&
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi   pi-map-marker'></i>
                                    </div>
                                    <div>
                                        <div className="header">   {t('ClientNationality')}</div>
                                        <div className="body">{Data?.ClientNationality}</div>
                                    </div>
                                </div>
                            </div>}
                        {Data?.ClientGender ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi  pi-user'></i>
                                    </div>
                                    <div>
                                        <div className="header"> {isLang === "en" ? ' Gender  ' : '  النوع '}</div>
                                        <div className="body">{Data?.ClientGender}       </div>
                                    </div>
                                </div>
                            </div> : ''
                        }


                        {Data?.PlanName ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi  pi-user'></i>
                                    </div>
                                    <div>
                                        <div className="header">   {t('PlanName')} </div>
                                        <div className="body">{Data?.PlanName}       </div>
                                    </div>
                                </div>
                            </div> : ''
                        }

                        {Data?.CityName ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi  pi-map'></i>
                                    </div>
                                    <div>
                                        <div className="header">  {t('tabel-header-cities-1')} </div>
                                        <div className="body">{Data?.CityName}</div>
                                    </div>
                                </div>
                            </div> : ''
                        }

                        {Data?.AreaName ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi   pi-map-marker'></i>
                                    </div>
                                    <div>
                                        <div className="header">  {t('tabel-header-areas-1')} </div>
                                        <div className="body">{Data?.AreaName}</div>
                                    </div>
                                </div>
                            </div> : ''}


                        {Data?.ClientCurrentAddress ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi   pi-map-marker'></i>
                                    </div>
                                    <div>
                                        <div className="header">   {t('addres')} </div>
                                        <div className="body">{Data?.ClientCurrentAddress}</div>
                                    </div>
                                </div>
                            </div> : ''}


                        {Data?.ClientIDAddress ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi   pi-map-marker'></i>
                                    </div>
                                    <div>
                                        <div className="header">   {t('ClientIDAddress')}</div>
                                        <div className="body">{Data?.ClientIDAddress}</div>
                                    </div>
                                </div>
                            </div> : ''}

                        {Data?.ParentClient ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi   pi-user-minus'></i>
                                    </div>
                                    <div>
                                        <div className="header">{t('ParentClient')} </div>
                                        <Link to={`/client/details/${Data?.IDParentClient}`}>
                                            <div className="body text-decoration-underline">{Data?.ParentClient}</div>
                                        </Link>
                                    </div>
                                </div>
                            </div> : ''
                        }


                        {Data?.ReferralClient ?
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi   pi-user-plus'></i>
                                    </div>
                                    <div>
                                        <div className="header"> {t('ReferralClient')} </div>
                                        <Link to={`/client/details/${Data?.IDReferralClient}`}>
                                            <div className="body text-decoration-underline">{Data?.ReferralClient}</div>
                                        </Link>
                                    </div>
                                </div>
                            </div> : ''
                        }

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi   pi-user-plus'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('ReferralNumber')} </div>
                                    <div className="body text-decoration-underline">{Data?.ReferralNumber}</div>
                                </div>
                            </div>
                        </div>



                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi   pi-user-plus'></i>
                                </div>
                                <div>
                                    <div className="header">   {isLang === "en" ? 'Reward Points ' : 'نقاط مكافأة  '} </div>
                                    <div className="body">{Data?.ClientRewardPoints}</div>
                                </div>
                            </div>
                        </div>



                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi   pi-user-plus'></i>
                                </div>
                                <div>
                                    <div className="header">   {isLang === "en" ? ' Right Points' : 'النقاط الصحيحة  '} </div>
                                    <div className="body">{Data?.ClientRightPoints}</div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi   pi-user-plus'></i>
                                </div>
                                <div>
                                    <div className="header">   {isLang === "en" ? '  Left Points' : 'نقاط   اليسرى'} </div>
                                    <div className="body">{Data?.ClientLeftPoints}</div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi   pi-user-plus'></i>
                                </div>
                                <div>
                                    <div className="header">   {isLang === "en" ? '  Total Points ' : 'تاريخ الميلاد'} </div>
                                    <div className="body">{Data?.ClientTotalPoints}</div>
                                </div>
                            </div>
                        </div>

                        {Data?.RegisterDate &&
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi  pi-calendar'></i>

                                    </div>
                                    <div>
                                        <div className="header">   {isLang === "en" ? 'Register Date' : 'تاريخ الميلاد'} </div>
                                        <div className="body">{format(new Date(Data?.RegisterDate), 'dd-MM-yyyy')}       </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi  pi-calendar'></i>

                                </div>
                                <div>
                                    <div className="header">   {isLang === "en" ? '  Privacy' : 'خصوصية العميل'} </div>
                                    <div className="body">{Data?.ClientPrivacy === 1 ? 'Yes' : 'No'}       </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className="mt-4">
                    <label htmlFor="ClientNationalIDImage" className="font-bold text-xl block mb-5 ">{t('NationalID_image')}</label>
                    <div className="grid   ">
                        {
                            Data?.ClientDocuments?.map((item, index) => (
                                <div key={index} className="lg:col-3 md:col-12 sm:col-12 mt-2 input__Col  mt-5 ">
                                    {
                                        item?.ClientDocumentType === "NATIONAL_ID" &&
                                        <img src={item?.ClientDocumentPath} width={200} alt="" srcset="" />
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="ClientNationalIDImage" className="font-bold text-xl block mb-5 ">{t('ClientPassport_Image')}</label>
                    <div className="grid">
                        {
                            Data?.ClientDocuments?.map((item, index) => (
                                <div key={index}  >
                                    {
                                        item?.ClientDocumentType === "PASSPORT" &&
                                        <img src={item?.ClientDocumentPath} width={200} alt="" srcset="" />
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <ClientBrandProducts />
                <ClientsEvents />
                <ClientTools />
                <ClientBonanza />
                <ClientPosition/>
            </div>
        </>
    )
}

export default ClientDetails