import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const SocialMedia = () => {
    let { role } = useContext(FetchApi)

    const dt = useRef(null);
    const toast = useRef(null);
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [Data, setData] = useState(null);
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 1 },
        { label: t('inactive'), value: 0 },
    ];
    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/socialmedia`;
        let data = await axios.post(url, {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            }) 
        setData(data?.data?.Response);
    }
    useEffect(() => {
        fetchData();
    }, [isLang])

    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center image_prime p-4' >
            <Image
                preview
                src={rowData.SocialMediaIcon}
                alt={'BrandLogo'}
                width='100px'
                height='100px'
                className='   overflow-hidden  '
                style={{ objectFit: 'cover', borderRadius: '50%' }}
            />
        </div>
    };


    // handel image in tabel
    const nameBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <span>{rowData?.SocialMediaName}</span>
        </div>
    };

    const onStatusChange = async (rowData, e) => {
        
        setEditingRow(null);
        await axios.get(`${process.env.REACT_APP_API_URL}/socialmedia/status/${rowData?.IDSocialMedia}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.SocialMediaActive === 1 ? t('active') : t('inactive');
        const placeholderText = rowData?.SocialMediaActive !== 1 ? t('inactive') : t('active');

        return editingRow === rowData?.IDSocialMedia ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.SocialMediaActive)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.SocialMediaActive)}
                onClick={() => {
                    if (role?.EDIT_SOCIAL_MEDIA == 1) {
                        setEditingRow(rowData?.IDSocialMedia)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>{editingStatus}   </span>
                    {
                        (role?.EDIT_SOCIAL_MEDIA == 1) &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };
    const getSeverity = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case '':
                return 'info';
        }
    };
    // Add new socialmedia
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to={`/socialmedia/add`}>
                    <Button label={t('add_SocialMedia_contacts')} severity="warning" icon="pi pi-plus" size='small' />
                </Link>
            </div>
        );
    };
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                {
                    role?.EDIT_SOCIAL_MEDIA == 1 &&
                    <Link to={`/socialmedia/edit/${rowData.IDSocialMedia}`}>
                        <Button size='small' text raised icon="pi pi-pencil" rounded outlined className=" disabled-button" tooltip={t('edit_SocialMedia_contacts')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDSocialMedia}/SOCIAL`}>
                        <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }

            </div>
        );
    };
    return (
        <div >
            {
                role?.ADD_SOCIAL_MEDIA === 1 &&
                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
            }
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                ref={dt}
                value={Data}
                dataKey="id"
            >
                <Column field="SocialMediaIcon" header={t('logo')} body={imageBodyTemplate}></Column>
                <Column field="SocialMediaName" body={nameBodyTemplate} header={t('SocialMedia_contacts')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                <Column field="SocialMediaActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                {
                    (role?.EDIT_SOCIAL_MEDIA == 1 || localStorage.getItem('Role') == 1) &&
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                }
            </DataTable>
        </div>
    )
}


export default SocialMedia