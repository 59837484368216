import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Image } from 'primereact/image'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

const ToolsDetails = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  let { id } = useParams()
  const [Data, setData] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [files, setFiles] = useState(null);

  const fetchData = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/tools/details`, { IDTool: id },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response?.Tool);
      setDocuments(data?.Response?.Tool?.ToolGallery);
      setFiles(data?.Response?.Tool?.ToolFiles);
    }
  }
  useEffect(() => {
    fetchData();
    window.scroll(0, 0)
  }, [isLang, id])
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const getSeverity = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success';

      case 'INACTIVE':
        return 'danger';

      case 'PENDING':
        return 'warning';

      case 'CANCELLED':
        return 'danger';

      case 'ONGOING':
        return 'warning';


    }
  };

  const items = [
    { label: <Link to={`/tools`} className='text-bluegray-400'> {t('tools_name')}</Link> },
    { label: <Link to={`/tools/details/${id}`} className='  text-bluegray-800 p-2 border-round'>{t('details_tools')}</Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{isLang === "en" ? 'Tool Attendees' : 'الحاضرين للحدث'}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );



  return (
    <>
      <div className="mb-3 px-8 mt-3">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="app__PersonalData mt-0 pt-0">

        <div className="info flex flex-row gap-8 mt-35">
          <Tag
            value={`${Data?.ToolStatus === "ACTIVE" ? t('active') : Data?.ToolStatus === "INACTIVE" ? t('inactive') : Data?.ToolStatus === "ONGOING" ? t('ONGOING') : Data?.ToolStatus === "CANCELLED" ? t('CANCELLED') : Data?.ToolStatus === "CANCELLED" ? t('CANCELLED') : t('pending')}`}
            severity={getSeverity(Data?.ToolStatus)}
          />
        </div>
        <div className="info_contactus mt-35">
          <div className="grid  mt-5  ">

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-exclamation-circle'></i>
                </div>
                <div>
                  <div className="header"> {t('title_en')}   </div>
                  <div className="body" dir='ltr'> {Data?.ToolTitleEn}  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-exclamation-circle'></i>

                </div>
                <div>
                  <div className="header"> {t('title_ar')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolTitleAr}   </div>
                </div>
              </div>
            </div>

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-exclamation-circle'></i>

                </div>
                <div>
                  <div className="header"> {t('desc_en')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolDescEn}   </div>
                </div>
              </div>
            </div>

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-exclamation-circle'></i>
                </div>
                <div>
                  <div className="header"> {t('desc_ar')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolDescAr}   </div>
                </div>
              </div>
            </div>

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-money-bill'></i>
                </div>
                <div>
                  <div className="header"> {t('price')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolPrice}   </div>
                </div>
              </div>
            </div>

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-money-bill'></i>
                </div>
                <div>
                  <div className="header"> {t('ClientRewardPoints')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolPoints}   </div>
                </div>
              </div>
            </div>


            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-circle'></i>
                </div>
                <div>
                  <div className="header"> {t('ReferralPoints')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolReferralPoints}   </div>
                </div>
              </div>
            </div>

            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-file'></i>
                </div>
                <div>
                  <div className="header"> {t('type')}</div>
                  <div className="body" dir='ltr'> {Data?.ToolType}   </div>
                </div>
              </div>
            </div>



          </div>
        </div>
        <div className="pt-5 pb-5">
          <hr />
        </div>
        <div className="info_contactus mt-35">

          <div className="header_gallery_Tool"> <span className='font-bold'>{t('gallery')}</span>   </div>

          <div className="grid  mt-2   ">
            {documents?.map((item, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col relative">
                {
                  item?.ToolGalleryType === "IMAGE" &&
                  <div className="relative  ">
                    <Image
                      preview
                      loading="lazy"
                      src={item.ToolGalleryPath}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                }

                {
                  item?.ToolGalleryType === "VIDEO" &&
                  <div className="relative  ">
                    <iframe width={400} height={250} className="w-full h-19rem border-round-xl " src={`https://${item.ToolGalleryPath}`} title="Ghalia Benali/ YA MSAFER / غالية بنعلي/ يا مسافر وحدك" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                }
              </div>
            ))}
          </div>
        </div>
        <div className="pt-5 pb-5">
          <hr />
        </div>

        <div className="info_contactus mt-35">

          <div className="header_gallery_Tool"> <span className='font-bold'>{isLang === "en" ? 'Files' : 'ملفات'}</span>   </div>

          <div className="grid  mt-2   ">
            {files?.map((item, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
                {
                  item?.ToolGalleryType === "IMAGE" &&
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                  <img
                      src={item.ToolGalleryPath}
                      alt={`Image ${index}`}
                      style={{ objectFit: 'cover' }}
                      className="w-full h-19rem border-round-xl "
                    />
                  </div>
                }

                {
                  item?.ToolGalleryType === "VIDEO" &&
                  <div className="relative  ">
                    <iframe width={400} height={250} className="w-full h-19rem border-round-xl " src={`https://${item.ToolGalleryPath}`} title="Ghalia Benali/ YA MSAFER / غالية بنعلي/ يا مسافر وحدك" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                }
              </div>
            ))}
          </div>
        </div>

        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data?.ClientTools}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column  field="ClientName" header={t('name')} style={{ textAlign: 'center' }}></Column>
          <Column  field="ClientPhone" header={t('phone')} style={{ textAlign: 'center' }}></Column>
          <Column  field="ToolAttendeePaidAmount" header={t('price')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
        </DataTable>
      </div>
    </>
  )
}


export default ToolsDetails
