import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Image } from 'primereact/image'
import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ViewContract = ({ modalDone, setModalDone, id }) => {
    let { isLang } = useContext(LocalizationContext)
    let { t } = useTranslation()
    const dt = useRef(null);

    const [documents, setDocuments] = useState(null);

    const fetchDocuments = async () => {
        setDocuments(null)
        if (id) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/contracts/documents/${id}`)
            if (data?.Success === true) {
                setDocuments(data?.Response);
            }
        }
    }
    useEffect(() => {
        fetchDocuments()
    }, [id]);
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>

            <Image
                preview
                loading="lazy"
                src={rowData.BrandContractDocumentPath}
                alt={'Image'}
                width='300px'
                height='100%'
                className=' mx-auto overflow-hidden '
                style={{ objectFit: 'cover' }}
            />

            {/* <img src={rowData?.BrandContractDocumentPath} alt={'BrandContractDocumentPath'} className="shadow-2 " style={{ width: '300px',  borderRadius:'10px' ,objectFit:'contain' }} /> */}
        </div>
    };

    return (
        <div>

            <Dialog
                visible={modalDone}
                style={{ width: '50rem' }}
                modal
                onHide={setModalDone}
                header={t('contract')}
                dir={isLang === "en" ? 'ltr' : 'rtl'}
            >
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={documents}
                    dataKey="id"
                    paginator
                    rows={10} >
                    <Column  field="BrandContractDocumentPath" header={t('brand_contract')} body={imageBodyTemplate}></Column>
                </DataTable>
            </Dialog>
        </div>
    )
}

export default ViewContract