
import ShowGoogleMap from 'GoogleMap/ShowGoogleMap';
import axios from 'axios';
import LogoSvg from 'constants/LogoSvg';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import { FetchApi } from 'context/FetchApi';

const Branch = () => {
    let { role } = useContext(FetchApi)
    const inputRef = useRef(null);

    let { isLang, setLoctionMap } = useContext(LocalizationContext);
    const [openMap, setOpenMap] = useState(false);
    const hideImagesProjectsDialog = (data) => {
        setOpenMap(!openMap)
        const newMarker = { lat: data?.BranchLatitude, lng: data?.BranchLongitude };
        setLoctionMap(newMarker);
    }

    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);

    // Add new countries
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/branch/add">
                    <Button severity="warning" label={t('branch_add')} icon="pi pi-plus" size='small' />
                </Link>
            <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

            </div>
        );
    };

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                {
                    (role?.EDIT_BRANCHES == 1) &&
                    <Link to={`/branch/edit/${rowData.IDBranch}`}>
                        <Button icon="pi pi-pencil" text raised rounded outlined className="mr-2" tooltip={t('branch_edit')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }
                <Link className='location_branch' onClick={() => hideImagesProjectsDialog(rowData)}>
                    <Button icon='pi pi-map-marker' text raised tooltip={t('tooltip_location')} tooltipOptions={{ position: t('tooltip') }} severity='success' rounded outlined className="mr-2" />
                </Link>
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDBranch}/BRANCH`}>
                        <Button icon="pi pi-file" rounded text raised outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }

            </div>
        );
    };
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
            {/* <h4 className="m-0">{t('search-countries')} </h4> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    fetchData(e.target.value);
                }
                if (e.target.value == '') {
                    fetchData(e.target.value);
                }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
        />          
          </span>
        </div>
    );

    const fetchData = async (SearchKey) => {

        const url = `${process.env.REACT_APP_API_URL}/branches`;
        let data = await axios.post(url, {IDPage:page + 1,SearchKey},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Branches);
        setPagesNum(data?.data?.Response?.Pages)

    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])
    const resetData = () => {
        fetchData();
        inputRef.current.value = ''; // مسح محتوى حقل الإدخال
    }
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/branches/status`, {
            IDBranch: rowData?.IDBranch,
            BranchStatus: e?.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };
    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.BranchStatus === "ACTIVE" ? t('active') : rowData?.BranchStatus === "INACTIVE" ? t('inactive') : t('pending');
        const placeholderText = rowData?.BranchStatus !== 1 ? t('INACTIVE') : t('active');

        return editingRow === rowData?.IDBranch ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.BranchStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.BranchStatus === "ACTIVE" ? t('active') : rowData?.BranchStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.BranchStatus)}
                onClick={() => {
                    if (role?.EDIT_BRANCHES == 1) {
                        setEditingRow(rowData?.IDBranch)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>{`${rowData?.BranchStatus === "ACTIVE" ? t('active') : rowData?.BranchStatus === "INACTIVE" ? t('inactive') : t('pending')}`} </span>
                    {
                        (role?.EDIT_BRANCHES == 1) &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };

    const statusBodyTemplate2 = (rowData) => {

        return editingRow === rowData?.IDBranch ? (
            <></>
        ) : (
            <Tag
                value={`${rowData?.BranchStatus === "ACTIVE" ? t('active') : rowData?.BranchStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
                severity={getSeverity(rowData.BranchStatus)}
                rounded
            />
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'INACTIVE':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';

            case '':
                return 'info';
        }
    };

    return (
        <div className='mb-8'>

            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_BRANCHES == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="BrandName" header={t('brand_name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BranchAddress" header={t('addres')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BranchPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="CityName" header={t('cities_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="AreaName" header={t('areas_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {
                        localStorage.getItem('Role') == 2 &&
                        <Column field="BranchStatus" header={t('status')} body={statusBodyTemplate2} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    }

                    {localStorage.getItem('Role') !== 2 &&
                        <Column field="BranchStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>}
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
            <ShowGoogleMap
                openMap={openMap}
                hideImagesProjectsDialog={hideImagesProjectsDialog}
                isLang={isLang}
            />
        </div>
    )
}
export default Branch