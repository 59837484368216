
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const LedgerClients = () => {
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);



    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">  </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/clients/ledger`;
        let data = await axios.post(url, { IDPage: page + 1, IDClient: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })


        // تصفية الصفوف حيث ClientLedgerAmount لا يساوي صفر
        let filteredData = data?.data?.Response?.ClientLedger.filter(item => item.ClientLedgerAmount !== 0);

        setData(filteredData);
        setPagesNum(data?.data?.Response?.Pages);

    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])

    const items = [
        { label: <Link to={`/client`}>{t('client_name')}</Link> },
        { label: <Link to={`/client/ledger/${id}`} className='p-2 border-round'>{t('client_ledger')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.Date), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="mb-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="card mt-5">
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}

                    size='normal'
                    rows={10}
                // header={header}
                >
                    <Column field="ClientLedgerAmount" header={t('amount')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerSource" header={t('ledgerSource')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerDestination" header={t('ledger_Destination')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerInitialeBalance" header={t('ledger_Initiale_Balance')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerFinalBalance" header={t('ledger_Final_Balance')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerInitialePoints" header={t('ledger_Initiale_Points')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerFinalPoints" header={t('ledger_final_Points')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerType" header={t('ledger_Ledger_Type')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerBatchNumber" header={t('ledger_Batchnumber')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientLedgerPoints" header={t('ledger_points')} style={{ textAlign: 'center' }}></Column>
                    <Column field="Date" header={isLang === "en" ? 'Transfer date' : 'تاريخ التحويل'} body={date} style={{ textAlign: 'center' }}></Column>
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />

            </div>

        </div>
    )
}

export default LedgerClients
