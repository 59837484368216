
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
const SubCategoryAccount = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [page, setPage] = useState(0);
  let { role } = useContext(FetchApi)
  const inputRef = useRef(null);

  const [pagesNum, setPagesNum] = useState(null);
  // Add new categories
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/subcategory_account/add">
          <Button severity="warning" label={t('subcategories_add')} icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        {
          role?.EDIT_ACCOUNT_CATEGORY ?
            <Link to={`/subcategories/edit/${rowData.IDSubCategory}`}>
              <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
            </Link>
            : ""
        }
        {
          localStorage.getItem('Role') == 1 &&
          <Link to={`/log/${rowData.IDSubCategory}/SUBCATEGORY`}>
            <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
          </Link>
        }
      </div>
    );
  };
  // location path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t('search_name')} </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="w-full p-inputtext-sm"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchData(e.target.value);
            }
            if (e.target.value == '') {
              fetchData(e.target.value);
            }
          }}
          placeholder={t('search_placeholder')}
          ref={inputRef}
        />      </span>
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/subcategories`;
    let data = await axios.post(url, { IDPage: page + 1, CategoryType: "COMPANY", SearchKey },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.SubCategories);
    setPagesNum(data?.data?.Response?.Pages)

  }
  useEffect(() => {
    fetchData();
  }, [isLang])
  const resetData = () => {
    fetchData();
    inputRef.current.value = ''; // مسح محتوى حقل الإدخال
  }
  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 1 },
    { label: t('inactive'), value: 0 },
  ];
  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.get(`${process.env.REACT_APP_API_URL}/subcategories/status/${rowData?.IDSubCategory}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.SubCategoryActive === 1 ? t('active') : t('inactive');
    const placeholderText = rowData?.SubCategoryActive !== 1 ? t('inactive') : t('active');

    return editingRow === rowData?.IDSubCategory ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.SubCategoryActive)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${placeholderText}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.SubCategoryActive)}
        onClick={() => {
          if (role?.EDIT_ACCOUNT_CATEGORY == 1) {
            setEditingRow(rowData?.IDSubCategory)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span> {editingStatus}</span>
          {
            role?.EDIT_ACCOUNT_CATEGORY == 1 &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };
  // handel image in tabel 
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center'>
      <Image
        preview
        loading="lazy"
        src={rowData.SubCategoryLogo}
        alt={'Image'}
        width='90'
        height='100%'
        className=' mx-auto overflow-hidden shadow-3  border-round'
        style={{ objectFit: 'cover' }}
      />
      {/* <img src={rowData.SubCategoryLogo} alt={rowData.SubCategoryLogo} className="shadow-2 border-round " style={{ width: '120px' }} /> */}
    </div>
  };

  const getSeverity = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };

  return (
    <div  >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        {
          role?.ADD_ACCOUNT_CATEGORY &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }

        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          {/* <Column field="SubCategoryLogo" header={t('image')} body={imageBodyTemplate}></Column> */}
          <Column field="SubCategoryName" header={t('tabel-header-subcategories-1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="CategoryName" header={t('tabel-header-categories-1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="SubCategoryActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {
            (role?.EDIT_ACCOUNT_CATEGORY == 1 || localStorage.getItem('Role') == 1) &&
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
          }
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

    </div>
  )
}

export default SubCategoryAccount
