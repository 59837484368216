import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

export const FetchApi = createContext([]);

function FetchApiContext({ children }) {
  const [roles, setRoles] = useState([]);

  async function fetchRoles() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/roles`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setRoles(data?.Response);
  }
  useEffect(() => {
    // Update roles when data changes
    if (localStorage.getItem('tokenBetter')) {
      fetchRoles()
    }
  }, [localStorage.getItem('tokenBetter')]);


  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);


  async function fetchCountry() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/countries`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setCountry(data?.Response);
  }

  async function fetchCity(id) {
    if (id) {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cities/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
          },
        }
      );
      setCity(data?.Response);

    }
  }

  async function fetchArea(id) {
    if (id) {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/areas/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
          },
        }
      );
      setArea(data?.Response);
    }
  }

  const [categoriesAjex, setCategoriesAjex] = useState([]);
  async function fetchCategoriesAjex() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/categories/ajax`, { CategoryType: "PROJECT" },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setCategoriesAjex(data?.Response);
  }

  const [subCategoriesAjex, setSubCategoriesAjex] = useState([]);
  async function fetchSubCategoriesAjex(id) {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/subcategories/ajax`, { IDCategory: id, CategoryType: "PROJECT" },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setSubCategoriesAjex(data?.Response);
  }

  const [categoriesAccountAjex, setCategoriesAccountAjex] = useState([]);
  async function fetchCategoriesAccountAjex(CategoryGroup) {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/categories/ajax`, { CategoryType: "COMPANY", CategoryGroup: CategoryGroup },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setCategoriesAccountAjex(data?.Response);
  }

  const [subCategoriesAccountAjex, setSubCategoriesAccountAjex] = useState([]);
  async function fetchSubCategoriesAccountAjex(id) {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/subcategories/ajax`, { IDCategory: id  },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setSubCategoriesAccountAjex(data?.Response);
  }

  const [brandsAjex, setBrandsAjex] = useState([]);
  async function fetchBrandsAjex() {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/brands/ajax`, {},
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setBrandsAjex(data?.Response);
  }

  const [branchesAjex, setbranchesAjex] = useState([]);
  async function fetchbranchesAjex(id) {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/branches/ajax`, { IDBrand: id },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setbranchesAjex(data?.Response);
  }

  const [SalesAjex, setSalesAjex] = useState([]);
  async function fetchSalesAjex() {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users`, {
      IDRole: 3,
      UserStatus: 'ACTIVE'
    },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setSalesAjex(data?.Response?.Users);
  }

  const [idBrand, setIdBrand] = useState(localStorage.getItem('IdBrand'));

  useEffect(() => {
    setIdBrand(localStorage.getItem('IdBrand'));
  }, []);

  /***plan Ajex */
  const [plansProductsAjex, setplansProductsAjex] = useState([]);
  async function fetchplansProductsAjex() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/plans/ajax`, {},
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setplansProductsAjex(data?.Response);
  }

  /***plan products  Ajex */
  const [plansAjex, setPlansAjex] = useState([]);
  async function fetchProductsAjex(id) {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/plans/products/ajax`, { IDPlan: id ? id : 0 },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setPlansAjex(data?.Response);
  }

  /***  products  Ajex */
  const [productsAjex, setProductsAjex] = useState([]);
  async function fetchProductsListAjex(id) {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/brands/products/ajax`, { IDBrand: id },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    
    setProductsAjex(data?.Response);
  }

  /***Postions  Ajex */
  const [positionsAjex, setPositionsAjex] = useState([]);
  async function fetchPositionsAjex() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/positions/ajax`, {},
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setPositionsAjex(data?.Response);
  }

  /***nationalities  Ajex */
  const [nationalitiesAjex, setNationalitiesAjex] = useState([]);
  async function fetchnationalitiesAjex() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/nationalities`, {},
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setNationalitiesAjex(data?.Response);
  }


  /***nationalities  Ajex */
  const [role, setRole] = useState(null);
  async function fetchRole() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/roles/mysections`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    
    setRole(data?.Response);
  }
  useEffect(() => {
    fetchRole();
    return () => {
      fetchRole();
    }
  }, [])

  return (
    <>
      <FetchApi.Provider
        value={{
          roles,
          idBrand,
          fetchCountry,
          fetchCity,
          fetchArea,
          country,
          city,
          area,
          setCity,
          categoriesAjex,
          fetchCategoriesAjex,
          fetchSubCategoriesAjex,
          subCategoriesAjex,
          fetchBrandsAjex,
          brandsAjex,
          SalesAjex,
          fetchSalesAjex,
          branchesAjex,
          fetchbranchesAjex,
          plansProductsAjex,
          fetchplansProductsAjex,
          plansAjex,
          fetchProductsAjex,
          positionsAjex,
          fetchPositionsAjex,
          nationalitiesAjex,
          fetchnationalitiesAjex,
          productsAjex,
          fetchProductsListAjex,
          categoriesAccountAjex,
          fetchCategoriesAccountAjex,
          subCategoriesAccountAjex,
          fetchSubCategoriesAccountAjex,
          role,
          fetchRole
        }}
      >
        {children}
      </FetchApi.Provider>
    </>
  );
}

export default FetchApiContext;
