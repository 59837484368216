import React, { useState } from 'react';
import { List, Button } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

const ToolFilesDisplay = ({ toolFiles, toast, fetchData }) => {
    const [loader, setLoader] = useState(false)
    const [id, setid] = useState(null)
    const deleteDocument = async (id) => {
        setLoader(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/tools/gallery/remove/${id}`,
            {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            }).then(({ data }) => {

                if (data?.Success === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                    fetchData()
                    setTimeout(() => {
                        setLoader(false)
                    }, 1000);
                }
            }).catch(({ response }) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
            })

    };

    return (
        <List
            itemLayout="horizontal"
            dataSource={toolFiles}
            renderItem={item => (
                <List.Item
                    actions={[
                        <div className='flex gap-3'>
                            <Button
                                type=""
                                icon={<DownloadOutlined />}
                                onClick={() => window.open(item.ToolGalleryPath, '_blank')}
                            >
                            </Button>
                            <Button
                                loading={id === item.IDToolGallery ? loader : false}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    deleteDocument(item.IDToolGallery)
                                    setid(item.IDToolGallery)
                                }}
                            >
                            </Button>
                        </div>
                    ]}
                >
                    <List.Item.Meta
                        title={`  ${item.ToolGalleryPath.split('/').pop()}`} // Display file name extracted from URL
                        description={item.ToolGalleryType}
                    />
                </List.Item>
            )}
        />
    );
};

export default ToolFilesDisplay;
