import { useContext } from 'react';
import { LocalizationContext } from '../../context/LangChange';
import './Dashboard.scss';
function Dashboard() {
    let { isLang } = useContext(LocalizationContext);

    return (
        <> 
            <div className="welcome__page   bg-body  " style={{ display: 'flex ', justifyContent: 'center', alignItems: 'center' }}>
                <div className="title_Dynamic bg-blue-100 border-round shadow  rounded-3">
                    <h3> {isLang === "en" ? 'Dashboard Better Way' : 'Dashboard Better Way'}   </h3>
                </div>
            </div>

        </>
    )
}

export default Dashboard