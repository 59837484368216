import { Button } from 'antd'
import React from 'react'

const BtnAntd = ({handleCancel,loading,form}) => {
    return (
        <div className='flex justify-content-end gap-2'>
            <Button key="cancel" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
                Send
            </Button>,
        </div>
    )
}

export default BtnAntd
