
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import ViewContract from './ViewContract';
import { FetchApi } from 'context/FetchApi';
import { Paginator } from 'primereact/paginator';

const BrandContract = () => {
  const inputRef = useRef(null);

  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [modalDone, setModalDone] = useState(false);
  const [dataContract, setdataContract] = useState(null);
  let { role } = useContext(FetchApi)
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const handelContract = (data) => {

    setdataContract(data?.IDBrandContract)
    setModalDone(true)
  }

  const items = [
    { label: <Link to={`/brands`}>{t('brand_name')}</Link> },
    { label: <Link to={`/brands/contract/${id}`} className='p-2 border-round'>{t('brand_contract')}</Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };

  // Add new contracts
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to={`/brands/contract/${id}/add`}>
          <Button label={t('add_brand_contract')} severity="warning" className='disabled-button' icon="pi pi-plus" size='small' />
        </Link>
        {/* <Button  onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' /> */}

      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center ">
        <Tooltip target=".disabled-button" autoHide={false} />
        <Button onClick={() => handelContract(rowData)} icon="pi pi-file" rounded outlined className="mr-2" severity="warning" tooltip={t('contract')} tooltipOptions={{ position: t('tooltip') }} />

        {localStorage.getItem('Role') == 1 &&
          <Link to={`/brands/contract/${id}/edit/${rowData.IDBrandContract}`}>
            <Button icon="pi pi-pencil" rounded outlined className="mr-2" tooltip={t('edit_brand_contract')} tooltipOptions={{ position: t('tooltip') }} />
          </Link>
        }
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">  </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="w-full p-inputtext-sm"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchData(e.target.value);
            }
            if (e.target.value == '') {
              fetchData(e.target.value);
            }
          }}
          placeholder={t('search_placeholder')}
          ref={inputRef}
        />      </span>
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/brands/contracts`;
    let data = await axios.post(url, {
      IDPage: page + 1,
      IDBrand: id,
      SearchKey
    },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Brands);
    setPagesNum(data?.data?.Response?.Pages)

  }
  useEffect(() => {
    fetchData();
  }, [isLang, page])

  const resetData = () => {
    fetchData();
    inputRef.current.value = ''; // مسح محتوى حقل الإدخال
  }
  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('expired'), value: 'EXPIRED' },
    { label: t('pending'), value: 'PENDING' },
    { label: t('deactivated'), value: 'DEACTIVATED' },
  ];


  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.BrandContractStatus === 1 ? t('active') : t('inactive');
    return editingRow === rowData?.IDBrandContract ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.BrandContractStatus)}
        placeholder={`${rowData?.BrandContractStatus === "ACTIVE" ? t('active') : rowData?.BrandContractStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.BrandContractStatus)}
        onClick={() => {
          if (role?.EDIT_BRANDS == 1) {
            // setEditingRow(rowData?.IDBrandContract)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>{`${rowData?.BrandContractStatus === "ACTIVE" ? t('active') : rowData?.BrandContractStatus === "INACTIVE" ? t('inactive') : t('pending')}`}   </span>
          {
            role?.EDIT_BRANDS == 1 &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };

  const date = (rowData) => {
    const formattedDate = format(new Date(rowData?.BrandContractStartDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };

  const enddate = (rowData) => {
    const formattedDate = format(new Date(rowData?.BrandContractEndDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success';

      case 'EXPIRED':
        return 'danger';

      case 'PENDING':
        return 'warning';

      case 'DEACTIVATED':
        return 'info';
    }
  };

  return (
    <div>
      <div className="mb-5">

        <BreadCrumb model={items} home={home} />

      </div>
      {
        (role?.ADD_BRANDS == 1) &&
        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
      }
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">

        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}

        // header={header}
        >
          <Column field="BrandContractStartDate" header={t('tabel_header_contract_1')} body={date} style={{ textAlign: 'center' }}></Column>
          <Column field="BrandContractEndDate" header={t('tabel_header_contract_2')} body={enddate} style={{ textAlign: 'center' }}></Column>
          <Column field="BrandContractMonths" header={isLang === "en" ? ' Number Months  ' : 'أشهر عقد'} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="BrandContractAmount" header={t('tabel_header_contract_3')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="BrandContractStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {
            (role?.EDIT_BRANDS == 1) &&
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          }

        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

      <ViewContract
        modalDone={modalDone}
        setModalDone={setModalDone}
        id={dataContract}
      />
    </div>
  )
}
export default BrandContract
