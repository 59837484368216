import { useTranslation } from "react-i18next"

const SummaryClient = ({ data }) => {
    const { t } = useTranslation()
    return (
        <>

            <div className="grid  p-1 ">

                {
                    data?.ClientNumber &&
                    <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
                        <div className="client_summary border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                            <div className="icons">
                                <i className='pi pi-users'></i>
                            </div>
                            <div className="content flex flex-column justify-content-end align-items-  ">
                                <span className=' number'>{t('client_name')}</span>
                                <span className=' header user'>{data?.ClientNumber}</span>
                            </div>
                        </div>
                    </div>
                }
                     {
                    data?.ActiveNetworkers &&
                    <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
                        <div className="client_summary Sales_summary2 border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                            <div className="icons">
                                <i className='pi pi-users'></i>
                            </div>
                            <div className="content flex flex-column justify-content-center align-items-   -2">
                                <span className='number'>{t('ActiveNetworkers')}</span>
                                <span className='header user'>{data?.ActiveNetworkers}</span>
                            </div>
                        </div>
                    </div>
                }
  

                {
                    data?.ClientTotalRewardPoints &&
                    <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
                        <div className="client_summary Sales_summary border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                            <div className="icons">
                                <i className='pi pi-gift'></i>
                            </div>
                            <div className="content flex flex-column justify-content-center align-items-   -2">
                                <span className='number'>{t('ClientRewardPoints')}</span>
                                <span className='header user'>{data?.ClientTotalRewardPoints}</span>
                            </div>
                        </div>
                    </div>
                }
              {
                    data?.ClientTotalPoints &&
                    <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
                        <div className="client_summary Sales_summary2 border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                            <div className="icons">
                                <i className='pi pi-users'></i>
                            </div>
                            <div className="content flex flex-column justify-content-center align-items-   -2">
                                <span className='number'>{t('ClientTotalPoints')}</span>
                                <span className='header user'>{data?.ClientTotalPoints}</span>
                            </div>
                        </div>
                    </div>
                }
           
            </div>
        </>
    )
}

export default SummaryClient
