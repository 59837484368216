
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const BrandGallery = () => {
  const { id } = useParams()
  const [deleteLoadind, setdeleteLoadind] = useState(false);
  const [deleteusersDialog, setDeleteusersDialog] = useState(false);
  const [IdDeleteusers, setIdDeleteusers] = useState(null);
  let { role } = useContext(FetchApi)

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [Data, setData] = useState(null);
  const items = [
    { label: <Link to={`/brands`}>{t('brand_name')}</Link> },
    { label: <Link to={`/brands/gallery/${id}`} className='p-2 border-round'>{t('brand_gallery')}</Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };
  const editClientsPermission = role?.EDIT_BRANDS === 1;
  // Add new categories
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to={`/brands/gallery/${id}/add`}>
          <Button label={t('add_brand_gallery')} severity="warning" icon="pi pi-plus" size='small' />
        </Link>
      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2">
        <Button icon="pi pi-trash" tooltip={t('delete_brand_gallery')} tooltipOptions={{ position: t('tooltip') }} rounded outlined severity="danger" onClick={() => confirmDeleteusers(rowData)} />
      </div>
    );
  };
  const confirmDeleteusers = async (users) => {
    setDeleteusersDialog(true);
    setIdDeleteusers(users?.IDBrandGallery)
  };
  const deleteusers = async (rowData) => {
    setdeleteLoadind(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/gallery/remove/${IdDeleteusers}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

        fetchData()
        setTimeout(() => {
          setDeleteusersDialog(false);
          setdeleteLoadind(false)
        }, 1000);
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setdeleteLoadind(false)
    })

  };
  const fetchData = async () => {

    const url = `${process.env.REACT_APP_API_URL}/brands/gallery`;
    let data = await axios.post(url, { IDBrand: id },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response);
  }
  useEffect(() => {
    fetchData();
  }, [isLang])


  const onStatusChange = async (rowData, e) => {

    await axios.get(`${process.env.REACT_APP_API_URL}/categories/status/${rowData?.IDCategory}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };


  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    if (rowData?.BrandGalleryType === "IMAGE") {
      return <div className='flex justify-content-center'>
        <Image
          preview
          loading="lazy"
          src={rowData.BrandGalleryPath}
          alt={'Image'}
          width='300px'
          height='100%'
          className=' mx-auto overflow-hidden '
          style={{ objectFit: 'cover' }}
        />
      </div>
    } if (rowData?.BrandGalleryType === "VIDEO") {
      return <div className='flex justify-content-center'>
        <iframe width={400} height={250} className="border-round  " src={`https://${rowData.BrandGalleryPath}`} title="Ghalia Benali/ YA MSAFER / غالية بنعلي/ يا مسافر وحدك" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    }
  };


  const hideDeleteusersDialog = () => setDeleteusersDialog(false);
  const deleteusersDialogFooter = (
    <div className='btn_gapAr flex justify-content-center align-items-center'>
      <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
      <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
    </div>
  );
  return (
    <div className='mb-8'>
      <div className="mb-3">
        {
          editClientsPermission == 1 &&
          <BreadCrumb model={items} home={home} />
        }
      </div>
      {
        editClientsPermission == 1 &&
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      }

      <div className="card">
        {
          editClientsPermission == 1 &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }
        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          paginator
          rows={10} >
          <Column header={t('gallery')} body={imageBodyTemplate}></Column>
          {
            editClientsPermission == 1 &&
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
          }
        </DataTable>

      </div>
      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
        <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>
            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
          </span>
        </div>
      </Dialog>
    </div>
  )
}

export default BrandGallery
