import axios from 'axios';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FetchApi } from 'context/FetchApi';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from 'context/LangChange';
import { useEffect } from 'react';
import { AutoComplete } from 'antd';
const fetchClients = async (searchText, type, IDReferral) => {
    try {
        let response = await axios.post(
            `${process.env.REACT_APP_API_URL}/clients/check`,
            { UserName: searchText, Type: type, IDReferral },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            }
        );
        return response.data.Response;
    } catch (error) {
        console.error('Error fetching clients:', error);
        return [];
    }
};

const transformClientData = (clients) => {
    return clients?.map(client => ({
        value: client.ClientPhone,  // استخدام الـ id هنا كقيمة
        label: (
            <div>
                {client.ClientName} - {client.ClientPhone} - {client.ClientAppID}
            </div>
        ),
        client,  // إضافة الكائن الكامل لاستخدامه لاحقًا
    }));
};
const NetworkRequest = () => {
    let { isLang } = useContext(LocalizationContext);
    const [options, setOptions] = useState([]);
    const [optionsReferral, setOptionsReferral] = useState([]);
    const [valueIDReferral, setValueIDReferral] = useState(null);

    let { id } = useParams()
    let { setCity, plansProductsAjex, fetchplansProductsAjex, plansAjex, fetchProductsAjex } = useContext(FetchApi)

    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/clients/network/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const items = [
        { label: <Link to={'/client'}>{t('client_name')}   </Link> },
        { label: <Link to={`/client/request/${id}`} className='p-2 border-round'>  {isLang === "en" ? 'Request' : 'طلبات'}  </Link> }
    ];
    const statusList = [
        { name: t('active'), value: '1' },
        { name: t('inactive'), value: '0' },
    ];

    const PlanNetworkPosition = [
        { name: isLang === "en" ? 'Left' : 'يسار', value: 'LEFT' },
        { name: isLang === "en" ? 'Right' : 'يمين', value: 'RIGHT' },
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const formik = useFormik({
        initialValues: {
            IDClient: id,
            IDPlanProduct: '',
            Upline: '',
            Referral: '',
            PlanNetworkPosition: '',
            // IDPlan: ''
        },
        onSubmit: async (values, { resetForm }) => {
            
            try {
                setLoading(true);
                let { data } = await axios.post(url, {
                    IDClient: id,
                    // IDPlan: values?.IDPlan,
                    IDPlanProduct: values?.IDPlanProduct,
                    Upline: values?.Upline,
                    Referral: values?.Referral,
                    PlanNetworkPosition: values?.PlanNetworkPosition,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })
                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        setCity([])
                        navigate('/client')
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });


    useEffect(() => {
        // fetchplansProductsAjex()
        fetchProductsAjex()
        return () => {
            // fetchplansProductsAjex()
            fetchProductsAjex()

        }
    }, [isLang])





    const onSearch = async (searchText) => {
        if (searchText) {
            formik.setFieldValue('Referral', searchText);

            const clients = await fetchClients(searchText, 'REFERRAL');
            setOptionsReferral(transformClientData(clients));
     
        } else {
            setOptions([]);
        }
    };

    const onSelect = (value, option) => {
        setValueIDReferral(option?.client?.IDClient);
        formik.setFieldValue('Referral', value);

    };

    const onSearchReferralClient = async (searchText) => {
        if (searchText) {
            formik.setFieldValue('Upline', searchText);

            const clients = await fetchClients(searchText, 'UPLINE', valueIDReferral);
            setOptions(transformClientData(clients));
        } else {
            setOptionsReferral([]);
        }
    };

    const onSelectReferralClient = (value, option) => {
        formik.setFieldValue('Upline', value);

        setValueIDReferral(option?.client?.IDClient);

    };



    return (
        <>
            <div className='mb-8'>
                <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

                <BreadCrumb model={items} home={home} />
                <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                    <div className="grid  mt-5  ">
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="Referral" className="font-bold block mb-2">   {t('ReferralClient')}  </label>

                            <AutoComplete
                                options={optionsReferral}
                                name='Referral'
                                id="Referral"
                                style={{ width: '100%' }}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder='enter phone, app id, email'
                                size='large'
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                            <label htmlFor="Upline" className="font-bold block mb-2"> {t('ParentClient')}   </label>
                            <AutoComplete
                                options={options}
                                name='Upline'
                                id="Upline"
                                style={{ width: '100%' }} 
                                onSelect={onSelectReferralClient}
                                onSearch={onSearchReferralClient}
                                placeholder='enter phone, app id, email'
                                size='large'
                            />
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                            <label htmlFor="PlanNetworkPosition" className="font-bold block mb-2">
                                {isLang === "en" ? '  Network position  ' : '  تخطيط موقف الشبكة '}
                            </label>
                            <Dropdown filter required
                                id="PlanNetworkPosition"
                                name="PlanNetworkPosition"
                                value={formik.values.PlanNetworkPosition}
                                options={PlanNetworkPosition}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue("PlanNetworkPosition", e.value)}
                                onBlur={formik.handleBlur}
                                placeholder={isLang === "en" ? 'Select a position' : 'حدد مكان '}
                                className="w-full p-inputtext-sm"
                            />
                        </div>
                        {/*          <div className="lg:col-6 md:col-12 sm:col-12   mt-2   ">
                            <label htmlFor="IDPlan" className="font-bold block mb-2">{t('PlanName')} </label>
                            <Dropdown filter required
                                options={plansProductsAjex?.map(item => ({
                                    name: item.PlanName,
                                    value: item.IDPlan,
                                }))}
                                id="IDPlan"
                                name="IDPlan"
                                optionLabel="name"
                                optionValue="value"
                                value={formik.values.IDPlan} // Add this line 
                                onChange={(e) => {
                                    formik.setFieldValue("IDPlan", e.value)
                                    fetchProductsAjex(e.value)
                                }}
                                onBlur={formik.handleBlur}
                                placeholder={t('plan_placeholder')}
                                className="w-full p-inputtext-sm"
                            />
                        </div> */}

                        {/* // formik.values.IDPlan && */}
                        <div div className="lg:col-6 md:col-12 sm:col-12">
                            <label htmlFor="IDPlanProduct" className="font-bold block mb-2">{t('  Product')}</label>
                            <Dropdown filter required
                                options={plansAjex?.map(item => ({
                                    name: item.PlanProductName,
                                    value: item.IDPlanProduct,
                                }))}
                                id="IDPlanProduct"
                                name="IDPlanProduct"
                                optionLabel="name"
                                optionValue="value"
                                value={formik.values.IDPlanProduct} // Add this line 
                                onChange={(e) => {
                                    formik.setFieldValue("IDPlanProduct", e.value)
                                }}
                                onBlur={formik.handleBlur}
                                placeholder={t('product_placeholder')}
                                className="w-full p-inputtext-sm"
                            />
                        </div>


                    </div>

                    <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                        <div className="btn_submit">
                            <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} size='small' className='mt-3' />
                        </div>
                        <Link to={'/client'}>
                            <Button severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
                        </Link>
                    </div>
                </form >
            </div >
        </>
    )
}


export default NetworkRequest
