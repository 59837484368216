import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NULL } from 'sass';

const UpdateProducts = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/products/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()
  const DiscountType = [
    { name: 'Value', value: 'VALUE' },
    { name: 'Precent', value: 'PERCENT' },
    { name: 'Invoice', value: 'INVOICE' },

  ];
  const items = [
    { label: <Link to={'/products'}>{t('products')}</Link> },
    { label: <Link to={`/products/edit/${id}`} className='p-2 border-round'>{t('products_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/edit/page/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response);
      setDocuments(data?.Response?.BrandProductGallery); 
    }
  }

  useEffect(() => {
    fetchData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      IDBrandProduct: id,
      BrandProductTitleEn: Data?.BrandProductTitleEn || '',
      BrandProductTitleAr: Data?.BrandProductTitleAr || '',
      BrandProductPrice: Data?.BrandProductPrice || '',
      BrandProductDescEn: Data?.BrandProductDescEn || '',
      BrandProductDescAr: Data?.BrandProductDescAr || '',
      BrandProductPoints: Data?.BrandProductPoints || '',
      // BrandProductReferralPoints: Data?.BrandProductReferralPoints || '',
      // BrandProductUplinePoints: Data?.BrandProductUplinePoints || '',
      BrandProductStartDate: Data?.BrandProductStartDate || '',
      BrandProductEndDate: Data?.BrandProductEndDate || '',
      BrandProductDiscountType: Data?.BrandProductDiscountType || '',
      BrandProductGallery: selectMultiImage,
      BrandProductDiscount: Data?.BrandProductDiscount || '',
      BrandProductInvoiceMin: Data?.BrandProductInvoiceMin || '',
      BrandProductMaxDiscount: Data?.BrandProductMaxDiscount || ''
    },
    onSubmit: async (values, { resetForm }) => {
      if (values.BrandProductDiscountType == 'INVOICE') {
        values.BrandProductPrice = ''

      } else {
        values.BrandProductMaxDiscount = ''
        values.BrandProductInvoiceMin = ''
      }
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/products')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/gallery/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchData()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };


  useEffect(() => {
    formik.setValues({
      BrandProductTitleEn: Data?.BrandProductTitleEn || '',
      BrandProductTitleAr: Data?.BrandProductTitleAr || '',
      BrandProductPrice: Data?.BrandProductPrice || '',
      BrandProductDiscount: Data?.BrandProductDiscount || '',
      IDBrandProduct: id,
      BrandProductDescEn: Data?.BrandProductDescEn || '',
      BrandProductDescAr: Data?.BrandProductDescAr || '',
      BrandProductPoints: Data?.BrandProductPoints || '',
      BrandProductStartDate: Data?.BrandProductStartDate || '',
      BrandProductEndDate: Data?.BrandProductEndDate || '',
      // BrandProductReferralPoints: Data?.BrandProductReferralPoints || '',
      // BrandProductUplinePoints: Data?.BrandProductUplinePoints || '',
      BrandProductDiscountType: Data?.BrandProductDiscountType || '',
      BrandProductInvoiceMin: Data?.BrandProductInvoiceMin || '',
      BrandProductMaxDiscount: Data?.BrandProductMaxDiscount || '',

    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>

      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandProductTitleEn" className="font-bold block mb-2"> {t('label_product_name_en')}    </label>
            <InputText
              name='BrandProductTitleEn'
              id="BrandProductTitleEn"
              type="text"
              className="w-full  p-inputtext-sm"

              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandProductTitleEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductTitleAr" className="font-bold block mb-2">  {t('label_product_name_ar')}</label>
            <InputText
              value={formik.values.BrandProductTitleAr}
              name='BrandProductTitleAr'
              id="BrandProductTitleAr" type="text" className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className="grid   ">

          <div className={`lg:col-6 md:col-12 sm:col-12 xs:col-12 mt-2 input__Col  bg-info ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandProductStartDate" className="font-bold block mb-2"> {t('start_date')}</label>
            <Calendar
              id="BrandProductStartDate"
              name="BrandProductStartDate"
              value={formik?.values?.BrandProductStartDate ? parseISO(formik?.values?.BrandProductStartDate) : null}
              placeholder={isLang === "en" ? ' start date' : ' تاريخ بدأ '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("BrandProductStartDate", selectedDate); 
              }}
              showIcon
              className='w-full'
              dateFormat="dd-mm-yy"
            />
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandProductEndDate" className="font-bold block mb-2">{t('end_date')}</label>
            <Calendar
              id="BrandProductEndDate"
              name="BrandProductEndDate"
              value={formik?.values?.BrandProductEndDate ? parseISO(formik?.values?.BrandProductEndDate) : null}
              placeholder={isLang === "en" ? ' start date' : ' تاريخ بدأ '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("BrandProductEndDate", selectedDate); 
              }}
              showIcon
              className='w-full'

              dateFormat="dd-mm-yy"
            />

          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandProductDescEn" className="font-bold block mb-2"> {t('label_product_Desc_en')}    </label>
            <InputText
              name='BrandProductDescEn'
              id="BrandProductDescEn"
              type="text"
              className="w-full  p-inputtext-sm"

              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandProductDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductDescAr" className="font-bold block mb-2">  {t('label_product_Desc_ar')}</label>
            <InputText
              value={formik.values.BrandProductDescAr}
              name='BrandProductDescAr'
              id="BrandProductDescAr" type="text" className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandProductDiscountType" className="font-bold block mb-2"> {t('Discounttype')} </label>
            <Dropdown filter
              id="BrandProductDiscountType"
              name="BrandProductDiscountType"
              value={formik.values.BrandProductDiscountType}
              optionLabel="name"
              onChange={(e) => {
                formik.setFieldValue("BrandProductDiscountType", e.value)
                if (formik.values.BrandProductDiscountType === "INVOICE") {
                  formik.setFieldValue("BrandProductPrice", null)

                } else {
                  formik.setFieldValue("BrandProductPrice", formik.values.BrandProductPrice)

                }
              }}
              onBlur={formik.handleBlur}
              options={DiscountType}
              placeholder={isLang === "en" ? 'payment method' : 'طريقة الدفع '}
              className="w-full  "

            />

          </div>
          {
            formik.values.BrandProductDiscountType != 'INVOICE' ?
              <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                <label htmlFor="BrandProductPrice" className="font-bold block mb-2">{t('price')}</label>
                <InputText
                  name='BrandProductPrice'
                  id="BrandProductPrice"
                  type="text"
                  className="w-full  p-inputtext-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.BrandProductPrice}
                />
              </div>
              : <>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                  <label htmlFor="BrandProductInvoiceMin" className="font-bold block mb-2">{t('InvoiceMin')}</label>
                  <InputText
                    name='BrandProductInvoiceMin'
                    id="BrandProductInvoiceMin"
                    type='text'
                    className="w-full  p-inputtext-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.BrandProductInvoiceMin}

                  />
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                  <label htmlFor="BrandProductMaxDiscount" className="font-bold block mb-2">{t('InvoiceMax')}</label>
                  <InputText
                    name='BrandProductMaxDiscount'
                    id="BrandProductMaxDiscount"
                    type='text'
                    className="w-full  p-inputtext-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.BrandProductMaxDiscount}

                  />
                </div>
              </>
          }
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductDiscount" className="font-bold block mb-2">{t('discount')}</label>
            {
              formik.values.BrandProductDiscountType === "VALUE" ?
                <InputNumber
                  value={formik.values.BrandProductDiscount}
                  name='BrandProductDiscount'
                  onChange={(e) => {
                    formik.setFieldValue('BrandProductDiscount', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="BrandProductDiscount"
                  className="w-full  p-inputtext-sm"
                />
                :
                <InputNumber
                  value={formik.values.BrandProductDiscount}
                  name='BrandProductDiscount'
                  onChange={(e) => {
                    formik.setFieldValue('BrandProductDiscount', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="BrandProductDiscount"
                  className="w-full  p-inputtext-sm"
                  prefix="%" />
            }


          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-3 input__Col" >
            <label htmlFor="BrandProductPoints" className="font-bold block mb-2"> {t('ClientRewardPoints')}</label>
            <InputText
              value={formik.values.BrandProductPoints}
              name='BrandProductPoints'
              id="BrandProductPoints" type="text" className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2"> {t('BrandProductGallery')}  </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} type='button' size='small' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
            </div>
          </div>
        </div>


        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={t('BrandProductGallery')}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('BrandProductGallery', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">

                    <Image
                      preview
                      loading="lazy"
                      src={item.BrandProductPath}
                      alt={'Image'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    <Button icon="pi pi-times" severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDBrandProductGallery)} />
                  </div>
                </div>
              ))}
            </div>
          </div>


        </Dialog>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/products'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>



      </form>


    </div>
  )
}

export default UpdateProducts