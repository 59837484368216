
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
const Ads = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    let { role } = useContext(FetchApi)
    const inputRef = useRef(null);

    // Add new categories
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/ads/add">
                    <Button label={t('add')} severity="warning" raised icon="pi pi-plus" size='small' />
                </Link>

                <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

            </div>
        );
    };
    // logcation path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                {
                    role?.EDIT_BANNERS == 1 &&
                    <Link to={`/ads/edit/${rowData.IDAdvertisement}`}>
                        <Button icon="pi pi-pencil" severity="danger" rounded outlined text raised className="mr-2" />
                    </Link>
                }

                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDAdvertisement}/ADS `}>
                        <Button icon="pi pi-file" rounded outlined text raised severity="secondary" />
                    </Link>
                }
            </div>
        );
    };
    const filterType = [
        { name: t('reset'), value: 'ALL' },
        { name: t('HOME'), value: 'HOME' },
        { name: t('PAGES'), value: 'PAGES' },
    ];
    const filterType2 = [
        { name: t('reset'), value: 'ALL' },
        { name: t('NONE'), value: 'NONE' },
        { name: t('brand_name'), value: 'BRAND' },
        { name: t('BRAND_PRODUCT'), value: 'BRAND_PRODUCT' },
    ];
    const [selectedTypeFilter, setTypeFilter] = useState(null);
    const [selectedTypeFilter2, setTypeFilter2] = useState(null);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div>
                <Dropdown
                    value={selectedTypeFilter}
                    options={filterType}
                    id="CategoryType"
                    name="CategoryType"
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e) => {
                        setTypeFilter(e.value)
                        setTypeFilter2(null)

                        if (e.value === "ALL") {
                            fetchData('')
                            setTypeFilter(null)

                        } else {
                            fetchData(e.value)
                        }
                    }}
                    placeholder={isLang === "en" ? 'All ' : 'الجميع'}
                    className="p-inputtext-sm w-14rem"
                />
            </div>
            <div>
                <Dropdown
                    value={selectedTypeFilter2}
                    options={filterType2}
                    id="CategoryType"
                    name="CategoryType"
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e) => {
                        setTypeFilter2(e.value)
                        setTypeFilter(null)

                        if (e.value === "ALL") {
                            fetchData('')
                            setTypeFilter2(null)
                        } else {
                            fetchData(null, e.value)
                        }
                    }}
                    placeholder={isLang === "en" ? 'All' : 'الجميع'}
                    className="p-inputtext-sm w-14rem"
                />
            </div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
       {/*          <InputText
                    type="search"
                    className="w-full p-inputtext-sm"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            fetchData('','',e.target.value);
                        }
                        if (e.target.value == '') {
                            fetchData('','',e.target.value);

                        }
                    }}
                    placeholder={t('search_placeholder')}
                    ref={inputRef}
                /> */}
            </span>
        </div>
    );

    const fetchData = async (AdvertisementLocation, AdvertisementService,SearchKey) => {
        const url = `${process.env.REACT_APP_API_URL}/advertisements`;
        let requestBody = { IDPage: page + 1 };

        if (AdvertisementLocation) requestBody.AdvertisementLocation = AdvertisementLocation;
        if (AdvertisementService) requestBody.AdvertisementService = AdvertisementService;

        if (SearchKey) requestBody.SearchKey = SearchKey;

        let data = await axios.post(url, requestBody,
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Advertisements);
        setPagesNum(data?.data?.Response?.Pages)
    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])
    const resetData = () => {
        fetchData();
        setTypeFilter(null)
        setTypeFilter2(null)
        inputRef.current.value = ''; // مسح محتوى حقل الإدخال

    }

    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 1 },
        { label: t('inactive'), value: 0 },
    ];
    const onStatusChange = async (rowData, e) => {
        
        setEditingRow(null);
        await axios.get(`${process.env.REACT_APP_API_URL}/advertisements/status/${rowData?.IDAdvertisement}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };



    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.AdvertisementActive === 1 ? t('active') : t('inactive');
        const placeholderText = rowData?.AdvertisementActive !== 1 ? t('inactive') : t('active');

        return editingRow === rowData?.IDAdvertisement ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.AdvertisementActive)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.AdvertisementActive)}
                onClick={() => {
                    if (role?.EDIT_BANNERS == 1) {
                        setEditingRow(rowData?.IDAdvertisement)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    {
                        role?.EDIT_BANNERS == 1 &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <Image
                preview
                loading="lazy"
                src={rowData.AdvertisementImage}
                alt={'Image'}
                width='90'
                height='100%'
                className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
            />
        </div>
    };

    const getSeverity = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case '':
                return 'info';
        }
    };

    const handeText = (text) => {
        return text?.replace(/_/g, ' ').charAt(0).toUpperCase() + text?.replace(/_/g, ' ').slice(1)?.toLowerCase()
    }
    const text = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <span>{handeText(rowData?.AdvertisementLocation)}</span>
            </div>
        );
    };

    const text2 = (text) => {
        return <span>  {handeText(text?.AdvertisementService)}</span>

    }
    const startDate = (rowData) => {
        const formattedDate = format(new Date(rowData?.AdvertisementStartDate), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };

    const enddate = (rowData) => {
        const formattedDate = format(new Date(rowData?.AdvertisementEndDate), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    return (
        <div  >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_BANNERS == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    showGridlines
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="AdvertisementImage" header={t('image')} body={imageBodyTemplate}></Column>
                    {/* <Column sortable field="AdvertisementName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column sortable field="AdvertisementLocation" header={isLang === "en" ? 'Go To' : 'اذهب إلى'} body={text} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column sortable field="AdvertisementService" header={t('Service')} body={text2} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="AdvertisementStartDate" header={t('tabel_header_contract_1')} body={startDate} style={{ minWidth: '10rem', textAlign: 'center' }} />
                    <Column field="AdvertisementEndDate" header={t('tabel_header_contract_2')} body={enddate} style={{ minWidth: '10rem', textAlign: 'center' }} />
                    <Column sortable field="AdvertisementActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {
                        role?.EDIT_BANNERS == 1 &&
                        <Column body={actionBodyTemplate} exportable={false} header={t('action')} style={{ minWidth: '12rem' }}></Column>
                    }
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

        </div>
    )
}


export default Ads