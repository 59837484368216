import { ConfigProvider } from 'antd';
import Component from 'constants/Component';
import FetchApiContext from 'context/FetchApi';
import LangChange from 'context/LangChange';
import { Tooltip } from 'primereact/tooltip';
import { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './style/App.scss';

function App() {

  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('tokenBetter')
    if (!token) {
      return <Navigate to="/login" replace={true} />
    } else {
      return children;
    }
  }
  const root = createBrowserRouter([
    {
      path: '', element: <Component.BetterWay />, children: [
        { index: true, element: <ProtectedRoutes><Component.Dashboard /></ProtectedRoutes> },
        { path: 'log/:id/:type', element: <ProtectedRoutes><Component.LogData /></ProtectedRoutes> },
        { path: '/general_settings', element: <ProtectedRoutes><Component.GeneralSettings /></ProtectedRoutes> },
        { path: '/brand_request', element: <ProtectedRoutes><Component.BrandContactUs /></ProtectedRoutes> },
        { path: '/better-way-depit', element: <ProtectedRoutes><Component.Accounts /></ProtectedRoutes> },
        { path: '/better-way-credit', element: <ProtectedRoutes><Component.Accounts /></ProtectedRoutes> },
        { path: '/contact-messagess', element: <ProtectedRoutes><Component.ContactMessagess /></ProtectedRoutes> },

        {
          path: '/users', children: [
            { index: true, element: <ProtectedRoutes><Component.UserData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddUsers /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateUsers /></ProtectedRoutes> },
            { path: 'profile/:id', element: <ProtectedRoutes><Component.UpdateProfile /></ProtectedRoutes> }
          ]
        },


        {
          path: '/roles', children: [
            { index: true, element: <ProtectedRoutes><Component.RoleDate /></ProtectedRoutes> },
            { path: 'sections/:id', element: <ProtectedRoutes><Component.RoleSections /></ProtectedRoutes> }

          ]
        },

        {
          path: '/countries', children: [
            { index: true, element: <ProtectedRoutes><Component.Countries /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCountries /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCountries /></ProtectedRoutes> }
          ]
        },

        {
          path: '/cities', children: [
            { index: true, element: <ProtectedRoutes><Component.Cities /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCities /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCities /></ProtectedRoutes> }
          ]
        },


        {
          path: '/ads', children: [
            { index: true, element: <ProtectedRoutes><Component.Ads /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddAds /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateAds /></ProtectedRoutes> }
          ]
        },

        {
          path: '/areas', children: [
            { index: true, element: <ProtectedRoutes><Component.Areas /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddAreas /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateAreas /></ProtectedRoutes> }
          ]
        },

        {
          path: '/nationalities', children: [
            { index: true, element: <ProtectedRoutes><Component.Nationalities /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddNationalities /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateNationalities /></ProtectedRoutes> }
          ]
        },

        {
          path: '/reviews', children: [
            { index: true, element: <ProtectedRoutes><Component.BrandReviews /></ProtectedRoutes> },
          ]
        },

        {
          path: '/categories', children: [
            { index: true, element: <ProtectedRoutes><Component.Category /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCategory /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCategory /></ProtectedRoutes> }
          ]
        },
        {
          path: '/revenue', children: [
            { index: true, element: <ProtectedRoutes><Component.CompanyLedger /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCompanyLedger /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCompanyLedger /></ProtectedRoutes> }
          ]
        },

        {
          path: '/expenses', children: [
            { index: true, element: <ProtectedRoutes><Component.Expenses /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddExpenses /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateExpenses /></ProtectedRoutes> }
          ]
        },

        {
          path: '/category_account', children: [
            { index: true, element: <ProtectedRoutes><Component.CategoryAccount /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCategoryAccount /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCategoryAccount /></ProtectedRoutes> }
          ]
        },

        {
          path: '/subcategory_account', children: [
            { index: true, element: <ProtectedRoutes><Component.SubCategoryAccount /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddSubCategoryAccount /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateSubCategoryAccount /></ProtectedRoutes> }
          ]
        },

        {
          path: '/subcategories', children: [
            { index: true, element: <ProtectedRoutes><Component.SubCategory /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddSubCategory /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateSubCategory /></ProtectedRoutes> }
          ]
        },
        {
          path: '/brands', children: [
            { index: true, element: <ProtectedRoutes><Component.Brand /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddBrand /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateBrand /></ProtectedRoutes> },
            { path: 'profile/:id', element: <ProtectedRoutes><Component.ProfileBrand /></ProtectedRoutes> },
            {
              path: 'contract/:id', children: [
                { index: true, element: <ProtectedRoutes><Component.BrandContract /></ProtectedRoutes> },
                { path: 'add', element: <ProtectedRoutes><Component.AddBrandContract /></ProtectedRoutes> },
                { path: 'edit/:idEdit', element: <ProtectedRoutes><Component.UpdateBrandContract /></ProtectedRoutes> }
              ]
            },

            {
              path: 'gallery/:id', children: [
                { index: true, element: <ProtectedRoutes><Component.BrandGallery /></ProtectedRoutes> },
                { path: 'add', element: <ProtectedRoutes><Component.AddBrandGallery /></ProtectedRoutes> },
              ]
            },

            {
              path: 'contacts/:id', children: [
                { index: true, element: <ProtectedRoutes><Component.BrandContacts /></ProtectedRoutes> },
                { path: 'add', element: <ProtectedRoutes><Component.AddBrandContacts /></ProtectedRoutes> },
                { path: 'edit/:idEdit', element: <ProtectedRoutes><Component.UpdateBrandContacts /></ProtectedRoutes> }
              ]
            },
          ]
        },
        {
          path: '/products', children: [
            { index: true, element: <ProtectedRoutes><Component.Products /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddProducts /></ProtectedRoutes> },
            { path: 'branches/:id', element: <ProtectedRoutes><Component.BrandProductBranches /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateProducts /></ProtectedRoutes> }
          ]
        },
        {
          path: '/branch', children: [
            { index: true, element: <ProtectedRoutes><Component.Branch /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddBranch /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateBranch /></ProtectedRoutes> }
          ]
        },
        {
          path: '/upgrades', children: [
            { index: true, element: <ProtectedRoutes><Component.UpgradeData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddUpgradeData /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateUpgradeData /></ProtectedRoutes> }
          ]
        },
        {
          path: '/socialmedia', children: [
            { index: true, element: <ProtectedRoutes><Component.SocialMedia /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddSocialMedia /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateSocialMedia /></ProtectedRoutes> }
          ]
        },
        {
          path: '/plans', children: [
            { index: true, element: <ProtectedRoutes><Component.PlanData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddPlanData /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdatePlanData /></ProtectedRoutes> }
          ]
        },
        {
          path: '/plan-products', children: [
            { index: true, element: <ProtectedRoutes><Component.PlanProducts /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddPlanProducts /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdatePlanProducts /></ProtectedRoutes> }
          ]
        },
        {
          path: '/events', children: [
            { index: true, element: <ProtectedRoutes><Component.Events /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddEvents /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateEvents /></ProtectedRoutes> },
            { path: 'details/:id', element: <ProtectedRoutes><Component.EventDetails /></ProtectedRoutes> }
          ]
        },
        {
          path: '/tools', children: [
            { index: true, element: <ProtectedRoutes><Component.Tools /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddTools /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateTools /></ProtectedRoutes> },
            { path: 'details/:id', element: <ProtectedRoutes><Component.ToolsDetails /></ProtectedRoutes> }
          ]
        },
        {
          path: '/positions', children: [
            { index: true, element: <ProtectedRoutes><Component.Position /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddPosition /></ProtectedRoutes> },
            { path: 'clients/:id', element: <ProtectedRoutes><Component.PositionClient /></ProtectedRoutes> },
            { path: 'add/brands/:id', element: <ProtectedRoutes><Component.AddPositionBrand /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdatePosition /></ProtectedRoutes> },
            { path: 'brands/:id', element: <ProtectedRoutes><Component.PositionBrand /></ProtectedRoutes> },

          ]
        },
        {
          path: '/bonanza', children: [
            { index: true, element: <ProtectedRoutes><Component.Bonanza /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddBonanza /></ProtectedRoutes> },
            { path: 'clients/:id', element: <ProtectedRoutes><Component.BonanzaClient /></ProtectedRoutes> },
            { path: 'add/brands/:id', element: <ProtectedRoutes><Component.AddBonanzaBrand /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateBonanza /></ProtectedRoutes> },
            { path: 'brands/:id', element: <ProtectedRoutes><Component.BonanzaBrand /></ProtectedRoutes> },
          ]
        },
        {
          path: '/client', children: [
            { index: true, element: <ProtectedRoutes><Component.ClientData /></ProtectedRoutes> },
            { path: 'register', element: <ProtectedRoutes><Component.RegisterClient /></ProtectedRoutes> },
            { path: 'rename/:id', element: <ProtectedRoutes><Component.ClientRename /></ProtectedRoutes> },
            { path: 'contract/:id', element: <ProtectedRoutes><Component.ClientsContract /></ProtectedRoutes> },
            { path: 'request/:id', element: <ProtectedRoutes><Component.NetworkRequest /></ProtectedRoutes> },
            { path: 'transfer/:id', element: <ProtectedRoutes><Component.ClientTransfer /></ProtectedRoutes> },
            { path: 'details/:id', element: <ProtectedRoutes><Component.ClientDetails /></ProtectedRoutes> },
            { path: 'events/:id', element: <ProtectedRoutes><Component.ClientsEvents /></ProtectedRoutes> },
            { path: 'tools/:id', element: <ProtectedRoutes><Component.ClientTools /></ProtectedRoutes> },
            { path: 'brandproducts/:id', element: <ProtectedRoutes><Component.ClientBrandProducts /></ProtectedRoutes> },
            { path: 'ledger/:id', element: <ProtectedRoutes><Component.ledgerClients /></ProtectedRoutes> },
            { path: 'bonanza/:id', element: <ProtectedRoutes><Component.ClientBonanza /></ProtectedRoutes> },
            {
              path: 'chat', children: [
                { path: 'list/:id', element: <ProtectedRoutes><Component.Chat /></ProtectedRoutes> },
                { path: 'client/:id/details/:idChat', element: <ProtectedRoutes><Component.ChatDetails /></ProtectedRoutes> }
              ]
            },
          ]
        },
        {
          path: '*', element: <Component.Error />
        }
      ],
    },
    {
      path: '/login', element: <Component.Auth />, children: [
        { index: true, element: <Component.Login /> },
      ]
    },
    {
      path: '/delete-account', element: <Component.Auth />, children: [
        { index: true, element: <Component.DeleteAccount /> },
      ]
    },
  ])
  const tooltipOptions = {
    position: 'top',
    style: { backgroundColor: 'blue' },
  };

  async function LogOut() {
    localStorage.removeItem("tokenBetter");
    localStorage.removeItem("IDBrand");
    localStorage.removeItem("IDUser");
    localStorage.removeItem("UserEmail");
    localStorage.removeItem("tokenBetter");
    localStorage.removeItem("Role");
    return <Navigate to="/login" replace={true} />
  }
  useEffect(() => {
    let session = localStorage.getItem('AdminSessionTimeout')
    let Role = localStorage.getItem('Role')

    if (Role == 1) {
      setTimeout(() => {
        LogOut();
      }, session * 60 * 1000);
    }
  }, [])


  return (
    <div className="App">
      <Tooltip target=".disabled-button" autoHide={false} options={tooltipOptions} />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#F1592A', // Default primary color
            borderRadius:'6px'
          },
    
          components: {
            Button: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            },
            Input: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            },
            Tabs:{
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            },
            Checkbox:{
              colorPrimary:'#27BDB0',
              algorithm: true, // Enable algorithm

            },
            AutoComplete: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            }
          },
        }}
      >
        <FetchApiContext>
          <LangChange>
            <RouterProvider router={root} />
          </LangChange>
        </FetchApiContext>

      </ConfigProvider>
    </div>
  );
}

export default App;
